export const getYears = () => {
  const currentYear = new Date().getFullYear()
  let years = []
  let startYear = 2018
  while (startYear <= currentYear) {
    years = [...years, startYear++]
  }
  return years
}

export const getSelectedYear = () => {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  return currentMonth < 5 ? currentYear - 1 : currentYear
}
