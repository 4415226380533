<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import ExpenseVatRateInput from '@components/expense-vat-rate-input/expense-vat-rate-input'
import ExpenseCategoryDropdown from '@components/expense-category-dropdown/expense-category-dropdown.vue'
import ExpenseScacCodeInput from '@components/expense-scac-code-input/expense-scac-code-input.vue'
import ExpenseAmountInclVatInput from '@components/expense-amount-incl-vat-input/expense-amount-incl-vat-input'
import ExpenseVatAmountInput from '@components/expense-vat-amount-input/expense-vat-amount-input'
import ExpenseScacInfo from '@components/expense-scac-info/expense-scac-info'
import ExpenseScacDescriptionInput from '@components/expense-scac-description-input/expense-scac-description-input'
import ExpenseVatRefundInput from '@components/expense-vat-refund-input/expense-vat-refund-input'
import { pick, isNumber, map } from 'lodash'
import EventBus from '@src/event-bus'

export default {
  components: {
    ExpenseCategoryDropdown,
    ExpenseVatRateInput,
    ExpenseScacCodeInput,
    ExpenseAmountInclVatInput,
    ExpenseVatAmountInput,
    ExpenseScacInfo,
    ExpenseScacDescriptionInput,
    ExpenseVatRefundInput,
  },
  props: {
    splitIndex: {
      type: Number,
      default: null,
    },
    setValidSection: {
      type: Function,
      default: () => {},
    },
    shouldValidateOnMount: { type: Boolean, default: false },
    baseSplitValidation: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      splitFormIsValid: true,
      isDeleting: false,
      isAdding: false,
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['splits', 'getApiSplits', 'isReadOnly']),

    isBaseSplit() {
      return !isNumber(this.splitIndex)
    },

    isNormalSplit() {
      return isNumber(this.splitIndex)
    },

    expenseHasSplits() {
      return this.splits.length
    },

    split() {
      return this.splits[this.splitIndex]
    },
    isInitialSplit() {
      return map(this.getApiSplits, 'id').includes(this.split.id)
    },
  },
  watch: {
    splitFormIsValid: {
      immediate: true,
      handler(bool) {
        this.setValidSection(bool)

        if (this.isBaseSplit) {
          // emit info about error state
          EventBus.$emit('setBaseSplitValidation', {
            hasError: !bool, // keep track of new error state
            shouldValidateOnMount:
              this.baseSplitValidation.shouldValidateOnMount, // keep the info about validation on mount intact
          })
        }
      },
    },
  },
  async mounted() {
    if (
      !this.shouldValidateOnMount &&
      !this.baseSplitValidation.shouldValidateOnMount
    ) {
      // if no need to show validation on mount, we reset the validation.
      this.$refs.splitForm.resetValidation()
    }
    if (
      this.split &&
      this.split.addedAt &&
      Date.now() - this.split.addedAt < 1000
    ) {
      this.isAdding = true
      await this.delay(300) // delay to give the animation time to finish
      this.isAdding = false
    }

    // validation of split when creating new expense
    EventBus.$on('validate-fields', () => {
      this.$refs.splitForm.validate()
    })

    // validation of base split when adding new split
    if (this.isBaseSplit) {
      // validate when switching from wizard to editor
      if (this.baseSplitValidation.shouldValidateOnMount) {
        this.$refs.splitForm.validate()
      }

      EventBus.$on('validate-base-split', () => {
        // emit info about error state
        EventBus.$emit('setBaseSplitValidation', {
          hasError: !this.splitFormIsValid,
          shouldValidateOnMount: true, // emit info that the validation is needed when switching back from wizard
        })

        // if errors exist, show error messages
        if (!this.splitFormIsValid) {
          this.$refs.splitForm.validate()
        }
      })
    }
  },

  beforeDestroy() {
    EventBus.$off('validate-fields')
    EventBus.$off('validate-base-split')
  },
  methods: {
    ...pick(expenseModalMethods, ['removeSplit']),

    async removeThisSplit() {
      this.isDeleting = true
      await this.delay(300) // delay to give the animation time to finish
      this.removeSplit(this.splitIndex)
      this.isDeleting = false
    },

    async delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    },
  },
}
</script>

<template>
  <div
    :class="[
      $style.splitItem,
      isAdding && $style.isAdding,
      isDeleting && $style.isDeleting,
      // The next two classes are used to style descendents like labels and placeholders
      'split-item',
      expenseHasSplits && 'in-split-state',
    ]"
  >
    <div :class="[$style.splitItemInner]">
      <!-- If this is a normal split, we show a header with a "remove split" button -->
      <div :class="[$style.splitHeader, !expenseHasSplits && $style.hidden]">
        <h2>{{
          `${$t('EXPENSE_SPLIT')} ${isBaseSplit ? 1 : splitIndex + 2}`
        }}</h2>
        <v-btn
          v-if="!disabled && !isReadOnly && isNormalSplit"
          class="minimal-btn remove-split center"
          text
          small
          @click="removeThisSplit"
          >{{ $t('REMOVE') }}</v-btn
        >
      </div>

      <v-form ref="splitForm" v-model="splitFormIsValid">
        <v-row>
          <v-col sm="12" cols="12">
            <ExpenseCategoryDropdown
              :disabled="disabled"
              :split-index="splitIndex"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" cols="12">
            <ExpenseVatRateInput
              :disabled="disabled"
              :split-index="splitIndex"
            />
          </v-col>

          <v-col sm="6" cols="12">
            <!-- For splits that exist when opening the expense for edit, we show
            no-value  message right away if the value is missing. -->
            <ExpenseScacCodeInput
              :disabled="disabled"
              :split-index="splitIndex"
              :should-validate-on-mount="isBaseSplit || isInitialSplit"
            />
          </v-col>
        </v-row>

        <ExpenseScacInfo :split-index="splitIndex" />

        <ExpenseScacDescriptionInput
          :disabled="disabled"
          :split-index="splitIndex"
        />

        <v-row>
          <v-col sm="6" cols="6">
            <ExpenseAmountInclVatInput
              :disabled="disabled"
              :split-index="splitIndex"
            />
          </v-col>
          <v-col sm="6" cols="6">
            <ExpenseVatAmountInput :split-index="splitIndex" />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" cols="6">
            <ExpenseVatRefundInput
              :disabled="disabled"
              :split-index="splitIndex"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.splitHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 30px;

  // Animating the header (it's only visible if we have multiple splits)
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  transform: translateY(0);
  h2 {
    font-size: 16px;
    font-weight: 500;
  }
  &.hidden {
    max-height: 0;
    opacity: 0;
    transform: translateY(40px);
  }
}

.splitItem {
  // Animation properties for outer split item
  transition: 0.3s ease-in-out;
  transition-property: margin-bottom;
  perspective: 350px;

  .splitItemInner {
    background-color: transparent;
    border-radius: 4px;

    // Animation properties for inner  split item
    transition: 0.3s ease-in-out;
    transition-property: background-color, transform, opacity, padding;
    transform-origin: top left;
    transform-style: preserve-3d;
  }

  &:not(:only-child) {
    .splitItemInner {
      padding: 20px 20px 0;
      background-color: darken($light-grey, 5%);
    }
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  // This class is used to animate add
  &.isAdding {
    animation: 0.3s ease-in-out 0s 1 both 'collapseHeight500';
    animation-direction: reverse;
    .splitItemInner {
      animation: 0.3s ease-in-out 0s 1 both 'foldUp';
      animation-direction: reverse;
    }
  }

  // This class is used to animate deletion of a split
  &.isDeleting {
    margin-bottom: 0;
    animation: 0.3s ease-in-out 0s 1 both 'collapseHeight500';
    .splitItemInner {
      animation: 0.3s ease-in-out 0s 1 both 'foldUp';
    }
  }
}
</style>
