<script>
import ExpenseSplitItem from '@components/expense-split-item/expense-split-item'
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import EventBus from '@src/event-bus'
import { pick, debounce } from 'lodash'
import { getSavedStateSession } from '@utils/storage'

export default {
  components: { ExpenseSplitItem },
  props: {
    setValidSection: {
      type: Function,
      default: () => {},
    },
    shouldValidateOnMount: { type: Boolean, default: false },
    baseSplitValidation: { type: Object, default: () => {} },
  },
  data() {
    return {
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'splits',
      'isReadOnly',
      'getAmountInclVat',
      'isNewExpense',
      'getIsCurrencyValid',
    ]),
    shouldDisableAddButton() {
      return this.getAmountInclVat(null) <= 0 || !this.getIsCurrencyValid
    },
  },
  created() {
    // Create a debounced function that scrolls to a new split
    this.scrollToNewSplit = debounce(async (splitIndex) => {
      await this.$nextTick()
      const el = this.$refs[`split_${splitIndex}`][0].$el
      const y = el.offsetTop
      const height = el.clientHeight
      EventBus.$emit('scroll-expense-modal-editor', { element: { y, height } })
    }, 500)
  },
  methods: {
    ...pick(expenseModalMethods, ['addSplit']),

    async addNewSplit() {
      const nextSplitIndex = this.splits.length

      if (this.isNewExpense) {
        // validate base split before adding a split
        EventBus.$emit('validate-base-split')

        if (!this.baseSplitValidation.hasError) {
          // if no errors, add a new split
          this.addSplit()
          this.scrollToNewSplit(nextSplitIndex)

          // reset base split error state so it doesn't affect the rest of the splits
          EventBus.$emit('setBaseSplitValidation', {
            hasError: false,
            shouldValidateOnMount: false,
          })
        }
      } else {
        this.addSplit()
        this.scrollToNewSplit(nextSplitIndex)
      }
    },
  },
}
</script>

<template>
  <div>
    <div :class="$style.splitsHeader">
      <h2 class="expenseTitle">{{
        $t(`EXPENSE${splits.length ? 'S' : ''}`)
      }}</h2>
      <v-tooltip
        v-if="!isReadOnlyUser"
        :disabled="isNewExpense || !shouldDisableAddButton"
        max-width="250px"
        bottom
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              v-if="!isReadOnly"
              class="expenseTitle minimal-btn add-split center"
              text
              small
              :disabled="!isNewExpense && shouldDisableAddButton"
              @click="addNewSplit"
              >{{ `+ ${$t('ADD_SPLIT')}` }}</v-btn
            >
          </div>
        </template>
        <span>{{
          $t(
            getIsCurrencyValid
              ? 'ADD_SPLIT_DISABLED_WARNING_AMOUNT'
              : 'ADD_SPLIT_DISABLED_WARNING_CURRENCY'
          )
        }}</span>
      </v-tooltip>
    </div>

    <!-- We wrap the split-items in a div (Makes it easier to target splits with
    css selectors) -->
    <div>
      <!--Parent expense aka "Base Split" -->
      <ExpenseSplitItem
        key="base"
        :set-valid-section="setValidSection('splitBase')"
        :should-validate-on-mount="shouldValidateOnMount"
        :base-split-validation="baseSplitValidation"
        :disabled="isReadOnlyUser"
      />
      <!--Expense splits aka "Normal Splits"-->
      <ExpenseSplitItem
        v-for="(split, index) in splits"
        :ref="`split_${index}`"
        :key="split.id || split.addedAt"
        :split-index="index"
        :set-valid-section="setValidSection(`split_${index}`)"
        :should-validate-on-mount="shouldValidateOnMount"
        :base-split-validation="baseSplitValidation"
        :disabled="isReadOnlyUser"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.splitsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
