<script>
import NavBar from '@components/nav-bar/nav-bar'
import HeaderUser from '@components/header-user/header-user'
import Logo from '@components/brand-logo/brand-logo'
import { authComputed } from '@state/helpers/auth'
import { getSavedStateSession } from '@utils/storage'

export default {
  components: {
    NavBar,
    HeaderUser,
    Logo,
  },
  data() {
    return {
      logoRoute:
        getSavedStateSession('isVattaxAdmin') ||
        getSavedStateSession('isReadOnlyUser')
          ? '/enterprises'
          : '/overview',
    }
  },
  computed: {
    ...authComputed,
  },
}
</script>

<template>
  <header class="header-top">
    <RouterLink :to="logoRoute" class="logo-link">
      <Logo />
    </RouterLink>
    <div class="nav-and-user">
      <NavBar />
      <HeaderUser v-if="loggedIn" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import '@design';

.header-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 40px;
  background: white;
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.07);
}

.logo-link {
  padding: 0;
}
.nav-and-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
