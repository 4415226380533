<script>
import { expenseModalComputed } from '@state/helpers/expense-modal'
import { pick, isNumber } from 'lodash'
import eventBus from '@src/event-bus'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
  },

  data: () => {
    return {
      split: {
        index: null,
        vatAmount: null,
        // The reason we need vatRate and amountInclVat in this component, is
        // that we want to show a warning message if only one of them have a
        // value.
        vatRate: null,
        amountInclVat: null,
      },
      unwatchStore: () => {},
    }
  },

  computed: {
    ...pick(expenseModalComputed, [
      'getVatAmount',
      'getVatRate',
      'getAmountInclVat',
      'currency',
    ]),

    isBaseSplit() {
      return !isNumber(this.split.index)
    },

    showVatAmountWarning() {
      // If only one of vatRate and totalAmountInclVat is truthy, we want
      // to show a warning
      return (
        [this.split.vatRate, this.split.amountInclVat].filter(
          (value) => Boolean(value) || isNumber(value)
        ).length === 1
      )
    },
  },
  mounted() {
    eventBus.$on('vatAmountChanged', (value) => {
      if (this.isBaseSplit) {
        this.split.vatAmount = isNumber(value) ? value.toFixed(2) : null
      }
    })
  },

  created() {
    // Start watching Vuex store

    this.unwatchStore = this.$store.watch(
      // Reactively watch this functions return value
      (state, getter) => {
        const index = this.splitIndex
        const vatRate = this.getVatRate(index)
        const amountInclVat = this.getAmountInclVat(index)
        const vatAmount = this.getVatAmount(index)

        // Return a primitive value
        return JSON.stringify({
          index,
          vatAmount: isNumber(vatAmount) ? vatAmount.toFixed(2) : null,
          vatRate,
          amountInclVat,
        })
      },

      // Call this function when the value changes
      (json) => {
        this.split = JSON.parse(json)
      },

      // Options
      { immediate: true }
    )
  },
  beforeDestroy() {
    this.unwatchStore()
  },
}
</script>

<template>
  <div>
    <v-text-field
      :value="split.vatAmount"
      disabled
      outlined
      :label="$t('VAT_AMOUNT')"
      type="number"
      :suffix="currency"
    />
    <span v-if="showVatAmountWarning" :class="$style.warningMessage">{{
      $t('VAT_AMOUNT_WARNING')
    }}</span>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.warningMessage {
  font-size: 12px;
  color: $orange;
}
</style>
