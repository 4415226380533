import Vue from 'vue'
import Vuex from 'vuex'
import dispatchActionForAllModules from '@utils/dispatch-action-for-all-modules'

import dataModules from './data-modules'
import featureModules from './modules'
import { intersection } from 'lodash'

if (process.env.NODE_ENV === 'development') {
  // Here we make sure all dataModules and featureModules have unique names
  intersection(Object.keys(dataModules), Object.keys(featureModules)).forEach(
    (module) =>
      console.error(
        `Module "${module}" exist both as a data-model-module and feature-module. The data-model-module will be overwritten.`
      )
  )
}

const modules = {
  ...dataModules,
  ...featureModules,
}

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
})

export default store

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
