/**
 * This watcher will trigger fetching new receipt limits
 */

export default (store) => {
  return store.watch(
    // Reactively watch this functions return value
    (state, getters) => {
      const country = getters['expenseModal/getCountry']
      const currency = getters['expenseModal/getCurrency']
      const date = state.expenseModal.date
      const category = getters['expenseModal/getCategory']() // Base category
      const amount = getters['expenseModal/getAmountInclVat']() // Base amount
      const splits = state.expenseModal.splits.map((split) => ({
        category: split.category.key,
        amount: split.amountInclVat,
      }))
      return JSON.stringify({
        country,
        currency,
        date,
        category: category.key,
        amount,
        splits,
      })
    },

    // Call this function when the value changes
    async (value) => {
      const { country, currency, category, amount, splits } = JSON.parse(value)
      if (country) {
        const response = await store.dispatch(
          'receiptLimits/fetchReceiptLimits',
          { query: { country, currency } }, // TODO: Also use date in query
          { root: true }
        )

        const currentLimits = store.getters['receiptLimits/item'](
          response.queryId
        )

        // Does either the base or some of the splits require a receipt?
        const showInvoiceNumber = [{ category, amount }, ...splits].some(
          ({ category, amount }) => {
            // -1 === "invoice number is never needed".
            return currentLimits[category] === -1
              ? false
              : amount && amount > currentLimits[category]
          }
        )

        // Commit the value to state if it needs to change
        if (
          store.getters['expenseModal/getShouldShowInvoiceNumber'] !==
          showInvoiceNumber
        )
          store.commit(
            'expenseModal/SET_SHOULD_SHOW_INVOICE_NUMBER',
            showInvoiceNumber
          )
      }
    },

    // Options
    { immediate: true }
  )
}
