import axios from 'axios'
import eventBus from '@src/event-bus'

export const state = {
  cached: [],
}

export const getters = {}

export const mutations = {
  CACHE_USER(state, newUser) {
    state.cached.push(newUser)
  },
}

export const actions = {
  fetchUser({ commit, state, rootState }, { username }) {
    // 1. Check if we already have the user as a current user.
    const { currentUser } = rootState.auth
    if (currentUser && currentUser.username === username) {
      return Promise.resolve(currentUser)
    }

    // 2. Check if we've already fetched and cached the user.
    const matchedUser = state.cached.find((user) => user.username === username)
    if (matchedUser) {
      return Promise.resolve(currentUser)
    }

    // 3. Fetch the user from the API and cache it in case
    //    we need it again in the future.
    return axios
      .get(`${rootState.general.base_api_url}/api/users/${username}`)
      .then((response) => {
        const user = response.data
        commit('CACHE_USER', user)
        return user
      })
      .catch((error) => {
        console.log(error)
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: 'User fetch failed',
          type: 'error',
        })
      })
  },
}
