import axios from 'axios'
import i18n from '@src/i18n'
import { size } from 'lodash'

export const state = { byKey: {} }

export const getters = {
  getCategories: (state) => {
    return Object.values(state.byKey)
  },
  getCategory:
    (state) =>
    ({ code, key, name }) => {
      if (key) {
        return state.byKey[key]
      } else if (code || name) {
        return Object.values(state.byKey).find((category) => {
          return category.code === code || category.name === name
        })
      }
    },
}

export const mutations = {
  SET_CATEGORIES(state, categories) {
    state.byKey = categories.reduce(
      (acc, category) => ({ ...acc, [category.key]: category }),
      {}
    )
  },
}

export const actions = {
  async fetchCategories({ commit, rootGetters }, { useCache = true }) {
    // If we already have the categories and want to reuse, abort here
    if (useCache && size(state.byId) > 0) return

    // Otherwise fetch the categories from the API
    try {
      const { data } = await axios.get(
        `${rootGetters['general/getVatQuestionServiceUrl']}/categories/all`,
        {
          headers: {
            'Accept-Language': i18n.locale,
          },
        }
      )
      commit('SET_CATEGORIES', data.categories)
    } catch (error) {
      console.error(error)
    }
  },
}
