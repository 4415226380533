import request from '@utils/apiRequest'
import { getSelectedYear } from '@utils/getYears'
import showVatRateError from '@utils/showVatRateError'
import { getSavedStateSession } from '@utils/storage'

export const state = {
  countries: [],
  getCountriesInProgress: false,
  getCountriesError: false,
  order: 'asc',
  sort: 'country',
  year: getSavedStateSession('expenseOverview.year') || getSelectedYear(),
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
  },
}

export const getters = {
  countries(state) {
    return state.countries
  },
  order(state) {
    return state.order
  },
  pagination(state) {
    return state.pagination
  },
  sort(state) {
    return state.sort
  },
  year(state) {
    return state.year
  },
}

export const mutations = {
  GET_COUNTRIES_DATA(state, countries) {
    state.countries = countries
    state.pagination = {
      page: state.pagination.page,
      pageSize: 10,
      total: countries.length,
    }
  },
  GET_COUNTRIES_DATA_IN_PROGRESS(state, bool) {
    state.getCountriesInProgress = bool
  },
  GET_COUNTRIES_DATA_ERROR(state, bool) {
    state.getCountriesError = bool
  },
  SET_YEAR(state, year) {
    state.year = year
    state.pagination = {
      ...state.pagination,
      page: 1,
    }
  },
  SET_PAGE(state, page) {
    state.pagination.page = page
  },

  SET_SORT(state, sort) {
    state.sort = sort

    state.pagination = {
      ...state.pagination,
      page: 1,
    }
  },
}

export const actions = {
  getCountries({ commit, rootState }) {
    commit('GET_COUNTRIES_DATA_IN_PROGRESS', true)
    return request
      .get(`expenses/overview`, {
        params: {
          enterprise_id: rootState.enterprise.currentEnterprise.id,
          year: state.year,
        },
      })
      .then((response) => {
        commit('GET_COUNTRIES_DATA', response.data)
        commit('GET_COUNTRIES_DATA_ERROR', false)
      })
      .catch((error) => {
        commit('GET_COUNTRIES_DATA_ERROR', true)

        const message =
          error.response &&
          error.response.data &&
          error.response.data.developer_message
        showVatRateError(message, 'EXPENSES_OVERVIEW_ERROR')
      })
      .finally(() => {
        commit('GET_COUNTRIES_DATA_IN_PROGRESS', false)
      })
  },

  setYear({ commit }, year) {
    commit('SET_YEAR', year)
  },

  setPage({ commit }, page) {
    commit('SET_PAGE', page)
  },

  setSort({ commit }, sort) {
    commit('SET_SORT', sort)
  },

  async refreshData({ dispatch }) {
    await dispatch('getCountries')
  },
}
