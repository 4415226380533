<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { modalMethods as baseModalMethods } from '@state/helpers/modal'
import { expensesByCountryMethods } from '@state/helpers/expensesByCountry'
import { pick } from 'lodash'
import i18n from '@src/i18n'
import EventBus from '@src/event-bus'
import { getSavedStateSession } from '@utils/storage'
export default {
  props: {
    currentModalState: { type: String, default: () => '' },
  },
  data() {
    return {
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'isNewExpense',
      'status',
      'id',
      'isReadOnly',
    ]),
    nextStatus() {
      if (['NEED_MORE_INFO', 'READY_TO_RECLAIM'].includes(this.status)) {
        return 'DESELECTED'
      } else if (this.status === 'DESELECTED') {
        return 'NEED_MORE_INFO'
      }
      return null
    },
    url() {
      return location.href
    },
  },
  methods: {
    ...pick(baseModalMethods, ['setModalData', 'resetModalData']),
    ...pick(expenseModalMethods, [
      'fetchExpenseHistory',
      'closeExpense',
      'changeExpenseStatus',
    ]),
    ...pick(expensesByCountryMethods, ['moveExpenses']),

    changeModalState(toState) {
      EventBus.$emit('change-expense-modal-state', { toState })
    },
    onCloseExpense() {
      this.$emit('resetErrorStates')
      this.closeExpense({
        isNewExpense: this.isNewExpense,
        isReadOnly: this.isReadOnly,
      })
    },
    copyUrl() {
      let copyUrlInput = document.querySelector('#copy-url')
      copyUrlInput.setAttribute('type', 'text')

      // copy to cliboard
      copyUrlInput.select()
      document.execCommand('copy')

      // hide and unselect
      copyUrlInput.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    onMoveExpense() {
      // Show confirmation modal
      const modalData = {
        confirmAction: this.onConfirmMove,
        declineAction: this.resetModalData,
        secondBtnAction: this.resetModalData,
        secondBtnVisible: true,
        isModalVisible: true,
        confirmText: i18n.t('MOVE_TO_STATUS_AND_CLOSE_BUTTON', {
          status: i18n.t(this.nextStatus),
        }),
        secondBtnText: i18n.t('CANCEL'),
        title: i18n.t('MOVE_TO_STATUS_QUESTION', {
          status: i18n.t(this.nextStatus),
        }),
        content: this.$i18n.t('MOVE_TO_STATUS_CONFIRMATION', {
          status: i18n.t(this.nextStatus),
        }),
      }
      this.setModalData(modalData)
    },
    onConfirmMove() {
      this.changeExpenseStatus({
        id: this.id,
        status: this.nextStatus,
      })
      this.onCloseExpense() // Close expense modal
      this.resetModalData() // Close confirmation modal
    },
  },
}
</script>

<template>
  <div>
    <div :class="$style.expenseDetailsHeader">
      <!-- Receipt Upload -->
      <template v-if="currentModalState === 'receipt-upload'">
        <div :class="$style.content">
          <span>{{ $t('ADD_EXPENSE') }}</span>
        </div>
      </template>

      <!-- Editor -->
      <template v-else-if="currentModalState === 'editor'">
        <div v-if="isNewExpense" :class="$style.content">
          <span>{{ $t('ADD_EXPENSE') }}</span>
        </div>
        <div v-else :class="$style.content">
          <span v-if="isReadOnlyUser">{{ $t('EXPENSE_DETAILS_TITLE') }} </span>
          <span v-else>{{ $t('EDIT_EXPENSE') }} </span>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                small
                :class="$style.quickActionButton"
                @click="copyUrl"
                v-on="on"
              >
                <img src="~@/src/assets/svg/copy-link.svg" />
                <input id="copy-url" type="hidden" :value="url" />
              </v-btn>
            </template>
            <span>{{ $t('EXPENSE_URL_COPY') }}</span>
          </v-tooltip>

          <v-tooltip v-if="!isReadOnlyUser" top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="!isNewExpense && nextStatus"
                depressed
                small
                :class="$style.quickActionButton"
                @click="onMoveExpense"
                v-on="on"
              >
                <img src="~@/src/assets/svg/move-expense.svg" />
              </v-btn>
            </template>
            <span>
              {{ $t('MOVE_TO_STATUS', { status: $t(nextStatus) }) }}
            </span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                small
                value="history"
                :class="$style.quickActionButton"
                @click="changeModalState('history')"
                @mouseenter="fetchExpenseHistory"
                v-on="on"
              >
                <img src="~@/src/assets/svg/expense-history.svg" />
              </v-btn>
            </template>
            <span>{{ $t('EXPENSE_HISTORY') }}</span>
          </v-tooltip>
        </div>
      </template>

      <!-- SCAC wizard -->
      <template v-else-if="currentModalState === 'scac-wizard'">
        <div :class="$style.navBackIcon" @click="changeModalState('editor')">
          <v-icon>mdi-arrow-left</v-icon>
        </div>
        <div :class="$style.content">
          <span>{{ $t('EXPENSE_SCAC') }}</span>
        </div>
      </template>

      <!-- History -->
      <template v-else-if="currentModalState === 'history'">
        <div :class="$style.navBackIcon" @click="changeModalState('editor')">
          <v-icon>mdi-arrow-left</v-icon>
        </div>
        <div :class="$style.content">
          <span>{{ $t('EXPENSE_HISTORY') }}</span>
        </div>
      </template>

      <!-- Editor has a "close expense" button  -->
      <div
        v-if="['receipt-upload', 'editor'].includes(currentModalState)"
        :class="$style.closeIcon"
        @click="onCloseExpense"
      >
        <v-icon>mdi-expenseDetailsCustomClose</v-icon>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.expenseDetailsHeader {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
  color: $dark;
}

.navBackIcon {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
}

.content {
  padding-left: 41px;
}

.navBackIcon + .content {
  // If there is a navBackIcon before content, remove the left padding
  padding-left: 0;
}

.closeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  margin-right: 10px;
  margin-left: auto;
  cursor: pointer;
}
.quickActionButton {
  min-width: auto !important;
  padding: 0 10px !important;
  margin-left: 10px;
  background: $medium-grey !important;
}
</style>
