import store from '@state/store'

import { saveStateSession, removeSavedStateSession } from '@utils/storage'

async function saveExpenseToStorage(id) {
  // fetch the direct link expense
  const response = await store.dispatch(
    'expenses/FETCH_ITEM',
    { query: { id } },
    { root: true }
  )
  if (response.queryId) {
    // get the direct link expense from state
    const expense = store.getters['expenses/item'](id)

    const directLinkExpense = {
      id: expense.id,
      enterpriseId: expense.enterprise_id,
      country: expense.country,
      year: new Date(expense.date).getFullYear(),
    }
    // save direct link expense to storage
    saveStateSession('expense', directLinkExpense)
    return directLinkExpense
  } else {
    removeSavedStateSession('expenseId')
    removeSavedStateSession('expense')
    return response.status
  }
}

export { saveExpenseToStorage }
