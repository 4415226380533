<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import {
  minNumber,
  maxNumber,
  requiredValidation,
} from '@utils/inputValidation'
import { pick } from 'lodash'
import { isValidNumber } from '@utils/vattax-math'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
    disabled: { type: Boolean, default: false },
  },
  data: () => {
    return {
      split: {
        index: null,
        country: null,
        vatRate: null,
        vatRatesForCountry: null,
      },
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'isReadOnly',
      'isNewExpense',
      'country',
      'enterpriseId',
      'getVatRatesForCountry',
      'getVatRate',
    ]),
    vatRates() {
      return this.getVatRatesForCountry(this.country)
    },
    vatRate() {
      return this.getVatRate(this.splitIndex)
    },
    vatRateRules() {
      return [
        (value) =>
          requiredValidation(
            typeof value === 'number' ? value.toString() : value
          ),
        (value) => maxNumber(this.getFormattedVatRateNumber(value), 100),
        (value) => minNumber(this.getFormattedVatRateNumber(value), 0),
      ]
    },
  },

  methods: {
    ...pick(expenseModalMethods, ['updateVatRate']),

    onSelectNewVatRate(newVatRate) {
      this.updateVatRateInVuexAndLogToAnalytics({
        vatRate: this.getFormattedVatRateNumber(newVatRate),
      })
    },

    getFormattedVatRateNumber(inputValue = '') {
      const formattedNumber = parseFloat(String(inputValue).replace(',', '.'))
      const roundedValue = Math.round(formattedNumber * 10) / 10
      return isValidNumber(roundedValue) ? roundedValue : null
    },

    onKeyUp(event) {
      this.split.vatRate = event.target.value
      if (event.key === 'Enter') {
        // 'Enter' is pressed
        this.$refs.comboBox.blur()
      }
    },

    // simply to avoid the warning, we still want on blur to be available
    // as this is cleaner ui
    onBlur(_event) {},

    updateVatRateInVuexAndLogToAnalytics({ vatRate }) {
      // Update Vuex state
      this.updateVatRate({ vatRate, splitIndex: this.split.index })

      // TODO: Should we also log split index?
      // Log to analytics
      if (isValidNumber(vatRate)) {
        this.$amplitude.getInstance().logEvent('UPDATE_VAT_RATE', {
          enterpriseId: this.enterpriseId,
          newRate: vatRate,
        })
      }
    },
  },
}
</script>

<template>
  <!-- The id "vat-rate-search" is added to give password managers a hint to
  leave the field alone. The word "search" does the trick -->
  <!-- The autocomplete "new-password" is added to give browsers a hint to leave
  the field alone. We don't want browser autocompletion. -->
  <v-combobox
    id="vat-rate-search"
    ref="comboBox"
    autocomplete="new-password"
    :label="$t('VAT_RATE')"
    class="bulky forced"
    outlined
    prepend-inner-icon="mdi-category__vat"
    :items="vatRates"
    :value="vatRate"
    :menu-props="{ offsetY: true }"
    :rules="vatRateRules"
    :disabled="disabled || isReadOnly || (isNewExpense && !country)"
    @change="onSelectNewVatRate"
    @keyup="onKeyUp"
    @blur="onBlur"
  ></v-combobox>
</template>
