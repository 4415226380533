<script>
export default {
  props: {
    action: { type: String, default: () => '' },
    date: { type: String, default: () => '' },
  },
}
</script>

<template functional>
  <div :class="$style.item">
    <div :class="$style.circle"></div>
    <div :class="$style.wrap">
      <h3>{{ props.action }}</h3>
      <span>
        <span :class="$style.updated">{{ parent.$t('UPDATED') }}</span>
        {{ props.date }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.item {
  display: flex;
  padding: 20px 20px 0;
  margin-bottom: 20px;
  text-align: left;
  animation: 0.85s cubic-bezier(0.22, 1.51, 0.28, 0.96) 0s 1 'fadePop';
  .circle {
    width: 48px;
    height: 48px;
    background: white;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='%238c949e' fill-rule='nonzero'%3E%3Cpath d='M13 16H1c-.553 0-1-.448-1-1V3c0-.552.447-1 1-1h7v2H2v10h10V8h2v7c0 .552-.447 1-1 1z'/%3E%3Ccircle cx='13' cy='3' r='3'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px 16px;
    border-radius: 999px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .wrap {
    padding-left: 15px;
    h3 {
      font-size: 16px;
    }

    span {
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
      color: rgba($grey, 0.9);

      .updated {
        padding: 4px 8px;
        margin-right: 5px;
        font-size: 12px;
        font-style: normal;
        color: white;
        background: #344359;
        border-radius: 4px;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.04);
      }
    }
  }
}
</style>
