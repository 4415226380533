<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: [String, Object],
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    btn2Text: {
      type: String,
      default: '',
    },
    btnFn: {
      type: Function,
      default: () => null,
    },
    btn2Fn: {
      type: Function,
      default: () => null,
    },
    btn1Visible: {
      type: Boolean,
      default: false,
    },
    btn2Visible: {
      type: Boolean,
      default: false,
    },
    closeFn: {
      type: Function,
      default: () => null,
    },
    resetPassword: {
      type: Function,
      default: () => null,
    },
    resendEmail: {
      type: Function,
      default: () => null,
    },
    bus: {
      type: [String, Object],
      default: 'div',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template functional>
  <div :class="$style.overlay" tabindex="0" @keyup.esc="props.closeFn">
    <div :class="$style.modalForm">
      <div :class="$style.flexRow">
        <div :class="$style.modalHeading">
          <h1 :class="$style.title">{{ props.title }}</h1>
          <div :class="$style.iconClose" @click="props.closeFn"></div>
        </div>
      </div>
      <div :class="$style.modalScroll">
        <component
          :is="props.content"
          :bus="props.bus"
          :close-fn="props.closeFn"
          :reset-password="props.resetPassword"
          :resend-email="props.resendEmail"
        />
        <div :class="$style.spacer" />
        <div :class="$style.center">
          <v-btn
            v-if="props.btn1Visible"
            :loading="props.loading"
            class="btn primary-btn center"
            type="submit"
            @click="props.btnFn"
            >{{ props.btnText }}</v-btn
          >
          <div :class="$style.smallSpacer" />
          <v-btn
            v-if="props.btn2Visible"
            :loading="props.loading"
            class="minimal-btn remove center"
            type="submit"
            @click="props.btn2Fn"
            >{{ props.btn2Text }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: $dark-transparent;

  .modalForm {
    display: flex;
    flex-direction: column;
    width: 505px;
    max-height: 90vh;
    padding: 20px;
    overflow: hidden;
    background-color: $light-grey;
    border-radius: 8px;

    .modalScroll {
      padding: 30px 35px 40px 21px;
      overflow-y: auto;

      .center {
        text-align: center;
      }

      @include scrollbars;
    }

    .modalHeading {
      position: relative;
      width: 100%;
      margin: 25px 10px 38px 17px;
    }

    .title {
      margin: 0;
    }

    .flexRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .iconClose {
      position: absolute;
      top: 10px;
      right: 0;
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E%3Cpath fill='%23222F44' fill-rule='nonzero' d='M.2928932.2928932c.3905243-.3905243 1.0236893-.3905243 1.4142136 0L11 9.585 20.2928932.2928932c.360484-.360484.927715-.3882135 1.3200062-.0831886l.0942074.0831886c.3905243.3905243.3905243 1.0236893 0 1.4142136L12.415 11l9.2921068 9.2928932c.3604839.360484.3882135.927715.0831886 1.3200062l-.0831886.0942074c-.3905243.3905243-1.0236893.3905243-1.4142136 0L11 12.415l-9.2928932 9.2921068c-.360484.3604839-.927715.3882135-1.3200062.0831886l-.0942074-.0831886c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136L9.585 11 .2928932 1.7071068c-.360484-.360484-.3882135-.927715-.0831886-1.3200062z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 20px 20px;
      &:hover {
        cursor: pointer;
      }
    }

    .spacer {
      height: 50px;
    }

    .smallSpacer {
      height: 20px;
    }
  }
}
</style>
