<script>
import HistoryItem from '@components/expense-history-item/expense-history-item'
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { formatDateWithTime } from '@utils/format-date'
import { pick } from 'lodash'

export default {
  components: {
    HistoryItem,
  },
  data() {
    return {
      translatatedActions: {
        EDIT_EXPENSE: this.$i18n.t('EXPENSE_HISTORY_EDITED_MESSAGE'),
        CREATE_EXPENSE: this.$i18n.t('EXPENSE_HISTORY_CREATED_MESSAGE'),
        CREATE_RECLAIM: this.$i18n.t('EXPENSE_HISTORY_RECLAIM_CREATED_MESSAGE'),
        SPLIT_EXPENSE: this.$i18n.t('EXPENSE_HISTORY_SPLIT_MESSAGE'),
      },
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['history', 'historyError']),
  },
  beforeMount() {
    this.fetchExpenseHistory()
  },
  methods: {
    ...pick(expenseModalMethods, ['fetchExpenseHistory']),
    renderDate(date) {
      return formatDateWithTime(date)
    },
  },
}
</script>

<template>
  <div :class="$style.history">
    <div v-if="historyError">{{ $t('EXPENSE_HISTORY_ERROR') }}</div>
    <div v-else-if="history && !history.length">{{
      $t('EXPENSE_HISTORY_NO_DATA')
    }}</div>
    <HistoryItem
      v-for="(item, index) in history"
      :key="index"
      :action="translatatedActions[item.action]"
      :date="renderDate(item.date)"
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.history {
  width: 100%;
  padding: 50px 30px 50px 0;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: left;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    margin-bottom: 30px;
    text-align: center;
    content: '';
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(242, 244, 246, 0)),
      color-stop(60%, $light-grey)
    );
    background-image: linear-gradient(
      180deg,
      rgba(242, 244, 246, 0) 0%,
      $light-grey 60%
    );
  }

  @include scrollbars;
}
</style>
