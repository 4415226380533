<script>
import { authComputed } from '@state/helpers/auth'
import NavBarRoutes from '@components/nav-bar-routes/nav-bar-routes'
import i18n from '@src/i18n'
import { lunadisPayOrigin } from '@/src/constants/origins'
import { enterpriseComputed } from '@state/helpers/enterprise'

export default {
  components: { NavBarRoutes },
  data() {
    return {
      persistentNavRoutes: [],
      loggedOutNavRoutes: [
        {
          name: 'login',
          title: i18n.t('LOG_IN'),
        },
      ],
      loggedInEnterpriseRoutes: [],
    }
  },
  computed: {
    ...authComputed,
    ...enterpriseComputed,
    loggedInNavRoutes() {
      return [
        {
          name: 'expenses overview',
          title: () => i18n.t('HEADER.EXPENSES'),
        },
        this.currentEnterprise &&
        this.currentEnterprise.originating_system !== lunadisPayOrigin
          ? {
              name: 'reclaims overview',
              title: () => i18n.t('HEADER.RECLAIMS'),
            }
          : false,
      ].filter(Boolean)
    },
  },
}
</script>

<template>
  <ul>
    <NavBarRoutes :routes="persistentNavRoutes" />
    <NavBarRoutes
      v-if="loggedIn && !this.$route.path.includes('enterprises')"
      :routes="loggedInNavRoutes"
    />
    <NavBarRoutes
      v-else-if="loggedIn && this.$route.path.includes('enterprises')"
      :routes="loggedInEnterpriseRoutes"
    />
    <NavBarRoutes v-else :routes="loggedOutNavRoutes" />
  </ul>
</template>

<style lang="scss" scoped>
@import '@design';
</style>
