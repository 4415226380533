import request from '@utils/apiRequest'
import eventBus from '@src/event-bus'
import Router from '@src/router/index'
import i18n from '@src/i18n'

export const state = {
  category: null,
  categoryOverview: [],
  getReclaimExpensesInProgress: false,
  getInvoicesInProgress: false,
  getRefundsInProgress: false,
  expenses: [],
  currentReclaim: null,
  deletingInProgress: false,
  pagination: {
    page: 1,
    pageSize: 10,
    total: null,
  },
  reclaimLimits: null,
}

export const getters = {}

export const mutations = {
  SET_CURRENT_RECLAIM(state, reclaim) {
    state.currentReclaim = { ...reclaim }
  },
  SET_EXPENSES_BY_RECLAIM(state, response) {
    state.expenses = response.data.content
  },
  GET_EXPENSES_BY_RECLAIM_IN_PROGRESS(state, bool) {
    state.getReclaimExpensesInProgress = bool
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination
  },
  SET_DELETING_IN_PROGRESS(state, bool) {
    state.deletingInProgress = bool
  },
  SET_CATEGORY(state, category) {
    state.category = category
  },
  SET_CATEGORY_OVERVIEW(state, response) {
    state.categoryOverview = response.categories
  },
  SET_RECLAIM_LIMITS(state, limits) {
    state.reclaimLimits = limits
  },
}

export const actions = {
  getCategoryOverview({ commit }, uuId) {
    commit('expensesByCountry/SET_CATEGORY_OVERVIEW_IN_PROGRESS', true, {
      root: true,
    })
    return request
      .get(`reclaims/${uuId}/expenses/category`)
      .then((response) => {
        commit('SET_CATEGORY_OVERVIEW', response.data)
      })
      .catch((error) => {
        console.log(error.response.data)
        // TODO: Add error translations
        eventBus.$emit('growl', {
          title: 'Oops:',
          description:
            error.response.data.developer_message ||
            'Error fetching categories overview',
          type: 'error',
        })
      })
      .finally(() => {
        commit('expensesByCountry/SET_CATEGORY_OVERVIEW_IN_PROGRESS', false, {
          root: true,
        })
      })
  },
  getSingleReclaim({ commit }, uuId) {
    return request
      .get(`reclaims/${uuId}`)
      .then((response) => {
        commit('SET_CURRENT_RECLAIM', response.data)
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.user_message
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: message,
          type: 'error',
        })
      })
  },
  getExpenses({ commit, state }, params) {
    commit('GET_EXPENSES_BY_RECLAIM_IN_PROGRESS', true)
    return request
      .get(`reclaims/${params.uuId}/expenses`, { params })
      .then((response) => {
        commit('GET_EXPENSES_BY_RECLAIM_IN_PROGRESS', false)
        commit('SET_EXPENSES_BY_RECLAIM', response)
        commit('SET_PAGINATION', {
          ...state.pagination,
          total: response.data.total_results,
        })
      })
      .catch((error) => {
        console.log(error)
        commit('GET_EXPENSES_BY_RECLAIM_IN_PROGRESS', false)
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: 'Error fetching expenses',
          type: 'error',
        })
      })
  },
  deleteReclaim({ commit }, uuId) {
    commit('SET_DELETING_IN_PROGRESS', true)
    return request
      .delete(`reclaims/${uuId}`)
      .then((response) => {
        commit('SET_DELETING_IN_PROGRESS', false)
        eventBus.$emit('growl', {
          title: 'Success:',
          description: i18n.t('RECLAIM_SUCCESSFULLY_CANCELLED'),
          type: 'success',
        })
        Router.push('/reclaims')
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.user_message
        commit('SET_DELETING_IN_PROGRESS', false)
        // TODO: Add error translations
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: message,
          type: 'error',
        })
      })
  },
  fetchReclaimLimits({ commit }, country) {
    return request
      .get(`reclaimlimits/${country}`)
      .then((response) => {
        commit('SET_RECLAIM_LIMITS', response.data)
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.user_message
        eventBus.$emit('growl', {
          description: message,
          type: 'error',
        })
      })
  },
}
