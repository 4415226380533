<script>
import i18n from '@src/i18n'
import { requiredValidation } from '@utils/inputValidation'
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { pick } from 'lodash'
import ExpenseCountryDropdown from '@components/expense-country-dropdown/expense-country-dropdown'
import EventBus from '@src/event-bus'
import { getSavedStateSession } from '@utils/storage'
export default {
  components: {
    ExpenseCountryDropdown,
  },
  data() {
    return {
      inputRules: [requiredValidation],
      countryFormIsValid: true, // The form is valid as default
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'isReadOnly',
      'country',
      'currency',
      'getIsCurrencyValid',
      'getCurrency',
    ]),
    currencies() {
      return [
        this.getCurrency(this.country),
        this.getIsCurrencyValid ? null : this.currency,
      ].filter(Boolean)
    },
    currencyMismatchWarning() {
      return this.getIsCurrencyValid ? '' : i18n.t('CURRENCY_MISMATCH')
    },
  },
  mounted() {
    EventBus.$on('validate-fields', () => {
      this.$refs.countryForm.validate()
    })
  },
  beforeDestroy() {
    EventBus.$off('validate-fields')
  },
  methods: {
    ...pick(expenseModalMethods, ['updateCountry', 'updateCurrency']),

    onSelectCurrency() {
      this.updateCurrency({ country: this.country })
    },
  },
}
</script>

<template>
  <div>
    <v-form>
      <!-- Expense country -->
      <v-row>
        <v-col sm="8" cols="8">
          <ExpenseCountryDropdown
            :value="country"
            :on-select="updateCountry"
            :label="$t('LABEL_RECLAIM_COUNTRY')"
            :disabled="isReadOnlyUser || isReadOnly"
            :rules="inputRules"
            :error-messages="currencyMismatchWarning"
          />
        </v-col>
        <!-- Expense currency -->
        <v-col sm="4" cols="4">
          <v-autocomplete
            outlined
            :items="currencies"
            :label="$t('LABEL_CURRENCY')"
            :value="currency"
            :menu-props="{ offsetY: true }"
            :disabled="isReadOnlyUser || currencies.length < 2"
            :class="['bulky', 'forced', $style.currency]"
            @change="onSelectCurrency"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.currency {
  :global(.v-icon--disabled) {
    display: none;
  }
}
</style>
