import request from '@utils/apiRequest'

import { keyBy } from 'lodash'

export const state = {
  cache: {
    queries: {},
  },
  byCode: {},
}

export const getters = {
  getCountries: (state) => {
    return Object.values(state.byCode)
  },
  getCurrency: (state) => (code) => {
    return state.byCode[code] && state.byCode[code].currency
  },
}

export const mutations = {
  ADD_COUNTRIES(state, { queryId, query, response, success }) {
    const queryDetails = {
      createdAt: Date.now(),
      id: queryId,
      query,
      success,
      response,
    }

    state.cache.queries[queryId] = queryDetails
    if (success) {
      state.byCode = keyBy(response.content, 'code')
    }
  },
}

export const actions = {
  async fetchCountries({ commit, state }, { query, useCache = true }) {
    const queryId = `ITEMS_${JSON.stringify(query)}`
    const { pageSize } = query

    // Check if we have cached this
    const cacheHit = useCache && state.cache.queries[queryId]
    if (!cacheHit || !cacheHit.success) {
      // Call the API
      const response = await request.get(`countries`, {
        params: {
          page_size: pageSize,
        },
      })
      // Handle the response
      commit('ADD_COUNTRIES', {
        queryId,
        query,
        response: response.data,
        success: response.statusOK,
      })
    }
    return { queryId }
  },
}
