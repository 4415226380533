<script>
import { pick, isNumber, debounce } from 'lodash'
import request from '@utils/apiRequest'
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import i18n from '@src/i18n'
import { inScacDigitNumberRange } from '@utils/inputValidation'
import EventBus from '@src/event-bus'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
    shouldValidateOnMount: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  data: () => {
    return {
      split: {
        index: null,
        category: {},
        country: null,
        scacCode: '',
        scacType: '',
      },
      scacCodeBackendValidationResult: true,
      unwatchStore: () => {},
      isFocused: false,
      shouldShowNoValueMessage: false,
    }
  },

  computed: {
    ...pick(expenseModalComputed, [
      'isReadOnly',
      'country',
      'getScac',
      'getCategory',
      'isNewExpense',
      'splits',
      'getScacType',
    ]),
    scacCodeValidation() {
      const { major, minor, patch } = this.getScacFromScacCode(
        this.split.scacCode
      )
      return [
        inScacDigitNumberRange(major, 1, 10),
        inScacDigitNumberRange(minor, 1, 17),
        inScacDigitNumberRange(patch, 1, 4),
        this.scacCodeBackendValidationResult,
      ].filter((msg) => typeof msg === 'string')
    },
    errorMessage() {
      return this.split.scacCode ? this.scacCodeValidation : null
    },
    messages() {
      const { scacCode, scacType } = this.split
      if (!scacCode) {
        if (scacType === 'NO_REFUND') {
          return i18n.t('SCAC_TYPE_NO_REFUND_MESSAGE')
        } else if (!this.isNewExpense && this.shouldShowNoValueMessage) {
          return i18n.t('INPUT_MISSING_VALUE')
        }
      }
      return ''
    },

    inputState() {
      return {
        hasError:
          this.split.scacCode && this.scacCodeBackendValidationResult !== true,
        isValid:
          (this.split.scacCode && !this.scacCodeValidation.length) ||
          this.split.scacType === 'NO_REFUND',
        hasNoValue:
          !this.isNewExpense &&
          this.split.scacType !== 'NO_REFUND' &&
          !this.split.scacCode,
      }
    },
  },
  watch: {
    // When local 'scacCode' (string) changes
    'split.scacCode': function (newScacCode, oldScacCode) {
      const { scac, scacCode } = this.cleanupScacCode(newScacCode) // 1f.2.3 -> '1.2.3'
      // If scacCode changed we might want to remove the scac wizard vat rates
      if (oldScacCode !== newScacCode) {
        /* If the field is focused and scac code is changed it means we want to
        manually enter the code so we set scac_type to 'CODE' */
        if (this.isFocused) {
          this.updateScacType({
            scacType: 'CODE',
            splitIndex: this.split.index,
          })
        }
      }
      this.updateExpenseScac({ scac, splitIndex: this.split.index })
      if (scacCode) {
        this.debouncedScacCodeValidation(this.split.country, scacCode)
      } else {
        this.scacCodeBackendValidationResult = true
      }
    },
  },
  created() {
    // If the prop `shouldValidateOnMount` is true, we want to show the
    // "no value" message
    this.shouldShowNoValueMessage = this.shouldValidateOnMount

    // Function that validates the scac code at the SCAC engine. Here we are
    // creating a debounced function that delays invoking the scac validation at
    // the SCAC engine
    this.debouncedScacCodeValidation = debounce(async (country, scacCode) => {
      const { data } = await request.get(`scacvalidation`, {
        baseUrl: 'scac',
        skipAuthentication: true,
        params: {
          country: country,
          code: scacCode,
        },
      })
      this.scacCodeBackendValidationResult = data.valid
        ? true
        : i18n.t('SCAC_CODE_INVALID_IN_COUNTRY', {
            interpolation: false,
            code: scacCode,
            country: i18n.t(`ALPHA3_COUNTRY.${country}`),
          })
    }, 500)

    // Start watching Vuex store
    this.unwatchStore = this.$store.watch(
      // Reactively watch this functions return value
      (state, getter) => {
        const index = this.splitIndex
        const category = this.getCategory(index) || {}
        const country = this.country
        const scac = this.getScac(index)
        const scacCode = this.getScacCodeFromScac(scac)
        const scacType = this.getScacType(this.splitIndex) || ''

        // Return a primitive value
        return JSON.stringify({
          index,
          category,
          country,
          scacCode,
          scacType,
        })
      },

      // Call this function when the value changes
      (json) => {
        this.split = JSON.parse(json)
      },

      // Options
      { immediate: true }
    )
  },
  beforeDestroy() {
    this.unwatchStore()
  },

  methods: {
    ...pick(expenseModalMethods, [
      'updateExpenseScac',
      'setScacWizardSplitIndex',
      'updateScacType',
    ]),

    // This helper method takes a scac code string, cleans it up, throws away
    // incorrect characters and returns a scac object
    getScacFromScacCode(scacCode) {
      const [major, minor, patch] = (scacCode || '')
        .split('.')
        .map((n) => n.replace(/\D+/g, '')) // replace non digits with nothing
        .filter(Boolean) // only keep truthy strings (i.e. no empty strings)
        .map(Number) // cast to a number
      return {
        major: major || null,
        minor: minor || null,
        patch: patch || null,
      }
    },

    // This helper method takes a scac code string (like "1.9.2") and returns an
    // object with a scac and a scacCode property.
    cleanupScacCode(code) {
      const scac = this.getScacFromScacCode(code)
      const scacCode = this.getScacCodeFromScac(scac)
      return { scac, scacCode }
    },

    openWizard() {
      this.setScacWizardSplitIndex(this.split.index)
      EventBus.$emit('open-scac-wizard')
    },
    // This helper method takes a scac object, and returns a scac code string
    getScacCodeFromScac(scac) {
      const { major, minor, patch } = scac || {}
      return [major, minor, patch].filter(isNumber).join('.')
    },

    onFocus(bol) {
      this.isFocused = true
    },
    onBlur() {
      this.isFocused = false
      this.shouldShowNoValueMessage = true
    },
  },
}
</script>

<template>
  <v-text-field
    v-model="split.scacCode"
    :label="$t('SCAC_CODE')"
    :class="{
      [$style.hasFocus]: isFocused,
      [$style.hasError]: inputState.hasError,
      [$style.isValid]: inputState.isValid,
      noValue: inputState.hasNoValue,
      bulky: true,
      forced: true,
      SCAC: true,
    }"
    outlined
    :messages="messages"
    :append-icon="!isReadOnly && split.category.key ? 'mdi-chat' : ''"
    required
    :disabled="disabled || isReadOnly || !country"
    :error-messages="errorMessage"
    placeholder="0.0.0"
    @click:append="openWizard"
    @focus="onFocus"
    @blur="onBlur"
  >
    <template v-slot:prepend-inner>
      <div :class="$style.icon">e</div>
    </template>
  </v-text-field>
</template>

<style lang="scss" module>
@import '@design';

.icon {
  width: 16px;
  margin: 5px 8px 0 4px;
  overflow: hidden;
  text-indent: -20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z' fill='%238C949E' fill-rule='nonzero'/%3e%3c/svg%3e");
  background-position: center;
  &::after {
    display: none !important;
  }
}

.hasError {
  :global(.v-label) {
    color: $alert-error;
  }
  .icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z' fill='%23d5345d' fill-rule='nonzero'/%3e%3c/svg%3e");
  }
}

.isValid {
  .icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z' fill='%230072e1' fill-rule='nonzero'/%3e%3c/svg%3e");
  }
}
.noValue {
  .icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z' fill='%23f8a000' fill-rule='nonzero'/%3e%3c/svg%3e");
  }
}
.isEmpty:not(.isValid) {
  :global(.v-label) {
    color: $orange;
  }
  .icon {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z' fill='%23f8a000' fill-rule='nonzero'/%3e%3c/svg%3e");
  }
  :global(.v-messages.error--text) {
    color: $dark-grey !important;
  }
}

.hasFocus:not(.isValid):not(.hasError) {
  :global(.v-label),
  .icon {
    color: $dark !important;
  }

  :global(.v-messages.error--text) {
    color: $dark !important;
  }
}
</style>
