import { render, staticRenderFns } from "./_base-expense-modal.vue?vue&type=template&id=19d54524&"
import script from "./_base-expense-modal.vue?vue&type=script&lang=js&"
export * from "./_base-expense-modal.vue?vue&type=script&lang=js&"
import style0 from "./_base-expense-modal.vue?vue&type=style&index=0&id=19d54524&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
