<script>
import { requiredValidation } from '@utils/inputValidation'
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import formatDate from '@utils/format-date'
import { pick } from 'lodash'
import EventBus from '@src/event-bus'
import { getSavedStateSession } from '@utils/storage'
export default {
  props: {
    setValidSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      transactionFormIsValid: true, // The form is valid as default
      isShowingDatePicker: false, // Don't show date picker as default
      transaction: {
        date: null,
        invoiceNumber: null,
      },
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'isReadOnly',
      'isNewExpense',
      'isManuallyCreated',
      'shouldShowInvoiceNumber',
      'date',
      'invoiceNumber',
    ]),
    formattedDate() {
      return formatDate(this.date)
    },
    inputRules() {
      return [requiredValidation]
    },
  },
  watch: {
    // When local 'transactionFormIsValid' changes
    transactionFormIsValid: {
      immediate: true,
      handler(bool) {
        this.setValidSection(bool)
      },
    },
    date(value) {
      this.setDate(value)
    },
  },
  created() {
    this.transaction.date = this.date
    this.transaction.invoiceNumber = this.invoiceNumber
  },
  mounted() {
    EventBus.$on('validate-fields', () => {
      this.$refs.transactionForm.validate()
    })
  },
  beforeDestroy() {
    EventBus.$off('validate-fields')
  },
  methods: {
    ...pick(expenseModalMethods, ['updateDate', 'updateInvoiceNumber']),

    setInvoiceNumber() {
      const invoiceNumber = this.transaction.invoiceNumber
        ? this.transaction.invoiceNumber.trim()
        : null

      this.transaction.invoiceNumber = invoiceNumber // Update UI input
      this.updateInvoiceNumber(invoiceNumber) // Update vuex
    },
    setDate(date) {
      this.isShowingDatePicker = false
      this.updateDate(new Date(date).toISOString())
    },
    allowedDates: (val) => {
      return new Date(val) <= new Date()
    },
    setNoValueClass(value) {
      return !this.isNewExpense && !value ? 'noValue' : ''
    },
    setMessages(value) {
      return !this.isNewExpense && !value
        ? this.$i18n.t('INPUT_MISSING_VALUE')
        : ''
    },
  },
}
</script>

<template>
  <div>
    <h2
      v-if="shouldShowInvoiceNumber || isManuallyCreated || isNewExpense"
      class="expenseTitle"
      >{{ $t('EXPENSE_DETAILS_TITLE') }}</h2
    >
    <v-form ref="transactionForm" v-model="transactionFormIsValid">
      <!-- Invoice number -->
      <v-row v-if="shouldShowInvoiceNumber">
        <v-col sm="12" cols="12">
          <v-text-field
            v-model="transaction.invoiceNumber"
            outlined
            :label="$t('INVOICE_NUMBER')"
            :class="[
              setNoValueClass(transaction.invoiceNumber),
              $style.noValueWarning,
            ]"
            :messages="setMessages(transaction.invoiceNumber)"
            :disabled="isReadOnlyUser || isReadOnly"
            @blur="setInvoiceNumber"
          />
        </v-col>
      </v-row>

      <!-- Date -->
      <v-row v-if="isManuallyCreated || isNewExpense">
        <v-col sm="12" cols="12">
          <v-menu
            v-model="isShowingDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <!-- Text field showing the date -->
              <v-text-field
                v-model="formattedDate"
                :label="$t('DATE')"
                readonly
                outlined
                :rules="inputRules"
                :disabled="isReadOnlyUser"
                v-on="on"
              ></v-text-field>
            </template>

            <!-- Date picker -->
            <v-date-picker
              v-model="transaction.date"
              :allowed-dates="allowedDates"
              no-title
              scrollable
              :disabled="isReadOnlyUser"
              @change="setDate"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.noValueWarning {
  :global(.v-input__append-inner) {
    align-self: center;
  }
}
</style>
