import i18n from '@src/i18n'
import eventBus from '@src/event-bus'
import Router from '@src/router/index'
import activitySetup, { clearTimers } from '@src/activityListener'
import { saveExpenseToStorage } from '@state/utils/expense-modal'

import {
  getSavedStateSession,
  removeSavedStateSession,
  saveStateSession,
} from '@utils/storage'
import request from '@utils/apiRequest'

export const state = {
  currentUser: null,
  isUserAuthenticated: false,
  isForgotPasswordActive: false,
  isUserIdle: false,
  shouldShowSecurityCheck: false,
  isVattaxAdmin: false,
  isReadOnlyUser: false,
  currentUserPhoto: null,
  lastLoggedInUser: '',
}

export const mutations = {
  SET_PREAUTHENTICATED_USER(state, username) {
    state.currentUser = username
  },
  SET_AUTHENTICATED_USER(state, bool) {
    state.isUserAuthenticated = bool
  },
  TOGGLE_FORGOT_PASSWORD(state, bool) {
    state.isForgotPasswordActive = bool
  },
  SET_USER_IDLE(state, bool) {
    state.isUserIdle = bool
  },
  SET_SECURITY_CHECK_STATE(state, bool) {
    state.shouldShowSecurityCheck = bool
  },
  SET_IS_VATTAX_ADMIN(state, bool) {
    state.isVattaxAdmin = bool
  },
  SET_CURRENT_USER_PHOTO(state, photo) {
    state.currentUserPhoto = photo
  },
  SET_LAST_LOGGED_IN_USER(state, userName) {
    state.lastLoggedInUser = userName
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.isUserAuthenticated
  },
  isUserIdle(state) {
    return state.isUserIdle
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    dispatch('validate')
  },

  // 1st step of authentication.
  logIn({ commit, dispatch, getters }, { username, password } = {}) {
    if (getters.loggedIn) return dispatch('validate')

    const options = {
      params: {
        j_username: username,
        j_password: password,
      },
      addAcceptLanguage: true,
      skipAuthentication: true,
    }
    return request
      .post(`auth`, options)
      .then((response) => {
        commit('SET_PREAUTHENTICATED_USER', username)
        const token = response.headers['cap-auth']
        saveStateSession('token', token)

        // check whether or not we need 2nd step
        return request
          .get('principals')
          .then((response) => {
            dispatch('authenticateUser', response)
          })
          .catch((error) => {
            const { status } = error && error.response
            if (status === 401) {
              commit('SET_SECURITY_CHECK_STATE', true)
            }
          })
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data.user_message_code ===
            'vattax.exception.unauthorized.locked'
        ) {
          eventBus.$emit('growl', {
            title: 'Error:',
            description: i18n.t('AUTH_ACCOUNT_LOCKED'),
            type: 'error',
          })
        } else {
          eventBus.$emit('growl', {
            title: 'Error:',
            description: i18n.t('AUTH_CREDENTIALS_INVALID'),
            type: 'error',
          })
        }
      })
  },

  // 2nd step of authentication.
  submitSecurityCode({ dispatch, commit }, code) {
    const options = {
      body: {
        code,
        username: state.currentUser,
      },
    }
    return request
      .post(`codeVerification`, options)
      .then((response) => {
        const token = response.headers['cap-auth']
        saveStateSession('token', token)

        // get user data after successful login
        return request
          .get('principals')
          .then((response) => {
            dispatch('authenticateUser', response)
          })
          .catch((error) => {
            // what to do here?
            console.log(error)
          })
      })
      .catch((error) => {
        const message =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.developer_message

        if (message && message.toLowerCase() === 'invalid verification code') {
          eventBus.$emit('growl', {
            title: 'Error:',
            description: i18n.t('AUTH_CODE_INVALID'),
            type: 'error',
          })
        } else {
          commit('SET_SECURITY_CHECK_STATE', false)
          removeSavedStateSession('token')
        }
      })
  },

  resetPassword({ rootState }, { newPassword, confirmPassword, token }) {
    return request
      .post(`forgotPassword/${token}`, {
        body: {
          newPassword,
          confirmPassword,
        },
        skipAuthentication: true,
      })
      .then(() => {
        eventBus.$emit('growl', {
          title: 'Success:',
          description: i18n.t('PASSWORD_CHANGE_SUCCESS'),
          type: 'success',
        })
        setTimeout(() => {
          Router.push('/')
        }, 2000)
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.developer_message
        let description

        if (
          message &&
          message.toLowerCase() === 'password reset link expired'
        ) {
          description = i18n.t('PASSWORD_CHANGE_EXPIRED')
        } else if (
          message &&
          message.toLowerCase() ===
            'password does not meet history requirements'
        ) {
          description = i18n.t('PASSWORD_CHANGE_HISTORY')
        } else {
          description = message
        }

        eventBus.$emit('growl', {
          title: 'Error:',
          description,
          type: 'error',
        })
      })
  },

  createPassword({ rootState }, { newPassword, confirmPassword, token }) {
    return request
      .post(`principals/activate/${token}`, {
        body: {
          newPassword,
          confirmPassword,
        },
        skipAuthentication: true,
      })
      .then(() => {
        eventBus.$emit('growl', {
          title: 'Success:',
          description: i18n.t('PASSWORD_CREATE_SUCCESS'),
          type: 'success',
        })
        setTimeout(() => {
          Router.push('/')
        }, 2000)
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.developer_message
        let description

        if (
          message &&
          message.toLowerCase() === 'password reset link expired'
        ) {
          description = i18n.t('PASSWORD_CREATE_EXPIRED')
        } else if (
          message &&
          message.toLowerCase() ===
            'password does not meet history requirements'
        ) {
          description = i18n.t('PASSWORD_CHANGE_HISTORY')
        } else if (
          message &&
          message.toLowerCase() === 'create link does not exist'
        ) {
          description = i18n.t('USER_ALREADY_ACTIVATED')
        } else {
          description = message
        }

        eventBus.$emit('growl', {
          title: 'Error:',
          description,
          type: 'error',
        })
      })
  },

  changePassword({ rootState }, { body, callback }) {
    return request
      .post('updatePassword', {
        body: body,
      })
      .then(() => {
        eventBus.$emit('growl', {
          title: 'Success:',
          description: i18n.t('PASSWORD_CHANGE_SUCCESS'),
          type: 'success',
        })
        callback()
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.user_message
        let description
        if (message) {
          switch (message.toLowerCase()) {
            case 'password history policy is not fulfilled':
              description = 'PASSWORD_CHANGE_HISTORY'
              break
            case 'current password mismatch':
              description = 'CHANGE_PASSWORD_CURRENT_MISMATCH'
              break
            default:
              description = 'PASSWORD_CHANGE_FAIL'
              break
          }
        }

        eventBus.$emit('growl', {
          title: 'Error:',
          description: i18n.t(description),
          type: 'error',
        })
      })
  },
  setUserIdle({ commit }, bool) {
    commit('SET_USER_IDLE', bool)
  },

  // Logs out the current user
  logOut({ commit }) {
    commit('SET_AUTHENTICATED_USER', false)
    commit('SET_PREAUTHENTICATED_USER', null)
    commit('enterprise/SET_CURRENT_ENTERPRISE', {}, { root: true })
    commit('SET_CURRENT_USER_PHOTO', null)
    removeSavedStateSession('selectedEnterprise')
    removeSavedStateSession('token')
    removeSavedStateSession('isVattaxAdmin')
    removeSavedStateSession('isReadOnlyUser')
    removeSavedStateSession('user')
    removeSavedStateSession('expenseOverview.year')
    removeSavedStateSession('expensesByCountry.year')
    removeSavedStateSession('expenseId')
    removeSavedStateSession('expense')
    removeSavedStateSession('auth.userPhoto')
    clearTimers()
    Router.push('/logout')
  },

  authenticateUser({ commit, dispatch }, response) {
    commit('SET_AUTHENTICATED_USER', true)
    commit('expenseOverview/SET_PAGE', 1, { root: true })
    const isVattaxAdmin = response.data.roles.includes('VATTAX_ADMIN')
    const isReadOnlyVattaxAdmin = response.data.roles.includes(
      'READ_ONLY_VATTAX_ADMIN'
    )
    const isAdminUser = isVattaxAdmin || isReadOnlyVattaxAdmin
    const isReadOnlyUser = isReadOnlyVattaxAdmin && !isVattaxAdmin
    saveStateSession('isVattaxAdmin', isAdminUser)
    saveStateSession('isReadOnlyUser', isReadOnlyUser)
    saveStateSession('user', {
      name: `${response.data.first_name} ${response.data.last_name}`,
      id: response.data.id,
    })
    if (!isAdminUser && response.data.imageContent) {
      dispatch('getCurrentUserPhoto', response.data.id)
    }
    dispatch('getInitialRoute', isAdminUser)
    activitySetup()
  },

  getCurrentUserPhoto({ commit }, userId) {
    return request
      .get(`principals/${userId}/image`, { responseType: 'blob' })
      .then((response) => {
        const reader = new FileReader()
        reader.readAsDataURL(new Blob([response.data]))
        reader.onload = () => {
          commit('SET_CURRENT_USER_PHOTO', reader.result)
          saveStateSession('auth.userPhoto', reader.result)
          commit('general/TOGGLE_USER_MENU_KEY', null, { root: true }) // Rerender user header
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },

  getInitialRoute(_, isVattaxAdmin) {
    // handle direct link expense
    const directLinkExpenseId = getSavedStateSession('expenseId')

    if (directLinkExpenseId) {
      // if direct link expense id exists, redirect to expense details for that
      // expense. if the fetching has failed, redirect to expense overview
      saveExpenseToStorage(directLinkExpenseId).then((response) => {
        if (response.id) {
          Router.push({
            name: 'expense details',
            params: { country: response.country, id: response.id },
          })
        } else {
          let description
          switch (response) {
            case 401:
              description = 'DIRECT_LINK_EXPENSE_UNAUTHORIZED'
              break
            case 404:
              description = 'DIRECT_LINK_EXPENSE_NOT_FOUND'
              break
            default:
              description = 'GENERAL_ERROR'
          }
          eventBus.$emit('growl', {
            title: ' ',
            description: i18n.t(description),
            type: 'error',
          })
          Router.push('/overview')
        }
      })
    } else {
      if (isVattaxAdmin) {
        Router.push('/enterprises')
      } else {
        Router.push('/overview')
      }
    }
  },

  // Reset to 1st step of authentication
  resetPreauthenticatedUser({ commit }) {
    commit('SET_PREAUTHENTICATED_USER', null)
  },

  // forgot password screen activation
  toggleForgotPassword({ commit }, bool) {
    commit('TOGGLE_FORGOT_PASSWORD', bool)
  },

  // forgot password sending.
  forgotPassword({ commit }, email) {
    return request
      .post('forgotPassword', {
        body: {
          email,
        },
        skipAuthentication: true,
        addAcceptLanguage: true,
      })
      .then(() => {
        eventBus.$emit('growl', {
          title: ' ',
          description: i18n.t('PASSWORD_FORGOT_INFO'),
          type: 'success',
        })

        // TODO implement redirecting message somewhere maybe?
        setTimeout(() => {
          commit('TOGGLE_FORGOT_PASSWORD', false)
        }, 1000)
      })
      .catch(() => {
        eventBus.$emit('growl', {
          title: ' ',
          description: i18n.t('GENERAL_ERROR'),
          type: 'error',
        })
      })
  },

  refreshToken() {
    return request
      .post(`auth/refreshToken`)
      .then((response) => {
        const token = response.headers['cap-auth']
        saveStateSession('token', token)
      })
      .catch((error) => {
        console.log(error)
      })
  },

  validate({ commit, dispatch }) {
    const user = getSavedStateSession('user')
    commit('SET_AUTHENTICATED_USER', !!user)
    return new Promise((resolve) => {
      resolve({ user })
    })
  },
}
