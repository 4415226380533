import 'babel-polyfill'

import Vue from 'vue'
import axios from 'axios'
import VueAmplitude from 'vue-amplitude'
import vuetify from './plugins/vuetify'
import VueFragment from './plugins/vueFragment'
import VueAnalytics from 'vue-analytics'
import i18n from './i18n'
import AppComponent from './app'
import router from '@router'
import store from '@state/store'
import { startWatchingStoreChanges } from '@state/watchers'
import activitySetup from '@src/activityListener'
import '@components/_globals'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import ZoomOnHover from 'vue-zoom-on-hover'
import panZoom from 'vue-panzoom'

Vue.use(ZoomOnHover)
Vue.use(panZoom)
Vue.use(VueAnalytics, {
  id: 'UA-77897671-6',
})

Vue.use(VueAmplitude, { apiKey: 'd1cbf745cc8acc7fdf2a6a65d5a2002d' })
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://53a50c6f60f54cdca1c8dff79916983f@sentry.io/1833547',
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
    ],
  })
}
// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV !== 'production'
// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

// Start listening for acitivity (logout if inactive)
activitySetup()

let app

/**
 * Function that mounts the app. This function will also make sure that the API
 * base url's are set before mounting (only in production mode).
 */
async function mountApp() {
  if (process.env.NODE_ENV !== 'development') {
    // If we are in production we set up the api url's before we mount
    const setApiUrls = async () => {
      try {
        const { data } = await axios.get('/config')

        const API_URL = data.api_endpoint
        const LOGO_SERVICE = data.api_logo_service_endpoint
        const VAT_QUESTION_SERVICE = data.api_vat_question_service_endpoint
        const VAT_RATES_SERVICE = data.api_vat_rates_endpoint
        const RECLAIM_PERIOD_DAYS = data.reclaim_period_notice_days
        const LOGO_API_SOLUTION_ID = data.logo_api_solution_id

        // Set all api urls before resolving
        return Promise.all([
          store.dispatch('general/setApiUrl', API_URL),
          store.dispatch('general/setLogoUrl', LOGO_SERVICE),
          store.dispatch(
            'general/setVatQuestionServiceUrl',
            VAT_QUESTION_SERVICE
          ),
          store.dispatch('general/setVatRatesUrl', VAT_RATES_SERVICE),
          store.dispatch('general/setReclaimPeriod', RECLAIM_PERIOD_DAYS),
          store.dispatch('general/setLogoApiSolutionId', LOGO_API_SOLUTION_ID),
        ])
      } catch (error) {
        console.error(error)
      }
    }
    await setApiUrls()
  } else {
    // If we are in development we set the api url's to the default values
    store.dispatch('general/setApiUrl', process.env.VUE_APP_API_URL)
    store.dispatch('general/setLogoUrl', process.env.VUE_APP_LOGO_SERVICE)
    store.dispatch(
      'general/setVatQuestionServiceUrl',
      process.env.VUE_APP_VAT_QUESTION_SERVICE
    )
    store.dispatch(
      'general/setVatRatesUrl',
      process.env.VUE_APP_VAT_RATES_SERVICE
    )
    store.dispatch(
      'general/setReclaimPeriod',
      process.env.VUE_APP_RECLAIM_PERIOD_NOTICE_DAYS
    )
    store.dispatch(
      'general/setLogoApiSolutionId',
      process.env.VUE_APP_LOGO_API_SOLUTION_ID
    )
  }

  app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    VueFragment,
    render: (h) => h(AppComponent),
  }).$mount('#app')

  return Promise.resolve()
}

mountApp().then(() => {
  startWatchingStoreChanges(store)
})

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
