import { isNumber, isNaN } from 'lodash'

/**
 * Helper function that evaluates if `value` is classified as a Number primitive or object, and if it's not NaN
 * @param {number} value
 */
export const isValidNumber = (value) => isNumber(value) && !isNaN(value)

// TODO: Until we have a decimal library we use toFixed in combination with Number to throw away decimals that may (or may not) be valuable
export const roundUNSAFE = (value, decimals = 4) =>
  isValidNumber(value) ? Number(value.toFixed(decimals)) : null

/**
 * @typedef {Object} VatData
 * @property {number} [amountExclVat] Total amount exclusive VAT
 * @property {number} [amountInclVat] Total amount inclusive VAT
 * @property {number} [vatPercent] The vat percent in the 0-1 range
 * @property {number} [vatAmount] The vat amount
 */

/**
 * Helper function that calculates amountExclVat, amountInclVat, vatPercent,
 * vatAmount based on only two of the values.
 * @param {VatData} data
 */
export const calculateVatValues = (data) => {
  let { amountExclVat, amountInclVat, vatPercent, vatAmount } = data

  // As long as we know two of the values we can calculate the other two. There
  // are six possible combinations.
  if (isValidNumber(amountExclVat) && amountExclVat !== 0) {
    if (isValidNumber(amountInclVat)) {
      vatAmount = amountInclVat - amountExclVat
      vatPercent = vatAmount / amountExclVat
    } else if (isValidNumber(vatPercent)) {
      vatAmount = amountExclVat * vatPercent
      amountInclVat = amountExclVat + vatAmount
    } else if (isValidNumber(vatAmount)) {
      amountInclVat = amountExclVat + vatAmount
      vatPercent = vatAmount / amountExclVat
    }
  } else if (isValidNumber(amountInclVat)) {
    if (isValidNumber(vatPercent)) {
      amountExclVat = amountInclVat / (1 + vatPercent)
      vatAmount = amountInclVat - amountExclVat
    } else if (isValidNumber(vatAmount)) {
      amountExclVat = amountInclVat - vatAmount
      vatPercent = amountExclVat !== 0 ? vatAmount / amountExclVat : 0
    }
  } else if (isValidNumber(vatPercent)) {
    if (isValidNumber(vatAmount)) {
      amountExclVat = vatAmount / vatPercent
      amountInclVat = amountExclVat + vatAmount
    }
  }

  return {
    amountExclVat: roundUNSAFE(amountExclVat),
    amountInclVat: roundUNSAFE(amountInclVat),
    vatPercent: roundUNSAFE(vatPercent),
    vatAmount: roundUNSAFE(vatAmount),
  }
}
