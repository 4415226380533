import axios from 'axios'

const fallbackLanguage = 'en'

export const state = {
  cache: {
    queries: {},
  },
}

export const getters = {}
export const mutations = {}

export const actions = {
  async fetchQuestion({ commit, state, rootGetters }, { query }) {
    if (!query.language) {
      if (process.env.NODE_ENV !== 'production') {
        throw new Error(`query.language may not be ${query.language}.`)
      } else {
        query.language = fallbackLanguage
      }
    }

    // Check if we have cached this
    const queryId = JSON.stringify(query)

    // We only want to use the cache if we are answering the first question.
    let cacheHit
    if (!query.id) {
      cacheHit = state.cache.queries[queryId]
      if (cacheHit) {
        return cacheHit
      }
    }

    if (!cacheHit) {
      const callScacAPI = async ({ country, category, id }) => {
        try {
          const url = [
            rootGetters['general/getVatQuestionServiceUrl'],
            'questions/tree',
            country,
            category,
            id,
          ]
            .filter(Boolean)
            .join('/')
          const config = {
            headers: { 'Accept-Language': query.language || fallbackLanguage },
          }
          const response = await axios.get(url, config)
          response.data = { data: response.data }
          return response
        } catch ({ response }) {
          return response
        }
      }

      // Call the API
      const { country, category, id } = query
      const response = await callScacAPI({ country, category, id })
      const isOk = response.status >= 200 && response.status < 300

      // Cache the response if it is the first question and successful
      if (!query.id && isOk) {
        state.cache.queries[queryId] = response.data
      }

      return response.data
    }
  },
}
