<script>
import { expenseModalComputed } from '@state/helpers/expense-modal'
import ExpenseDetails from '@components/expense-details/expense-details'
import ExpenseReceipt from '@components/expense-receipt/expense-receipt'
import { pick } from 'lodash'

export default {
  components: {
    ExpenseReceipt,
    ExpenseDetails,
  },
  computed: {
    ...pick(expenseModalComputed, [
      'expenseKey',
      'isShowingExpenseModal',
      'receipt',
    ]),
  },
}
</script>

<template>
  <v-dialog
    v-model="isShowingExpenseModal"
    transition="vattax-modal"
    :content-class="
      receipt.id ? 'expenseModal' : `expenseModal ${$style.receiptUploadMode}`
    "
    overlay-color="#222F44"
    overlay-opacity="0.8"
    persistent
  >
    <div :key="expenseKey" :class="$style.modal">
      <!-- Receipt (left side of expense modal) -->
      <ExpenseReceipt v-if="receipt.id" />
      <!-- Details (right side of expense modal) -->
      <ExpenseDetails />
    </div>
  </v-dialog>
</template>

<style lang="scss" module>
@import '@design';

.modal {
  display: flex;
  min-height: 100%;
  text-align: center;
}

.receiptUploadMode {
  justify-content: center;
  width: 100%;
  max-width: 530px;
}
</style>
