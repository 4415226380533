<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { isAllowedReceiptMimeType } from '@utils/fileTypeValidator'
import pdf from 'vue-pdf'
import { pick } from 'lodash'
import Loader from '@components/custom-loader/custom-loader'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'
import { getSavedStateSession } from '@utils/storage'

const emptyImage = {
  content: null,
  id: '',
  name: '',
  type: '',
}

export default {
  components: {
    pdf,
    Loader,
  },
  data() {
    return {
      isIE: !!window.navigator.msSaveOrOpenBlob,
      pdfPageCount: 0,
      image: { ...emptyImage },
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['isReadOnly', 'receipt']),
  },
  watch: {
    'receipt.id': {
      immediate: true,
      handler(id) {
        if (!id) {
          this.image = { ...emptyImage }
        }
      },
    },
    'receipt.isLoadingImage': {
      immediate: true,
      handler(loading) {
        if (!loading) {
          this.image = this.$store.getters['receipts/item'](
            this.receipt.id
          ) || { ...emptyImage }
        }
      },
    },
    'image.type': {
      immediate: true,
      handler(type) {
        if (type === 'application/pdf') {
          pdf.createLoadingTask(this.image.content).then((pdf) => {
            this.pdfPageCount = pdf.numPages
          })
        }
      },
    },
  },
  methods: {
    ...pick(expenseModalMethods, ['uploadReceiptImage']),

    handleUpload(event) {
      const file = event.target.files[0]
      if (!isAllowedReceiptMimeType(file.type)) {
        eventBus.$emit('growl', {
          title: 'Error:',
          description: i18n.t('FILE_UPLOAD_TYPE_ERROR_MESSAGE'),
          type: 'error',
        })
        return // abort upload
      }
      this.$amplitude.getInstance().logEvent('UPLOAD_RECEIPT')
      this.uploadReceiptImage({
        file: event.target.files[0],
      })
    },
  },
}
</script>

<template>
  <div :class="$style.receipt">
    <!-- Receipt header -->
    <div v-if="receipt" :class="$style.receiptHeader">
      <div v-if="image.content" :class="$style.pusher">
        <button
          v-if="isIE"
          class="icon-download"
          @click="() => window.navigator.msSaveOrOpenBlob(image.content)"
        >
          <v-icon :class="$style.receiptHeaderIcon">mdi-cloud-download</v-icon>
        </button>
        <v-btn
          v-else
          class="naked-btn smallest semi-white"
          text
          :href="image.content"
          :download="image.name"
          :class="!image.content && $style.disabled"
        >
          {{ $t('DOWNLOAD_RECEIPT') }}
        </v-btn>
      </div>
      <div v-if="!isReadOnly && !isReadOnlyUser">
        <v-btn
          class="naked-btn smallest semi-white"
          text
          @click="() => $refs.file.click()"
        >
          {{ $t('UPLOAD_RECEIPT') }}
        </v-btn>
        <input
          ref="file"
          type="file"
          style="display: none"
          @change="(e) => handleUpload(e)"
        />
      </div>
    </div>

    <!-- Is image loading ? -->
    <Loader v-if="image.loading" />

    <!-- PDF viewer -->
    <div v-else-if="image.type === 'application/pdf'" :class="$style.pdf">
      <pdf v-for="i in pdfPageCount" :key="i" :page="i" :src="image.content" />
    </div>

    <!-- Image viewer -->
    <div v-else :key="receipt.id" :class="$style.photo">
      <zoom-on-hover
        v-if="image.content"
        :img-normal="image.content"
        :scale="2"
        :img-zoom="image.content"
      ></zoom-on-hover>

      <!-- Expense is missing a receipt -->
      <div v-if="isReadOnly && !receipt.isLoadingImage && !image.content">
        <h3 :class="$style.noReceiptHeading">
          {{ $t('NO_RECEIPT_UPLOADED') }}
        </h3>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.receipt {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $dark;
  box-shadow: 7px 0 0 5px $dark;
  transition: 4.5s cubic-bezier(0.43, 0.49, 0.43, 1.14) 0s;
}

.pdf {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - 130px);
  margin: 35px;
  overflow-y: auto;
  span {
    width: 100%;
  }
  :global(.vue-pan-zoom-item) {
    width: 100%;
  }
}

.pusher {
  margin: 0 15px;
}

.photo {
  position: relative;
  height: calc(100% - 130px);
  margin: 35px;
  overflow-y: auto;

  :global(.zoom-on-hover) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.26);
      object-fit: contain;
    }
  }
}

.receiptHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  a {
    padding: 0;
  }
}

.receiptHeaderIcon {
  width: 56px;
  height: 44px;
  color: #fff !important;
  background: #293850;
  border-radius: 2px;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    background: #2c3c55;
  }
  &:active {
    background: $dark;
  }

  &:first-of-type {
    margin-right: 1px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.noReceiptHeading {
  color: #fff;
}
</style>
