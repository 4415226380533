import request from '@utils/apiRequest'
import { cloneDeep } from 'lodash'
import eventBus from '@src/event-bus'
import i18n from '@/src/i18n'

export const state = {
  tabSelected: 1, // 1 = Active reclaims, 2 = Account statements
  reclaims: null,
  invoices: null,
  currentInvoice: null,
  refunds: null,
  order: 'desc',
  reclaimsSort: 'date_created',
  invoicesSort: 'date_created',
  getReclaimsInProgress: false,
  getInvoicesInProgress: false,
  getRefundsInProgress: false,
  getPdfInProgress: false,
  invoicePdf: {
    name: '',
    url: '',
  },
  reclaimsPagination: {
    page: 1,
    pageSize: 10,
    total: null,
  },
  invoicesPagination: {
    page: 1,
    pageSize: 10,
    total: null,
  },
}

export const getters = {}

export const mutations = {
  SET_TAB_SELECTED(state, tabNumber) {
    state.tabSelected = tabNumber
  },
  GET_RECLAIMS_DATA(state, response) {
    state.reclaims = response.data.content
    state.reclaimsPagination = {
      page: response.data.page,
      pageSize: response.data.page_size,
      total: response.data.total_results,
    }
  },
  GET_RECLAIMS_DATA_IN_PROGRESS(state, bool) {
    state.getReclaimsInProgress = bool
  },
  GET_INVOICES_DATA(state, response) {
    const remappedInvoices = response.data.content.map((invoice) => {
      return {
        id: invoice.id,
        date: invoice.date,
        dateCreated: invoice.date_created,
        documentContent: invoice.document_content,
        documentFileName: invoice.document_file_name,
        documentMimeType: invoice.document_mine_type, // Note the typo
        enterpriseId: invoice.enterprise_id,
      }
    })
    state.invoices = remappedInvoices
    state.invoicesPagination = {
      page: response.data.page,
      pageSize: response.data.page_size,
      total: response.data.total_results,
    }
  },
  GET_INVOICES_IN_PROGRESS(state, bool) {
    state.getInvoicesInProgress = bool
  },
  SET_CURRENT_INVOICE(state, invoice) {
    state.currentInvoice = invoice
  },
  GET_REFUNDS(state, response) {
    const remappedRefunds = response.data.refunds.map((refund) => {
      return {
        id: refund.id,
        amount: refund.amount,
        currency: refund.currency,
        reclaim: cloneDeep(refund.reclaim),
        relatedRefunds: cloneDeep(refund.related_refunds),
        status: refund.status,
      }
    })
    state.refunds = remappedRefunds
  },
  GET_REFUNDS_IN_PROGRESS(state, bool) {
    state.getRefundsInProgress = bool
  },
  SET_RECLAIMS_PAGE(state, page) {
    const pagination = { ...state.reclaimsPagination, page }
    state.reclaimsPagination = pagination
  },
  SET_INVOICES_PAGE(state, page) {
    const pagination = { ...state.invoicesPagination, page }
    state.invoicesPagination = pagination
  },
  SET_RECLAIMS_SORT(state, sort) {
    state.reclaimsSort = sort
  },
  GET_PDF_IN_PROGRESS(state, bool) {
    state.getPdfInProgress = bool
  },
  SET_PDF_DOCUMENT(state, pdf) {
    state.invoicePdf = pdf
  },
}

export const actions = {
  getReclaims({ commit, rootState }) {
    commit('GET_RECLAIMS_DATA_IN_PROGRESS', true)

    return request
      .get(`reclaims`, {
        params: {
          enterprise_id: rootState.enterprise.currentEnterprise.id,
          order: state.reclaimsSort === 'country' ? 'asc' : state.order,
          page: state.reclaimsPagination.page,
          page_size: state.reclaimsPagination.pageSize,
          sort: state.reclaimsSort,
          year: rootState.expenseOverview.year,
        },
      })
      .then((response) => {
        commit('GET_RECLAIMS_DATA', response)
        commit('GET_RECLAIMS_DATA_IN_PROGRESS', false)
      })
      .catch((error) => {
        commit('GET_RECLAIMS_DATA_IN_PROGRESS', false)

        console.log(error)
        const { data } = error && error.response
        eventBus.$emit('growl', {
          description: `${i18n.t('ERROR_FETCHING_RECLAIMS')}: ${
            data.user_message || 'N/A'
          }`,
          type: 'error',
        })
      })
  },

  getInvoices({ commit, rootState }) {
    commit('GET_INVOICES_IN_PROGRESS', true)

    return request
      .get(`invoices`, {
        params: {
          enterprise_id: rootState.enterprise.currentEnterprise.id,
          order: state.order,
          page: state.invoicesPagination.page,
          page_size: state.invoicesPagination.pageSize,
          sort: state.invoicesSort,
        },
      })
      .then((response) => {
        commit('GET_INVOICES_DATA', response)
      })
      .catch((error) => {
        console.log(error)
        const { data } = error && error.response
        eventBus.$emit('growl', {
          description: `${i18n.t('ERROR_FETCHING_INVOICES')}: ${
            data.user_message || 'N/A'
          }`,
          type: 'error',
        })
      })
      .finally(() => {
        commit('GET_INVOICES_IN_PROGRESS', false)
      })
  },

  getInvoiceById({ commit }, invoiceId) {
    commit('GET_INVOICES_IN_PROGRESS', true)

    return request
      .get(`invoices/${invoiceId}`)
      .then((response) => {
        const invoice = response.data
        const remappedInvoice = {
          id: invoice.id,
          date: invoice.date,
          dateCreated: invoice.date_created,
          documentContent: invoice.document_content,
          documentFileName: invoice.document_file_name,
          documentMimeType: invoice.document_mine_type, // Note the typo
          enterpriseId: invoice.enterprise_id,
        }
        commit('SET_CURRENT_INVOICE', remappedInvoice)
      })
      .catch((error) => {
        console.log(error)
        const { data } = error && error.response
        eventBus.$emit('growl', {
          description: `${i18n.t('ERROR_FETCHING_INVOICE')}: ${
            data.user_message || 'N/A'
          }`,
          type: 'error',
        })
      })
      .finally(() => {
        commit('GET_INVOICES_IN_PROGRESS', false)
      })
  },

  getRefunds({ commit, rootState }, invoiceId) {
    commit('GET_REFUNDS_IN_PROGRESS', true)

    return request
      .get(`invoices/${invoiceId}/refunds`, {
        params: {
          enterprise_id: rootState.enterprise.currentEnterprise.id,
        },
      })
      .then((response) => {
        commit('GET_REFUNDS', response)
      })
      .catch((error) => {
        console.log(error)
        const { data } = error && error.response
        eventBus.$emit('growl', {
          description: `${i18n.t('ERROR_FETCHING_REFUNDS')}: ${
            data.user_message || 'N/A'
          }`,
          type: 'error',
        })
      })
      .finally(() => {
        commit('GET_REFUNDS_IN_PROGRESS', false)
      })
  },

  // View/save invoice PDF
  getInvoicePdf(
    { commit, rootState },
    { invoiceId, pdfName, isDownload = false }
  ) {
    commit('GET_PDF_IN_PROGRESS', true)
    return request
      .get(
        `invoices/${invoiceId}/file?enterprise_id=${rootState.enterprise.currentEnterprise.id}`,
        {
          responseType: 'blob',
        }
      )
      .then((response) => {
        const objectURL = URL.createObjectURL(response.data)
        if (isDownload) {
          const link = document.createElement('a')
          link.href = objectURL
          link.download = pdfName
          link.click()
        } else {
          commit('SET_PDF_DOCUMENT', { url: objectURL, name: pdfName })
        }
      })
      .catch((error) => {
        console.log(error)
        const { data } = error && error.response
        eventBus.$emit('growl', {
          description: `${i18n.t('ERROR_FETCHING_INVOICE_PDF')}: ${
            data.user_message || 'N/A'
          }`,
          type: 'error',
        })
      })
      .finally(() => {
        commit('GET_PDF_IN_PROGRESS', false)
      })
  },
  resetInvoicePdf({ commit }) {
    URL.revokeObjectURL(state.invoicePdf.url)
    commit('SET_PDF_DOCUMENT', { url: '', name: '' })
  },
  setCurrentInvoice({ state, commit }, index) {
    const invoice = cloneDeep(state.invoices[index])
    commit('SET_CURRENT_INVOICE', invoice)
  },
  setReclaimsPage({ commit }, page) {
    commit('SET_RECLAIMS_PAGE', page)
  },
  setInvoicesPage({ commit }, page) {
    commit('SET_INVOICES_PAGE', page)
  },
  setReclaimsSort({ commit }, sort) {
    commit('SET_RECLAIMS_SORT', sort)
  },
  setTabSelected({ commit }, tabNumber) {
    commit('SET_TAB_SELECTED', tabNumber)
  },
}
