import Vue from 'vue'
import Vuetify, {
  VCol,
  VIcon,
  VBtn,
  VSimpleTable,
  VPagination,
  VCheckbox,
  VTooltip,
} from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'

const options = {
  icons: {
    iconfont: 'mdi',
  },
  components: {
    VCol,
    VIcon,
    VBtn,
    VSimpleTable,
    VPagination,
    VCheckbox,
    VTooltip,
  },
}

Vue.use(Vuetify, options)

export default new Vuetify({
  theme: {
    options: {
      cspNonce: 'dQw4w9WgXcQ',
    },
  },
})
