<script>
import appConfig from '@src/app.config'
import Growl from '@components/growl-notifier/growl-notifier'

export default {
  components: { Growl },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === 'function' ? title(this.$store) : title
      return title ? `${title} | ${appConfig.title}` : appConfig.title
    },
  },
}
</script>

<template>
  <v-app>
    <!--
    Even when routes use the same component, treat them
    as distinct and create the component again.
    -->
    <v-content>
      <RouterView :key="$route.fullPath" />
    </v-content>
    <Growl />
  </v-app>
</template>

<!-- This should generally be the only global CSS in the app. -->
<style lang="scss">
// Allow element/type selectors, because this is global CSS.
// stylelint-disable selector-max-type, selector-class-pattern

// Normalize default styles across browsers,
// https://necolas.github.io/normalize.css/
@import '~normalize.css/normalize.css';
// Style loading bar between pages.
// https://github.com/rstacruz/nprogress
@import '~nprogress/nprogress.css';

// Design variables and utilities from src/design.
@import '@design';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  min-width: 1100px;
  font-family: $system-font-family;
  background: $color-body-bg;
}

// ===
// Base element styles
// ===

h1 {
  @include heading-1;
}

h2 {
  @include heading-2;
}

p {
  @include paragraph;
}
</style>
