<script>
import EventBus from '@src/event-bus'
import i18n from '@src/i18n'

export default {
  data: function () {
    return {
      title: '',
      description: '',
      type: 'default',
      isActive: false,
    }
  },
  created: function () {
    EventBus.$on('growl', (growl) => {
      const {
        description,
        type,
        contentText,
        // If no custom button text and callback are passed, default to closing the notifier
        buttonText = i18n.t('GENERAL.CLOSE'),
        buttonCallback = () => {
          this.isActive = false
        },
      } = growl

      this.description = description
      this.type = type
      this.buttonText = buttonText
      this.buttonCallback = buttonCallback
      this.contentText = contentText
      this.isActive = !!(description && type)
    })
  },
}
</script>
<template>
  <v-snackbar
    v-if="isActive"
    v-model="isActive"
    top
    :timeout="5000"
    :type="type"
    :class="`${$style.growl} ${type}`"
    width="100%"
  >
    <div>
      <p>{{ description }}</p>
      <p v-if="contentText" :class="$style.subtitle">{{ contentText }} </p>
    </div>

    <v-btn
      dark
      text
      class="btn naked-btn smallest semi-white"
      @click="buttonCallback"
      >{{ buttonText }}</v-btn
    >
  </v-snackbar>
</template>

<style lang="scss" module>
@import '@design';
.growl {
  p {
    padding: 0.266rem 0.8rem 0.266rem 0.8rem;
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: #fff;
  }

  .subtitle {
    width: 350px;
    padding: 0 0.8rem 0.533rem 0.8rem;
    font-size: 0.9rem;
    font-weight: initial;
  }
}
</style>
