import request from '@utils/apiRequest'
import store from '@state/store'

export const state = {
  cache: {
    queries: {},
  },
  byName: {},
}

export const getters = {
  getMerchantLogo: (state) => (name) => {
    return state.byName[name] && state.byName[name].url
  },
}

export const mutations = {
  ADD_MERCHANT_LOGO(state, { queryId, query, response, success }) {
    const queryDetails = {
      createdAt: Date.now(),
      id: queryId,
      query,
      success,
      response,
    }
    state.cache.queries[queryId] = queryDetails
    if (success) {
      const name = query.merchantName
      state.byName[name] = { url: response }
    }
  },
}

export const actions = {
  async fetchMerchantLogo(
    { commit, rootState, state },
    { query, useCache = true }
  ) {
    const queryId = `ITEM_${JSON.stringify(query)}`
    const { merchantName } = query

    // Check if we have cached this
    const cacheHit = useCache && state.cache.queries[queryId]
    if (!cacheHit || !cacheHit.success) {
      console.log('query', query)
      // Call the API
      const response = await request.put(`api/posname/${merchantName}`, {
        baseUrl: 'logo',
        skipAuthentication: true,
        customHeaders: {
          'Solution-Id': store.getters['general/get_logo_api_solution_id'],
        },
      })

      // Handle the response
      const logoUrl = response.data.logoUrl
      // The status can be 200 but the logo is missing
      if (logoUrl) {
        commit('ADD_MERCHANT_LOGO', {
          queryId,
          query,
          response: logoUrl.location,
          success: response.statusOK,
        })
      }
    }
    return { queryId }
  },
}
