import Vue from 'vue'
import VueI18n from 'vue-i18n'
import I18number from '@/src/plugins/i18nNumber'
import { getSavedStateSession } from '@utils/storage'
const locale = getSavedStateSession('selectedLanguage') || 'en'
Vue.use(VueI18n)
Vue.use(I18number)

export default new VueI18n({
  locale: locale,
  fallbackLocale: locale,
  messages: {
    da: require('@localisation/da.json'),
    en: require('@localisation/en.json'),
    fi: require('@localisation/fi.json'),
    no: require('@localisation/no.json'),
    sv: require('@localisation/sv.json'),
    de: require('@localisation/de.json'),
  },
})
