<script>
import { expenseModalComputed } from '@state/helpers/expense-modal'
import EventBus from '@src/event-bus'
import ExpenseDetailsHeader from '@components/expense-details-header/expense-details-header'
import ExpenseReceiptUpload from '@components/expense-receipt-upload/expense-receipt-upload'
import ExpenseEditor from '@components/expense-editor/expense-editor'
import ExpenseHistory from '@components/expense-history/expense-history'
import ExpenseScacWizard from '@components/expense-scac-questions/expense-scac-questions'

import { pick, isNumber } from 'lodash'

export default {
  components: {
    ExpenseDetailsHeader,
    ExpenseReceiptUpload,
    ExpenseEditor,
    ExpenseHistory,
    ExpenseScacWizard,
  },
  data() {
    return {
      currentModalState: 'editor', // 'editor', 'scac-wizard', 'history', 'receipt-upload'
      editorScrollPosition: 0,
      handleModalStateChangeEvent: () => {},
      expenseHasErrors: false, // keep track if required fields are missing
      baseSplitValidation: {
        hasError: false, // keep track if required fields in base split are missing
        shouldValidateOnMount: false, // keep track if base split needs to be validated on mount
      },
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['isNewExpense', 'expenseKey', 'receipt']),
  },
  watch: {
    'receipt.id': {
      immediate: true,
      handler(id) {
        this.currentModalState = id ? 'editor' : 'receipt-upload'
      },
    },
  },
  created() {
    this.handleModalStateChangeEvent = ({
      toState,
      event,
      callback,
      scrollTop,
    }) => {
      this.currentModalState = toState

      if (isNumber(scrollTop)) {
        this.editorScrollPosition = scrollTop
      }

      // Emit an event that the expense modal state changed
      EventBus.$emit(`expense-modal-state-changed-to-${toState}`)
      // If caller provided a callback, we call it once next time we see it
      if (typeof event === 'string' && typeof callback === 'function') {
        EventBus.$once(event, callback)
      }
    }
    EventBus.$on('change-expense-modal-state', this.handleModalStateChangeEvent)
    EventBus.$on('setBaseSplitValidation', (data) => {
      this.baseSplitValidation = data
    })
  },
  beforeDestroy() {
    EventBus.$off(
      'change-expense-modal-state',
      this.handleModalStateChangeEvent
    )
  },

  methods: {
    setExpenseHasErrors(bool) {
      this.expenseHasErrors = bool
    },
    resetErrorStates() {
      this.expenseHasErrors = false
      this.baseSplitValidation = {
        hasError: false,
        shouldValidateOnMount: false,
      }
    },
  },
}
</script>

<template>
  <div :key="expenseKey" :class="$style.expenseDetails">
    <ExpenseDetailsHeader
      :current-modal-state="currentModalState"
      :class="$style.expenseHeader"
      @resetErrorStates="resetErrorStates"
    />
    <ExpenseReceiptUpload v-if="currentModalState === 'receipt-upload'" />
    <ExpenseEditor
      v-else-if="currentModalState === 'editor'"
      :scroll-position="editorScrollPosition"
      :should-validate-on-mount="expenseHasErrors"
      :base-split-validation="baseSplitValidation"
      @setExpenseHasErrors="setExpenseHasErrors"
      @resetErrorStates="resetErrorStates"
    />
    <ExpenseScacWizard v-else-if="currentModalState === 'scac-wizard'" />
    <ExpenseHistory v-else-if="currentModalState === 'history'" />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.expenseDetails {
  position: relative;
  display: flex;
  width: 530px;
  min-width: 530px;
  padding: 50px 30px;
  overflow: hidden !important;
  overflow-x: hidden;
  background-color: $light-grey;
  border-radius: 7px;
}

.expenseHeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3500;
  width: 100%;
  height: 57px;
  background: $light-grey;
  box-shadow: 0 -20px 20px 4px #000;
}
</style>
