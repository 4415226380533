var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.$style.splitItem,
    _vm.isAdding && _vm.$style.isAdding,
    _vm.isDeleting && _vm.$style.isDeleting,
    // The next two classes are used to style descendents like labels and placeholders
    'split-item',
    _vm.expenseHasSplits && 'in-split-state',
  ]},[_c('div',{class:[_vm.$style.splitItemInner]},[_c('div',{class:[_vm.$style.splitHeader, !_vm.expenseHasSplits && _vm.$style.hidden]},[_c('h2',[_vm._v(_vm._s(`${_vm.$t('EXPENSE_SPLIT')} ${_vm.isBaseSplit ? 1 : _vm.splitIndex + 2}`))]),(!_vm.disabled && !_vm.isReadOnly && _vm.isNormalSplit)?_c('v-btn',{staticClass:"minimal-btn remove-split center",attrs:{"text":"","small":""},on:{"click":_vm.removeThisSplit}},[_vm._v(_vm._s(_vm.$t('REMOVE')))]):_vm._e()],1),_c('v-form',{ref:"splitForm",model:{value:(_vm.splitFormIsValid),callback:function ($$v) {_vm.splitFormIsValid=$$v},expression:"splitFormIsValid"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","cols":"12"}},[_c('ExpenseCategoryDropdown',{attrs:{"disabled":_vm.disabled,"split-index":_vm.splitIndex}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('ExpenseVatRateInput',{attrs:{"disabled":_vm.disabled,"split-index":_vm.splitIndex}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('ExpenseScacCodeInput',{attrs:{"disabled":_vm.disabled,"split-index":_vm.splitIndex,"should-validate-on-mount":_vm.isBaseSplit || _vm.isInitialSplit}})],1)],1),_c('ExpenseScacInfo',{attrs:{"split-index":_vm.splitIndex}}),_c('ExpenseScacDescriptionInput',{attrs:{"disabled":_vm.disabled,"split-index":_vm.splitIndex}}),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('ExpenseAmountInclVatInput',{attrs:{"disabled":_vm.disabled,"split-index":_vm.splitIndex}})],1),_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('ExpenseVatAmountInput',{attrs:{"split-index":_vm.splitIndex}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"6"}},[_c('ExpenseVatRefundInput',{attrs:{"disabled":_vm.disabled,"split-index":_vm.splitIndex}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }