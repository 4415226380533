import request from '@utils/apiRequest'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'
import { calculateVatValues, isValidNumber } from '@utils/vattax-math'
import { isNumber, isEmpty, omit, cloneDeep, range, mapKeys } from 'lodash'
import { isAllowedReceiptMimeType } from '@utils/fileTypeValidator'
import Router from '@src/router/index'
import {
  compareSplits,
  formatLocalSplitToApiSplit,
  formatApiSplitToLocalSplit,
  formatApiSplitForPatch,
} from '@state/utils/expense-splits'
import { removeSavedStateSession } from '@utils/storage'
/**
 * This feature module is used by the expense details modal
 */

const initialState = {
  id: null,
  enterpriseId: null,
  type: 'TRANSACTION', //  "VATTAX_LOCAL" (created in VATTAX) | "MANUAL" | "TRANSACTION" (default)

  // Amounts
  originalAmountExclVat: null,
  originalVatAmount: null,
  amountExclVat: null,
  amountInclVat: null,
  vatAmount: null,

  // Where and when
  country: null,
  currency: null,
  date: null,

  // Invoice and receipt
  invoiceNumber: null,
  shouldShowInvoiceNumber: false,
  receipt: {
    id: null,
    isLoadingImage: false,
  },

  // Merchant
  merchant: {
    city: null,
    country: null,
    name: null,
    number: null,
    numberType: null,
    source: null,
    street: null,
    streetNo: null,
    zipCode: null,
    logoUrl: null,
    isLoading: false,
  },

  // Category for first split or whole expense
  category: {
    code: null, // 7
    key: null, // "FOOD"
    name: null, // "Food, drink and restaurant services"
  },
  // In some circumstances we save the category temporaroy in this property
  // until we know for sure
  categoryTemporary: null,

  // vatPercent is the actual percent in range [0 - 1]. It comes from the vatRate the user choose from the vatRate dropdown (or typed), or is
  vatPercent: null,

  // vatRefund describes how many percent of vatAmount is refundable. The value comes from SCAC engine
  vatRefund: null,

  scacWizardCurrentSplitIndex: null,

  // The current scac for first split or whole expense
  scac: {
    major: null,
    minor: null,
    patch: null,
  },
  scacInformation: null,
  scacDescription: null,
  // scacType can be one of CODE | NO_REFUND | MANUAL | NONE
  // - CODE = success with a valid SCAC code
  // - NO_REFUND = no refund, no SCAC code
  // - MANUAL = user must do something manually, i.e., expenses must be split to
  //   remove tip from the total amount
  // - NONE = internal node in the question tree, i.e., there are more questions
  //   to answer
  scacType: null,
  // The cardholder has in some cases answered a SCAC question with text
  scacTextReply: null,
  history: [],
  historyError: false, // if loading history fails, we set this to true

  status: null,

  isInitializing: false,
  isShowingExpenseModal: false,
  hasChanges: false,

  maximumReclaimAmount: null,

  // Expense splits
  splits: [],
}

export const state = cloneDeep(initialState)

export const getters = {
  getCountry(state) {
    return state.country
  },
  getVatRate: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex)
      ? vatPercentToVatRate(splits[splitIndex].vatPercent)
      : vatPercentToVatRate(state.vatPercent)
  },
  getCategory: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex) ? splits[splitIndex].category : state.category
  },
  getCategoryTemporary: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex)
      ? splits[splitIndex].categoryTemporary
      : state.categoryTemporary
  },
  getAmountInclVat: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex)
      ? splits[splitIndex].amountInclVat
      : state.amountInclVat
  },
  getVatAmount: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex) ? splits[splitIndex].vatAmount : state.vatAmount
  },
  getVatRefund: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex) ? splits[splitIndex].vatRefund : state.vatRefund
  },
  getScac: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex) ? splits[splitIndex].scac : state.scac
  },
  getScacInfo: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex)
      ? splits[splitIndex].scacInformation
      : state.scacInformation
  },
  getScacDescription: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex)
      ? splits[splitIndex].scacDescription
      : state.scacDescription
  },
  getScacTextReply: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex)
      ? splits[splitIndex].scacTextReply
      : state.scacTextReply
  },
  expenseHasChanges(state) {
    return state.hasChanges
  },
  getCurrency(state) {
    return state.currency
  },
  getShouldShowInvoiceNumber(state) {
    return state.shouldShowInvoiceNumber
  },
  editedExpenseRequestBody(state) {
    return generatePatchRequestBody({
      expense: state,
    })
  },
  originalPointOfSale(state, getters, rootState, rootGetters) {
    const originalExpense = rootGetters['expenses/item'](state.id)
    if (!originalExpense) {
      return null
    }
    const {
      amount_incl_vat: amount,
      currency,
      merchant,
      date,
      country,
    } = originalExpense

    return {
      amountInclVat:
        (isValidNumber(amount) && amount.toFixed(2)) ||
        (typeof amount === 'string' && Number(amount).toFixed(2)) ||
        null,
      currency,
      merchantName: merchant && merchant.name,
      date,
      country,
    }
  },
  originalExpenseRequestBody(state, getters, rootState, rootGetters) {
    const originalExpense = rootGetters['expenses/item'](state.id)
    return originalExpense
      ? generatePatchRequestBody({
          expense: originalExpense,
          isOriginal: true,
        })
      : null
  },
  originalExpenseReceiptId(state, getters, rootState, rootGetters) {
    const originalExpense = rootGetters['expenses/item'](state.id)
    return originalExpense ? originalExpense.receipt_id : null
  },
  receiptId(state) {
    return state.receipt.id
  },
  getLocalSplits(state) {
    const splits = cloneDeep(state.splits) // Force detection
    return splits
  },
  getApiSplits(state, getters, rootState, rootGetters) {
    const originalExpense = rootGetters['expenses/item'](state.id) || {}
    return (originalExpense.expense_splits || []).map(formatApiSplitForPatch)
  },

  getScacType: (state) => (splitIndex) => {
    const splits = cloneDeep(state.splits) // Force detection
    return isNumber(splitIndex) ? splits[splitIndex].scacType : state.scacType
  },
  getIsCurrencyValid(state, getters, rootState, rootGetters) {
    return (
      state.currency === rootGetters['countries/getCurrency'](state.country)
    )
  },
  getHasScacTypeManual(state) {
    return (
      state.scacType === 'MANUAL' ||
      state.splits.some((split) => {
        return split.scacType === 'MANUAL'
      })
    )
  },
}

export const mutations = {
  SET_IS_INITIALIZING(state, bool) {
    state.isInitializing = bool
  },
  SET_EXPENSE(state, expense) {
    state.id = expense.id
    state.enterpriseId = expense.enterprise_id
    state.type = expense.expense_type || state.type
    state.originalAmountExclVat = expense.original_amount_ex_vat
    state.originalVatAmount = expense.original_vat_amount
    state.amountExclVat = expense.amount_ex_vat
    state.amountInclVat = expense.amount_incl_vat
    state.vatAmount = expense.vat_amount
    state.vatRefund = isNumber(expense.scac.vat_refund)
      ? expense.scac.vat_refund
      : 100

    state.country = expense.country
    state.currency = expense.currency
    state.date = expense.date

    state.invoiceNumber = expense.invoice_number
    state.receipt.id = expense.receipt_id

    state.merchant = {
      ...state.merchant,
      city: expense.merchant.city,
      country: expense.merchant.country,
      name: expense.merchant.name,
      number: expense.merchant.number,
      numberType: expense.merchant.number_type,
      source: expense.merchant.source,
      street: expense.merchant.street,
      streetNo: expense.merchant.street_no,
      zipCode: expense.merchant.zip_code,
    }

    state.scac = {
      major: expense.scac.major,
      minor: expense.scac.minor,
      patch: expense.scac.patch,
    }
    state.scacInformation = expense.scac.information
    state.scacDescription = expense.scac.scac_description
    state.scacTextReply = expense.scac.text_reply
    state.scacType = expense.scac.scac_type
    state.maximumReclaimAmount = expense.maximum_reclaim_amount

    state.category = {
      key: expense.scac.top_level_category,
    }

    state.status = expense.status

    state.splits = []
    expense.expense_splits.forEach((apiSplit) => {
      state.splits.push(formatApiSplitToLocalSplit(apiSplit))
    })
  },
  ADD_SPLIT(state) {
    // New splits are appended to the end of the array
    state.splits.push({ ...formatApiSplitToLocalSplit(), addedAt: Date.now() })
  },
  REMOVE_SPLIT(state, index) {
    state.splits.splice(index, 1)
  },
  SET_EXPENSE_ID(state, id) {
    state.id = id
  },
  SET_EXPENSE_TYPE(state, type) {
    state.type = type
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  RESET_EXPENSE(state) {
    Object.entries(initialState).forEach(([key, value]) => {
      state[key] = cloneDeep(value)
    })
  },
  SET_CATEGORY(state, { category, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].category = category
    } else {
      state.category = category
    }
  },
  SET_CATEGORY_TEMPORARY(state, { category, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].categoryTemporary = category
    } else {
      state.categoryTemporary = category
    }
  },
  SET_SCAC_WIZARD_CURRENT_SPLIT_INDEX(state, index) {
    state.scacWizardCurrentSplitIndex = index
  },
  SET_EXPENSE_SCAC(state, { scac, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].scac = scac
    } else {
      state.scac = scac
    }
  },
  SET_SCAC_TYPE(state, { scacType, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].scacType = scacType
    } else {
      state.scacType = scacType
    }
  },
  SET_MAXIMUM_RECLAIM_AMOUNT(state, { maximumReclaimAmount, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].maximumReclaimAmount = maximumReclaimAmount
    } else {
      state.maximumReclaimAmount = maximumReclaimAmount
    }
  },
  SET_SCAC_INFORMATION(state, { information, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].scacInformation = information
    } else {
      state.scacInformation = information
    }
  },
  SET_SCAC_DESCRIPTION(state, { description, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].scacDescription = description
    } else {
      state.scacDescription = description
    }
  },
  SET_IS_SHOWING_EXPENSE_MODAL(state, bool) {
    state.isShowingExpenseModal = bool
  },
  SET_MERCHANT_FIELD(state, { key, value }) {
    state.merchant[key] = value
  },
  SET_MERCHANT_COUNTRY(state, value) {
    state.merchant.country = value
  },
  SET_MERCHANT_LOGO(state, logoUrl) {
    state.merchant.logoUrl = logoUrl
  },
  SET_IS_LOADING_MECHANT_INFO(state, bool) {
    state.merchant.isLoading = bool
  },
  SET_EXPENSE_COUNTRY(state, country) {
    state.country = country
  },
  SET_INVOICE_NUMBER(state, invoiceNumber) {
    state.invoiceNumber = invoiceNumber
  },
  SET_SHOULD_SHOW_INVOICE_NUMBER(state, bool) {
    state.shouldShowInvoiceNumber = bool
  },
  SET_DATE(state, date) {
    state.date = date
  },
  SET_AMOUNT_EXCL_VAT(state, { value, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].amountExclVat = value
    } else {
      state.amountExclVat = value
    }
  },
  SET_AMOUNT_INCL_VAT(state, { value, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].amountInclVat = value
    } else {
      state.amountInclVat = value
    }
  },
  SET_VAT_AMOUNT(state, { value, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].vatAmount = value
    } else {
      state.vatAmount = value
    }
  },
  SET_VAT_REFUND(state, { value, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].vatRefund = value
    } else {
      state.vatRefund = value
    }
  },
  SET_VAT_PERCENT(state, { value, splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].vatPercent = value
    } else {
      state.vatPercent = value
    }
  },
  SET_ENTERPRISE_ID(state, enterpriseId) {
    state.enterpriseId = enterpriseId
  },
  SET_CURRENCY(state, currency) {
    state.currency = currency
  },
  SET_IS_LOADING_RECEIPT_IMAGE(state, bool) {
    state.receipt.isLoadingImage = bool
  },
  SET_RECEIPT_IMAGE_ID(state, id) {
    state.receipt.id = id
  },
  SET_HISTORY(state, history) {
    state.history = history
  },
  SET_HISTORY_ERROR(state, bool) {
    state.historyError = bool
  },
  SET_EXPENSE_HAS_CHANGES(state, bool) {
    state.hasChanges = bool
  },
  REMOVE_SCAC_TEXT_REPLY(state, { splitIndex }) {
    if (isNumber(splitIndex)) {
      state.splits[splitIndex].scacTextReply = null
    } else {
      state.scacTextReply = null
    }
  },
  SET_MANUAL_SCAC_TYPE_TO_CODE(state) {
    // Base split
    if (state.scacType === 'MANUAL') state.scacType = 'CODE'
    // Splits
    const newSplits = cloneDeep(state.splits)
    newSplits.forEach((split) => {
      if (split.scacType === 'MANUAL') split.scacType = 'CODE'
    })
    state.splits = newSplits
  },
}

export const actions = {
  async initializeExpense(
    { state, commit, dispatch, rootGetters, rootState },
    { id, country } = {}
  ) {
    // Edit
    if (id) {
      commit('SET_IS_INITIALIZING', true)

      // Setup the state
      await dispatch(
        'expenses/FETCH_ITEM',
        { query: { id }, useCache: false },
        { root: true }
      )

      const expense = rootGetters['expenses/item'](id)
      commit('SET_EXPENSE', expense)

      // We create an array of splitIndices. We prepend a null, because we want
      // to use the same loop for the base
      const splitIndices = [
        null, // base has no splitIndex
        ...range(state.splits.length), // the indices for the actal splits
      ] // [null, 0, 1, 3, ...]

      splitIndices.forEach((splitIndex) => {
        // Set category for base and splits
        const category = rootGetters['expenseModal/getCategory'](splitIndex)
        const categoryEnriched =
          rootGetters['categories/getCategory']({
            key: category.key,
          }) || {}
        if (categoryEnriched) {
          commit('SET_CATEGORY', { category: categoryEnriched, splitIndex })
        }

        // Recalculate VAT and Amount data for base and splits
        dispatch('recalculateVatAndAmountData', {
          exclude: ['vatPercent', 'amountExclVat'],
          splitIndex,
        })
      })

      commit('SET_IS_INITIALIZING', false)

      if (state.type === 'TRANSACTION' && state.merchant.name) {
        dispatch('updateMerchantLogo', { merchantName: state.merchant.name })
      }

      if (state.receipt.id) {
        dispatch('fetchReceiptImage')
      }
    }
    // Add
    else {
      commit('SET_EXPENSE_TYPE', 'VATTAX_LOCAL')
      commit('SET_ENTERPRISE_ID', rootState.enterprise.currentEnterprise.id)
      if (country) {
        commit('SET_EXPENSE_COUNTRY', country)
        commit('SET_MERCHANT_FIELD', { key: 'country', value: country })
        commit('SET_CURRENCY', rootGetters['countries/getCurrency'](country))
        commit('SET_MERCHANT_FIELD', { key: 'numberType', value: 'VAT_NUMBER' })
      }
    }

    commit('SET_IS_SHOWING_EXPENSE_MODAL', true)
  },

  // Called when changing expense status from expense details modal.
  async changeExpenseStatus({ state, commit, dispatch }, { id, status }) {
    const expenseUrl = `/expenses/${state.country}/${id}`
    try {
      await dispatch(
        'expensesByCountry/moveExpenses',
        { status, expenseIds: [id] },
        { root: true }
      )

      commit('SET_STATUS', status)

      eventBus.$emit('growl', {
        title: 'Success',
        description: i18n.t('MOVE_EXPENSE_SUCCESS', {
          status: i18n.t(status),
        }),
        type: 'success',
        buttonText: i18n.t('GO_TO_EXPENSE'),
        buttonCallback: () => {
          Router.push(expenseUrl)
        },
        contentText: i18n.t('MOVE_EXPENSE_SUCCESS_MESSAGE', {
          status: i18n.t(status),
        }),
      })
    } catch (error) {
      eventBus.$emit('growl', {
        title: 'Error',
        description: i18n.t('MOVE_EXPENSE_ERROR', {
          status: i18n.t(status),
        }),
        type: 'error',
      })
      console.log(error)
    }
  },

  async closeExpense({ commit }, { isNewExpense, isReadOnly = false }) {
    commit('SET_IS_SHOWING_EXPENSE_MODAL', false)
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
    await delay(350) // delay to give the closing animation time to finish before resetting the data

    commit('RESET_EXPENSE')
    removeSavedStateSession('expenseId')
    removeSavedStateSession('expense')

    if (!isNewExpense && !isReadOnly) {
      Router.push({ name: 'expenses by country' })
    }
    if (isReadOnly) {
      Router.push({ name: 'reclaim by id' })
    }
  },

  addSplit({ commit }) {
    commit('ADD_SPLIT')
  },

  removeSplit({ commit }, splitIndex) {
    commit('REMOVE_SPLIT', splitIndex)
  },

  setScacWizardSplitIndex({ commit }, splitIndex) {
    commit('SET_SCAC_WIZARD_CURRENT_SPLIT_INDEX', splitIndex)
  },

  updateMerchantField({ commit }, data) {
    commit('SET_MERCHANT_FIELD', data)
  },

  updateMerchantCountry({ commit }, country) {
    commit('SET_MERCHANT_FIELD', {
      key: 'numberType',
      value: country ? 'VAT_NUMBER' : null,
    })

    commit('SET_MERCHANT_COUNTRY', country)
  },

  updateAmountInclVat({ commit, dispatch }, { value, splitIndex }) {
    commit('SET_AMOUNT_INCL_VAT', { value, splitIndex })

    // When amountInclVat changes, we need to recalculate
    dispatch('recalculateVatAndAmountData', {
      exclude: ['amountExclVat', 'vatAmount'],
      splitIndex,
    })
  },

  updateVatRate({ commit, dispatch }, { vatRate, splitIndex }) {
    const vatPercent = isNumber(vatRate) ? vatRate * 0.01 : null
    commit('SET_VAT_PERCENT', { value: vatPercent, splitIndex })

    // When vatPercent changes, we need to recalculate
    dispatch('recalculateVatAndAmountData', {
      exclude: ['amountExclVat', 'vatAmount'],
      splitIndex,
    })
  },
  updateVatRefund({ commit }, { vatRefund, splitIndex }) {
    commit('SET_VAT_REFUND', { value: vatRefund, splitIndex })
  },
  updateInvoiceNumber({ commit }, invoiceNumber) {
    commit('SET_INVOICE_NUMBER', invoiceNumber)
  },
  updateDate({ commit }, date) {
    commit('SET_DATE', date)
  },
  updateCountry({ commit, dispatch }, country) {
    commit('SET_EXPENSE_COUNTRY', country)
    dispatch('updateCurrency', { country })
  },
  updateCurrency({ commit, rootGetters }, { country }) {
    commit('SET_CURRENCY', rootGetters['countries/getCurrency'](country))
  },
  updateCategory(
    { state, commit, getters },
    { category, updateIsTemporary, splitIndex }
  ) {
    if (updateIsTemporary) {
      commit('SET_CATEGORY_TEMPORARY', { category, splitIndex })
    } else {
      // When we change category of each split, we want to remove scac text reply and scac
      // information for previous category for that split
      if (getters['getCategory'](splitIndex).code !== category.code) {
        commit('SET_SCAC_INFORMATION', { information: null, splitIndex })
        commit('REMOVE_SCAC_TEXT_REPLY', { splitIndex })
      }

      commit('SET_CATEGORY', { category, splitIndex })
      commit('SET_CATEGORY_TEMPORARY', { category: null, splitIndex })

      // If scac is incorrect we want to remove it
      const { major } = isNumber(splitIndex)
        ? state.splits[splitIndex].scac
        : state.scac
      if (major && major !== category.code) {
        commit('SET_EXPENSE_SCAC', { scac: {}, splitIndex }) // Removes all scac values
      }
    }
  },

  removeTemporaryCategory({ commit }, { splitIndex }) {
    if (isNumber(splitIndex)) {
      commit('SET_CATEGORY_TEMPORARY', { category: null, splitIndex })
    } else {
      commit('SET_CATEGORY_TEMPORARY', { category: null })
    }
  },

  updateExpenseScac({ commit, rootGetters, dispatch }, { scac, splitIndex }) {
    commit('SET_EXPENSE_SCAC', { scac, splitIndex })

    if (scac.major) {
      const category = rootGetters['categories/getCategory']({
        code: scac.major,
      })
      if (category) {
        dispatch('updateCategory', { category, splitIndex })
      }
    }
  },

  updateScacInfo({ commit }, { information, splitIndex }) {
    commit('SET_SCAC_INFORMATION', { information, splitIndex })
  },

  updateScacDescription({ commit }, { description, splitIndex }) {
    commit('SET_SCAC_DESCRIPTION', { description, splitIndex })
  },

  updateScacType({ commit }, { scacType, splitIndex }) {
    commit('SET_SCAC_TYPE', { scacType, splitIndex })
  },

  updateMaximumReclaimAmount({ commit }, { maximumReclaimAmount, splitIndex }) {
    commit('SET_MAXIMUM_RECLAIM_AMOUNT', { maximumReclaimAmount, splitIndex })
  },

  setExpenseHasChanges({ commit }, bool) {
    commit('SET_EXPENSE_HAS_CHANGES', bool)
  },

  // Save (update) an existing expense
  async saveExpense({ state, dispatch, rootGetters }) {
    // Helper function that shows a growl when save expense goes wrong
    const showSaveExpenseFailureGrowl = () => {
      eventBus.$emit('growl', {
        title: 'Save expense failed',
        description: i18n.t('SAVE_EXPENSE_FAILED'),
        type: 'error',
      })
    }

    const originalExpense = rootGetters['expenses/item'](state.id)

    // If the receipt has changed, we need to update the expense
    if (originalExpense.receipt_id !== state.receipt.id) {
      try {
        await request.patch(`expenses/${state.id}/receipt`, {
          body: {
            expense_id: state.id,
            receipt_id: state.receipt.id,
          },
        })
      } catch (error) {
        showSaveExpenseFailureGrowl()
        return
      }
    }

    // Create an expense object in the shape the API want's it
    const patchRequestBody = generatePatchRequestBody({
      expense: state,
    })

    const { success, data } = await dispatch(
      'expenses/UPDATE_ITEM',
      { query: { id: state.id }, item: patchRequestBody },
      { root: true }
    )
    if (!success) {
      if (
        data &&
        data.user_message &&
        data.user_message_code === 'vattax.expense.scac.version.error'
      ) {
        showScacValidationErrorGrowl(data, state.scac)
      } else {
        showSaveExpenseFailureGrowl()
      }
      return
    }

    // At this point the expense has been updated, and we can now update the
    // splits
    try {
      await makeSplitApiCalls({
        ...compareSplits({
          apiSplits: originalExpense.expense_splits,
          localSplits: state.splits,
        }),
        expenseId: state.id,
      })
    } catch (error) {
      console.log(error)
    }

    // When all split requests are resolved, we update the cached expense
    await dispatch(
      'expenses/FETCH_ITEM',
      { query: { id: state.id }, useCache: false },
      { root: true }
    )
    // TODO: Delete this dispatch. It's are not related to expense-modal and
    // should not be called from here. The components depending on fresh data
    // should pull when they see relevant changes in the state
    dispatch('expensesByCountry/refreshData', {}, { root: true })

    // Everything was successful, let's hide the modal and reset state
    dispatch('closeExpense', { isNewExpense: false })
  },

  // Create a new expense
  async createExpense({ state, commit, dispatch }) {
    // Create an expense object in the shape the API want's it
    const postRequestBody = {
      amount_ex_vat: state.amountExclVat,
      category: state.category.key,
      country: state.country,
      currency: state.currency,
      date: state.date,
      enterprise_id: state.enterpriseId, // TODO:
      invoice_number: state.shouldShowInvoiceNumber
        ? state.invoiceNumber
        : null,
      merchant: {
        city: state.merchant.city || undefined, // optional,
        country: state.merchant.country || undefined, // optional,
        // merchant_id // optional
        merchant_number: state.merchant.number || undefined, // optional,
        // merchant_po_box // optional
        merchant_zip_code: state.merchant.zipCode || undefined, // optional,
        name: state.merchant.name || undefined, // optional,
        street: state.merchant.street || undefined, // optional,
        street_number: state.merchant.streetNo || undefined, // optional,
        vat_number_type: state.merchant.country && state.merchant.numberType,
      },
      receipt_id: state.receipt.id,
      scac_information: state.scacInformation || undefined, // optional
      scac_description: state.scacDescription || undefined, // optional
      scac_major: state.scac.major || undefined, // optional
      scac_minor: state.scac.minor || undefined, // optional
      scac_patch: state.scac.patch || undefined, // optional
      vat_amount: state.vatAmount,
      scac_type: state.scacType,
      vat_refund: state.vatRefund,
      maximum_reclaim_amount: state.maximumReclaimAmount,
    }

    const { id } = await dispatch(
      'expenses/CREATE_ITEM',
      { item: postRequestBody },
      { root: true }
    )
    if (id) {
      commit('SET_EXPENSE_ID', id)

      // At this point the expense has been created, and we can now update the
      // splits
      try {
        await makeSplitApiCalls({
          add: state.splits.map((split) => formatLocalSplitToApiSplit(split)),
          expenseId: id,
        })
      } catch (error) {
        console.log(error)
      }

      // When all split requests are resolved, we update the cached expense
      await dispatch(
        'expenses/FETCH_ITEM',
        { query: { id: state.id }, useCache: false },
        { root: true }
      )

      // TODO: Delete these dispatches. They are not related to expense-modal and
      // should not be called from here. The components depending on fresh data
      // should pull when they see relevant changes in the state
      if (Router.currentRoute.fullPath.indexOf('/expenses') !== -1) {
        dispatch('expensesByCountry/refreshData', {}, { root: true })
      } else if (Router.currentRoute.fullPath === '/overview') {
        dispatch('expenseOverview/refreshData', {}, { root: true })
      }

      dispatch('closeExpense', { isNewExpense: true })
    } else {
      // TODO: Add better error message. The backend tells us what went wrong.
      // But we don't use it yet.
      eventBus.$emit('growl', {
        title: 'Expense creation failed',
        description: i18n.t('EXPENSE_CREATION_FAILED'),
        type: 'error',
      })
    }
  },

  async fetchReceiptImage({ commit, dispatch }) {
    commit('SET_IS_LOADING_RECEIPT_IMAGE', true)
    try {
      // TODO: Figure out why this fn gets called with no id even though we check for that before the call is made
      if (state.receipt.id) {
        await dispatch(
          'receipts/FETCH_ITEM',
          { query: { id: state.receipt.id } },
          { root: true }
        )
      }
    } catch (error) {
      console.error(error) // TODO: add better error handling here
    } finally {
      commit('SET_IS_LOADING_RECEIPT_IMAGE', false)
    }
  },

  async updateMerchantLogo(
    { commit, dispatch, rootGetters },
    { merchantName }
  ) {
    await dispatch(
      'merchantLogo/fetchMerchantLogo',
      { query: { merchantName } },
      { root: true }
    )

    const logoUrl = rootGetters['merchantLogo/getMerchantLogo'](
      state.merchant.name
    )
    commit('SET_MERCHANT_LOGO', logoUrl)
  },

  async updateMerchantInfo({ commit, dispatch, rootGetters }, data = {}) {
    const { lookupOnReceiptUpload } = data

    // if we have a new receipt to scan, we will get this id, otherwise we send
    // empty string for manual lookup
    const photoBucketId = rootGetters['receipts/item'](state.receipt.id)
      ? rootGetters['receipts/item'](state.receipt.id).photoBucketId
      : ''

    try {
      commit('SET_IS_LOADING_MECHANT_INFO', true)
      await dispatch(
        'merchantInfo/fetchMerchantInfo',
        {
          query: {
            vatNumber: state.merchant.number,
            expenseId: state.id,
            country: state.merchant.country,
            receiptId: photoBucketId,
            lookupOnReceiptUpload,
          },
        },
        { root: true }
      )

      // we can either search by recipt id, if we are uploading new image,
      // or by number in manual lookup (on button click)
      const merchantInfo = lookupOnReceiptUpload
        ? rootGetters['merchantInfo/getMerchantInfoByReceiptId'](photoBucketId)
        : rootGetters['merchantInfo/getMerchantInfoByNumber'](
            state.merchant.number
          )
      if (merchantInfo) {
        const isNewExpense = !state.id

        if (lookupOnReceiptUpload && !isNewExpense && !state.merchant.number) {
          // lookup after uploading new receipt in existing expense if no
          // vat number is set
          dispatch('updateMerchantField', {
            key: 'number',
            value: merchantInfo.number,
          })
        } else if (
          // Lookup after uploading a receipt in new expense, or lookup via button click
          (lookupOnReceiptUpload && isNewExpense) ||
          !lookupOnReceiptUpload
        ) {
          mapKeys(merchantInfo, (value, key) => {
            // Known issue that VIES will return '---' or 'N/A' for some fields that are not available
            if (value !== 'N/A' && value !== '---') {
              dispatch('updateMerchantField', { key, value })
            }
          })
        }
      }
    } finally {
      commit('SET_IS_LOADING_MECHANT_INFO', false)
    }
  },

  async uploadReceiptImage({ commit, dispatch, rootState }, { file }) {
    // If we get a file type that we don't support, we want to abort
    if (isAllowedReceiptMimeType(file.type) === false) {
      eventBus.$emit('growl', {
        title: 'No',
        description: i18n.t('FILE_UPLOAD_TYPE_ERROR_MESSAGE'),
        type: 'error',
      })
      return // abort here
    }

    // Helper function that reads the file and returns the content
    const readFile = async (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result.split(';base64,')[1])
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }

    try {
      commit('SET_IS_LOADING_RECEIPT_IMAGE', true)

      // Read the file and update receipt data object
      const data = await readFile(file)

      // Upload (create) the receipt
      const { id } = await dispatch(
        'receipts/CREATE_ITEM',
        {
          enterpriseId: rootState.enterprise.currentEnterprise.id,
          fileName: file.name,
          mimeType: file.type,
          data,
        },
        { root: true }
      )

      // Update the receipt id
      commit('SET_RECEIPT_IMAGE_ID', id)

      if (state.merchant.country && !state.merchant.number) {
        dispatch('updateMerchantInfo', { lookupOnReceiptUpload: true })
      }
    } catch (error) {
      console.error('create receipt failed', error)
      eventBus.$emit('growl', {
        description: i18n.t('FILE_UPLOAD_FAILED_ERROR_MESSAGE'),
        type: 'error',
      })
      return // abort here
    } finally {
      commit('SET_IS_LOADING_RECEIPT_IMAGE', false)
    }
  },

  async fetchExpenseHistory({ state, commit }) {
    // Only fetch if we don't have the history for this expense
    if (isEmpty(state.history) && state.id) {
      try {
        const {
          data: { content },
        } = await request.get(`expenses/${state.id}/history`)
        commit(
          'SET_HISTORY',
          content.map((item) => ({
            action: item.action,
            date: item.activity_time,
          }))
        )
      } catch (error) {
        commit('SET_HISTORY_ERROR', true)
      }
    }
  },

  async fetchMaximumReclaimAmount(_, { questionId, integerAnswer }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await request.get(
          `scac/${questionId}/maximum-reclaimed-amount`,
          {
            params: { reply: integerAnswer },
          }
        )
        resolve(data)
      } catch (error) {
        eventBus.$emit('growl', {
          title: 'Error',
          description: i18n.t('GENERAL_ERROR', {
            status: i18n.t(status),
          }),
          type: 'error',
        })
        reject(error)
      }
    })
  },

  /**
   * This action can be called whenever we need to recalculate the vat and
   * amount data. The action optionally takes an object with an 'exclude'
   * property. It's used to exclude values from the calculations that is known
   * or suspected to be outdated. If it has an optional 'splitIndex' property,
   * that means the results will be saved to that split instead of root state.
   */
  recalculateVatAndAmountData(
    { state, commit },
    { exclude = {}, splitIndex = null }
  ) {
    const currentValues = isNumber(splitIndex)
      ? {
          amountExclVat: state.splits[splitIndex].amountExclVat,
          amountInclVat: state.splits[splitIndex].amountInclVat,
          vatPercent: state.splits[splitIndex].vatPercent,
          vatAmount: state.splits[splitIndex].vatAmount,
        }
      : {
          amountExclVat: state.amountExclVat,
          amountInclVat: state.amountInclVat,
          vatPercent: state.vatPercent,
          vatAmount: state.vatAmount,
        }

    // We filter out the values specified in exclude
    const filteredValues = omit(currentValues, exclude)

    // We can't calculate the vat values if we have less than 2 known variables
    if (Object.values(filteredValues).filter(isValidNumber).length < 2) {
      // If vatPercent is null, we reset vat amount as well
      if (currentValues.vatPercent === null) {
        commit('SET_VAT_AMOUNT', { value: null, splitIndex })
      }
      return
    }

    // Run the calculation and get the updated values
    const { amountExclVat, amountInclVat, vatPercent, vatAmount } =
      calculateVatValues(filteredValues)

    // Only set the values that have changed
    if (currentValues.amountExclVat !== amountExclVat) {
      commit('SET_AMOUNT_EXCL_VAT', { value: amountExclVat, splitIndex })
    }

    if (currentValues.amountInclVat !== amountInclVat) {
      commit('SET_AMOUNT_INCL_VAT', { value: amountInclVat, splitIndex })
    }

    if (currentValues.vatPercent !== vatPercent) {
      commit('SET_VAT_PERCENT', { value: vatPercent, splitIndex })
    }

    if (currentValues.vatAmount !== vatAmount) {
      commit('SET_VAT_AMOUNT', { value: vatAmount, splitIndex })
    }
  },

  changeScacTypeFromManualToCode({ commit }) {
    commit('SET_MANUAL_SCAC_TYPE_TO_CODE')
  },
}

const showScacValidationErrorGrowl = (error, scac) => {
  const { major, minor, patch } = scac

  const title = i18n.t('SCAC_CODE_INVALID')
  let description

  // Inspect at the error message, it reveals what part of the scac code the BE
  // didn't accept
  if (error.user_message.startsWith('Scac Maj')) {
    description = i18n.t('SCAC_DIGIT_INVALID', {
      interpolation: { escapeValue: false },
      digit: major,
    })
  } else if (error.user_message.startsWith('Scac Min')) {
    description = i18n.t('SCAC_DIGIT_INVALID', {
      interpolation: { escapeValue: false },
      digit: minor,
    })
  } else if (error.user_message.startsWith('Scac Pat')) {
    description = i18n.t('SCAC_DIGIT_INVALID', {
      interpolation: { escapeValue: false },
      digit: patch,
    })
  }

  if (description) {
    // Show the growl
    eventBus.$emit('growl', { title, description, type: 'error' })
  }
}

const generatePatchRequestBody = ({ expense, isOriginal = false }) => {
  return {
    amount_ex_vat: isOriginal ? expense.amount_ex_vat : expense.amountExclVat,
    country: expense.country,
    currency: expense.currency,
    invoice_number: isOriginal ? expense.invoice_number : expense.invoiceNumber,
    date: expense.date,

    merchant: {
      city: expense.merchant.city,
      country: expense.merchant.country,
      name: expense.merchant.name,
      number: expense.merchant.number,
      number_type: isOriginal
        ? expense.merchant.number_type
        : expense.merchant.numberType,
      street: expense.merchant.street,
      street_no: isOriginal
        ? expense.merchant.street_no
        : expense.merchant.streetNo,
      zip_code: isOriginal
        ? expense.merchant.zip_code
        : expense.merchant.zipCode,
    },
    scac: {
      information: isOriginal
        ? expense.scac.information
        : expense.scacInformation,
      scac_description: expense.scacDescription,
      major: expense.scac.major,
      minor: expense.scac.minor,
      patch: expense.scac.patch,
      scac_top_level_category: isOriginal
        ? expense.scac.top_level_category
        : expense.category.key,
      scac_type: isOriginal ? expense.scac.scac_type : expense.scacType,
      vat_refund: isOriginal ? expense.scac.vat_refund : expense.vatRefund,
    },
    vat_amount: isOriginal ? expense.vat_amount : expense.vatAmount,
    maximum_reclaim_amount: isOriginal
      ? expense.maximum_reclaim_amount
      : expense.maximumReclaimAmount,
  }
}

const vatPercentToVatRate = (vatPercent) => {
  return isNumber(vatPercent) ? Math.round(vatPercent * 1000) / 10 : vatPercent
}

const makeSplitApiCalls = async ({
  add = [],
  update = [],
  remove = [],
  expenseId,
}) => {
  await Promise.all([
    // Create splits
    ...add.map((split) =>
      request.post(`expenses/${expenseId}/splits`, {
        body: split,
      })
    ),
    // Delete splits
    ...remove.map((splitId) =>
      request.delete(`expenses/${expenseId}/splits/${splitId}`)
    ),
    // Patch splits
    ...update.map((split) => {
      return request.patch(`expenses/${expenseId}/splits/${split.id}`, {
        body: split,
      })
    }),
  ])
}
