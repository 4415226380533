/**
 * This feature module is used by the enterprise-list component
 */

export const state = {
  queryId: null,
  pagination: {
    page: 1,
    pageSize: 10,
    totalResults: null,
  },
  isLoadingEnterprises: false,
}

export const getters = {
  getEnterprises(state, _, __, rootGetters) {
    const enterprisesRaw = rootGetters['enterprises/items'](state.queryId)
    return enterprisesRaw.map((enterprise) => ({
      id: enterprise.id,

      // Name
      name: enterprise.name,

      // Address
      address: enterprise.address,
      secondAddress: enterprise.second_address,
      postalCode: enterprise.postal_code,
      city: enterprise.city,
      country: enterprise.country,

      // Bank
      bic: enterprise.bic,
      ibanNumber: enterprise.iban_number,
      currency: enterprise.currency,

      // Contact person info
      contactPerson: {
        email: enterprise.contact_person_email,
        firstName: enterprise.contact_person_firstname,
        lastName: enterprise.contact_person_lastname,
        fullName: [
          enterprise.contact_person_firstname,
          enterprise.contact_person_lastname,
        ]
          .filter(Boolean)
          .join(' '),
        phoneCountryCode: enterprise.contact_person_phone_country_code,
        phoneNumber: enterprise.contact_person_phone_number,
        fullPhoneNumber: [
          enterprise.contact_person_phone_country_code,
          enterprise.contact_person_phone_number,
        ]
          .filter(Boolean)
          .join(' '),
      },

      // Enterprise contact Info
      companyEmail: enterprise.company_email,
      phoneCountryCode: enterprise.phone_country_code,
      phoneNumber: enterprise.phone_number,
      fullPhoneNumber: [enterprise.phone_country_code, enterprise.phone_number]
        .filter(Boolean)
        .join(' '),

      // Vat
      vatNumber: enterprise.vat_number,
      vatNumberType: enterprise.vat_number_type,
      vatRate: enterprise.vat_rate,

      // Misc
      companyNumber: enterprise.company_number,
      originatingSystem: enterprise.originating_system,
      idInOriginatingSystem: enterprise.id_in_originating_system,
    }))
  },
}

export const mutations = {
  SET_QUERY_ID(state, { queryId }) {
    state.queryId = queryId
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = {
      ...state.pagination,
      ...pagination,
    }
  },
  SET_LOADING_ENTERPRISES(state, bool) {
    state.isLoadingEnterprises = bool
  },
}

export const actions = {
  async fetchEnterprises({ state, commit, dispatch, rootGetters }, params) {
    commit('SET_LOADING_ENTERPRISES', true)

    const { page, pageSize } = params || {}
    const query = {
      page: page || state.pagination.page,
      pageSize: pageSize || state.pagination.pageSize,
    }
    commit('SET_PAGINATION', query)

    const { queryId } = await dispatch(
      'enterprises/FETCH_ITEMS',
      { query },
      { root: true }
    )

    commit('SET_QUERY_ID', { queryId })

    const response = rootGetters['enterprises/response'](queryId)
    if (response && response.meta) {
      const { page, pageSize, totalResults } = response.meta
      commit('SET_PAGINATION', { page, pageSize, totalResults })
    } else {
      commit('SET_PAGINATION', { page: 0, pageSize: 0, totalResults: 0 })
    }

    commit('SET_LOADING_ENTERPRISES', false)
  },
}
