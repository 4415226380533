import { mapState, mapActions, mapGetters } from 'vuex'
import { isNumber } from 'lodash'

// Computed

export const expenseModalComputed = {
  ...mapState('expenseModal', {
    id: (state) => state.id,
    isInitializing: (state) => state.isInitializing,
    isShowingExpenseModal: (state) => state.isShowingExpenseModal,
    isReadOnly: (state) => state.status === 'RECLAIMED',
    status: (state) => state.status,
    isNewExpense: (state) => !state.id,
    isManuallyCreated: (state) => state.type !== 'TRANSACTION',
    // expenseKey is used as 'key' attribute on components to make vue
    // instantiate a new expense modal for each expense and not reuse old
    // components
    expenseKey: (state) => state.id || Date.now(),
    shouldShowInvoiceNumber: (state) => state.shouldShowInvoiceNumber,
    receipt: (state) => state.receipt,
    enterpriseId: (state) => state.enterpriseId,
    country: (state) => state.country,
    vatRate: (state) => {
      return isNumber(state.vatPercent)
        ? Number((state.vatPercent * 100).toPrecision(4))
        : state.vatPercent
    },
    scac: (state) => state.scac,
    scacWizardCurrentSplitIndex: (state) => state.scacWizardCurrentSplitIndex,
    splits: (state) => state.splits,

    amountExclVat: (state) => state.amountExclVat,
    amountInclVat: (state) => state.amountInclVat,
    totalAmountInclVat: (state) => {
      return (
        state.amountExclVat + state.vatAmount || // Use the sum if it exist
        state.amountInclVat || // ...otherwise take the amountInclVat value as it is
        null // ...or null
      )
    },
    vatAmount: (state) =>
      state.vatAmount ? state.vatAmount.toFixed(2) : state.vatAmount,
    vatRefund: (state) => state.vatRefund,
    invoiceNumber: (state) => state.invoiceNumber,
    date: (state) => state.date,
    merchant: (state) => state.merchant,
    currency: (state) => state.currency,
    totalOriginalAmountInclVat: (state) => {
      return state.originalAmountExclVat + state.originalVatAmount
    },
    history: (state) => state.history,
    historyError: (state) => state.historyError,
    expenseHasChanges: (state) => state.hasChanges,
  }),

  ...mapGetters('expenseModal', [
    'getVatRate',
    'getCategory',
    'getCategoryTemporary',
    'getAmountInclVat',
    'getVatAmount',
    'getVatRefund',
    'getScac',
    'getScacTextReply',
    'getScacInfo',
    'getScacDescription',
    'originalPointOfSale',
    'getScacType',
    'getApiSplits',
    'getIsCurrencyValid',
    'getHasScacTypeManual',
  ]),

  ...mapGetters('categories', {
    categories: 'getCategories',
  }),

  ...mapGetters('vatRates', ['getVatRatesForCountry']),
  ...mapGetters('countries', ['getCurrency', 'getCountries']),
}

// Methods

export const expenseModalMethods = {
  ...mapActions('expenseModal', [
    'closeExpense',
    'createExpense',
    'fetchExpenseHistory',
    'removeTemporaryCategory',
    'saveExpense',
    'updateAmountInclVat',
    'updateCategory',
    'updateDate',
    'updateExpenseScac',
    'updateScacInfo',
    'updateInvoiceNumber',
    'updateCountry',
    'updateMerchantField',
    'updateMerchantCountry',
    'updateMerchantInfo',
    'updateScacType',
    'updateVatRate',
    'updateVatRefund',
    'uploadReceiptImage',
    'updateCurrency',
    'changeExpenseStatus',
    'changeScacTypeFromManualToCode',
    'updateScacDescription',
    'updateMaximumReclaimAmount',
    'fetchMaximumReclaimAmount',

    // Splits
    'addSplit',
    'removeSplit',
    'setScacWizardSplitIndex',
  ]),
}
