export default {
  NO_CATEGORY: 'mdi-category__no_category',
  FUEL: 'mdi-category__Fuel',
  HIRING_MEANS_OF_TRANSPORT: 'mdi-category__CarRental',
  EXPENDITURE_TRANSPORT: 'mdi-category__Parking',
  ROAD_TOLLS: 'mdi-category__RoadAndBridgeToll',
  TRAVEL_EXPENSES: 'mdi-category__TransportTaxi',
  ACCOMMODATION: 'mdi-category__HotelAccommodation',
  FOOD: 'mdi-category__FoodAndBeverage',
  ADMISSIONS: 'mdi-category__AdmissionFairsAndExhibitions',
  ENTERTAINMENT: 'mdi-category__GiftsAndAmusements',
  OTHER: 'mdi-category__Uncategorized',
}
