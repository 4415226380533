// https://date-fns.org/docs/parseISO
import parseISO from 'date-fns/parseISO'
// https://date-fns.org/docs/format
import format from 'date-fns/format'
import { getSavedStateSession } from '@utils/storage'

export const customFormat = (date, formatStr = 'P') => {
  if (!date) return

  const modifiedDate = date instanceof Date ? new Date(date) : parseISO(date)
  const lang = getSavedStateSession('selectedLanguage')
  let locale

  try {
    locale = require(`date-fns/locale/${
      lang === 'en' || !lang ? 'en-GB' : lang
    }/index.js`)
  } catch (e) {
    // in case the library doesn't support the selected language, we use en-GB as fallback
    locale = require(`date-fns/locale/en-GB`)
  }
  return format(modifiedDate, formatStr, {
    locale: locale.default || locale,
  })
}

export default function formatDate(date) {
  return customFormat(date)
}

export function formatDateWithTime(date) {
  return customFormat(date, 'Pp')
}
