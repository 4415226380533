import i18n from '@src/i18n'
import eventBus from '@src/event-bus'

export default function (errorMessage, altErrorMessage) {
  const growlMessage =
    errorMessage && errorMessage.toLowerCase() === 'null enterprise vat rate'
      ? i18n.t('PARTIAL_VAT_RATE_MISSING')
      : i18n.t(altErrorMessage)

  eventBus.$emit('growl', {
    title: 'Oops:',
    description: growlMessage,
    type: 'error',
  })
}
