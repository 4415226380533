import { castArray, isArray } from 'lodash'

export default function createDataModule({ service }) {
  return {
    namespaced: true,

    state: {
      byId: {},
      cache: {
        // usage: {}, // TODO: Will be implemented later

        // queries is a map where key is the "queryId" and value is an object
        // describing the result of fetching data with that query
        queries: {},
      },
    },

    mutations: {
      // The ADD_QUERY mutation is called in order to update the state with a
      // query and its reponse.
      ADD_QUERY(state, { queryId, query, response, success }) {
        const queryDetails = {
          createdAt: Date.now(),
          id: queryId,
          query,
          success,
          response: success
            ? {
                ...response,
                data: Array.isArray(response.data)
                  ? response.data.map((item) => String(item.id))
                  : String(response.data.id),
              }
            : response,
        }
        state.cache.queries[queryId] = queryDetails
        if (success) {
          castArray(response.data).forEach(
            (item) => (state.byId[item.id] = item)
          )
        }
      },
    },

    actions: {
      async FETCH_ITEMS({ commit, state }, { query, useCache = true }) {
        const queryId = `ITEMS_${JSON.stringify(query)}`

        // Check if we have cached this
        const cacheHit = useCache && state.cache.queries[queryId]
        if (!cacheHit || !cacheHit.success) {
          // Call the API
          const response = await service.list(query)
          // Handle the response
          commit('ADD_QUERY', {
            queryId,
            query,
            response: response.data,
            success: response.statusOK,
          })
        }

        return { queryId }
      },

      async FETCH_ITEM({ commit, state }, { query, useCache = true }) {
        const queryId = `ITEM_${JSON.stringify(query)}`

        // Check if we have cached this
        const cacheHit = useCache && state.cache.queries[queryId]
        if (!cacheHit || !cacheHit.success) {
          // Call the API
          const response = await service.find(query)
          // Handle the response
          commit('ADD_QUERY', {
            queryId,
            query,
            response: response.data,
            success: response.statusOK,
          })

          if (!response.statusOK) {
            return response
          }
        }

        return { queryId }
      },

      async UPDATE_ITEM({ state, dispatch }, { query, item }) {
        const queryId = `ITEM_${JSON.stringify(query)}`

        // Try to update the item
        const response = await service.update(query, item)

        if (response.statusOK && state.byId[query.id]) {
          // Update was successful. If we had this item in cache, we will
          // refetch it and thereby update the cache for this item
          dispatch('FETCH_ITEM', { query, useCache: false })
        }

        return { queryId, success: response.statusOK, data: response.data }
      },

      async CREATE_ITEM({ dispatch }, { item }) {
        // Try to create the item
        const { statusOK, data } = await service.create(item)
        if (statusOK) {
          const id = data
          // Fetch the item in order to put it in cache
          dispatch('FETCH_ITEM', { query: { id }, useCache: false })
          return { id }
        } else {
          return { error: data }
        }
      },
    },

    getters: {
      items: (state) => (queryId) => {
        // Check if we have a cached successful response for this queryId
        const cacheHit = state.cache.queries[queryId]
        if (
          !cacheHit ||
          !cacheHit.success ||
          !isArray(cacheHit.response.data)
        ) {
          return []
        }
        // We have data in cache! Let's map the id's inside reponse.data to the
        // data objects in state
        return cacheHit.response.data.map((id) => state.byId[id])
      },
      item: (state) => (id) => {
        return state.byId[id] || null
      },
      response: (state) => (queryId) => {
        // Check if we have a cached response for this queryId
        const cacheHit = state.cache.queries[queryId]
        if (!cacheHit) {
          return null
        }
        // We have data in cache!
        const { response } = cacheHit
        const { data } = response // data contains an id (or array of ids)
        if (!data) {
          return response
        } else {
          return {
            ...response,
            data: Array.isArray(data)
              ? data.map((id) => state.byId[id])
              : state.byId[data.id],
          }
        }
      },
    },
  }
}
