export default function (addRemoveClass, className, element) {
  if (addRemoveClass === 'addClass') {
    element.classList.add(className)
  } else {
    element.classList.remove(className)
  }
}

export const addClass = (className, element) => {
  element.classList.add(className)
}

export const removeClass = (className, element) => {
  element.classList.remove(className)
}
