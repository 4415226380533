export const state = {
  currentModal: null,
}

export const getters = {}

export const mutations = {
  SET_CURRENT_MODAL(state, value) {
    state.currentModal = value
  },
}

export const actions = {}
