import request from '@utils/apiRequest'

export const list = async (query) => {
  try {
    const response = await request.get(`expenses`, {
      params: {
        category: query.category,
        country: query.country,
        enterprise_id: query.enterpriseId,
        order: query.order,
        page: query.page,
        page_size: query.pageSize,
        periods: query.periods,
        sort: query.sort,
        status: query.status,
        year: query.year || new Date().getFullYear(),
      },
    })
    // Tweak response.data a little to fit our needs
    response.data = {
      data: response.data.content,
      categorySummary: response.data.category_summary,
      meta: {
        order: response.data.order,
        page: response.data.page,
        pageSize: response.data.page_size,
        sort: response.data.sort,
        totalResults: response.data.total_results,
      },
    }
    return response
  } catch ({ response }) {
    return response
  }
}

export const find = async ({ id }) => {
  try {
    const response = await request.get(`expenses/${id}`)
    response.data = { data: response.data }
    return response
  } catch ({ response }) {
    return response
  }
}

export const update = async ({ id }, item) => {
  try {
    const response = await request.patch(`expenses/${id}`, { body: item })
    response.data = { data: response.data }
    return response
  } catch ({ response }) {
    return response
  }
}

export const create = async (item) => {
  try {
    const response = await request.post(`expenses`, { body: item })
    return response
  } catch ({ response }) {
    return response
  }
}
