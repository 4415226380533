export function saveStateLocal(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

export function getSavedStateLocal(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

export function removeSavedStateLocal(key) {
  if (window.localStorage.getItem(key) !== null) {
    return window.localStorage.removeItem(key)
  }
}

export function saveStateSession(key, state) {
  window.sessionStorage.setItem(key, JSON.stringify(state))
}

export function getSavedStateSession(key) {
  return JSON.parse(window.sessionStorage.getItem(key))
}

export function removeSavedStateSession(key) {
  window.sessionStorage.removeItem(key)
}
