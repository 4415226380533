export default {
  show: false,
  value: '',
  validations: {
    isPasswordDigitValid: {
      index: 0,
      valid: true,
      message: 'PASSWORD_DIGIT',
      params: null,
    },
    isPasswordLettersValid: {
      index: 1,
      valid: true,
      message: 'PASSWORD_LETTERS',
      params: null,
    },
    isPasswordRangeValid: {
      index: 2,
      valid: true,
      message: 'PASSWORD_RANGE',
      params: {
        min: 7,
        max: 50,
      },
    },
  },
}
