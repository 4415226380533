/**
 * This feature module is used by the enterprise overview
 */

export const state = {
  queryId: null,
  pagination: {
    page: 1,
    pageSize: 3,
    totalResults: null,
  },
  isLoadingEnterprises: false,
}

export const getters = {
  getEnterprises(state, _, __, rootGetters) {
    return rootGetters['enterprises/items'](state.queryId)
  },
}

export const mutations = {
  SET_QUERY_ID(state, queryId) {
    state.queryId = queryId
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = {
      ...state.pagination,
      ...pagination,
    }
  },
  SET_LOADING_ENTERPRISES(state, bool) {
    state.isLoadingEnterprises = bool
  },
}

export const actions = {
  async fetchEnterprises({ commit, dispatch, rootGetters }, params) {
    commit('SET_LOADING_ENTERPRISES', true)

    const { page, pageSize } = params || {}
    const query = {
      page: page || state.pagination.page,
      pageSize: pageSize || state.pagination.pageSize,
    }
    commit('SET_PAGINATION', query)

    const { queryId } = await dispatch(
      'enterprises/FETCH_ITEMS',
      { query },
      { root: true }
    )
    commit('SET_LOADING_ENTERPRISES', false)
    if (queryId) {
      commit('SET_QUERY_ID', queryId)

      const response = rootGetters['enterprises/response'](state.queryId)
      if (response && response.meta) {
        const { page, pageSize, totalResults } = response.meta
        commit('SET_PAGINATION', { page, pageSize, totalResults })
      } else {
        commit('SET_PAGINATION', { page: 0, pageSize: 0, totalResults: 0 })
      }
    }
  },
}
