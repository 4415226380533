import request from '@utils/apiRequest'

export const state = {
  byId: {},
}

export const getters = {
  items: (state) => state.byId,
  item: (state) => (id) => state.byId[id],
}

export const mutations = {
  ADD_RECEIPT(state, { id, content, type, name, photoBucketId }) {
    state.byId[id] = { id, content, type, name, photoBucketId }
  },
  REMOVE_RECEIPT(state, id) {
    delete state.byId[id]
  },
}

export const actions = {
  async FETCH_ITEM({ state, commit }, { query: { id }, useCache = true }) {
    return new Promise(async (resolve, reject) => {
      // Check if we have cached this
      const cacheHit = useCache && state.byId[id]
      if (cacheHit) {
        return resolve()
      }

      try {
        // Get file name
        const {
          data: { fileName, imageId },
        } = await request.get(`receipts/${id}`)

        // Get image data
        const { data } = await request.get(`receipts/${id}/image`, {
          responseType: 'blob',
        })

        // Create an url
        const url = URL.createObjectURL(
          new Blob([data], {
            type: 'application/octet-stream',
          })
        )

        commit('ADD_RECEIPT', {
          id,
          content: url,
          type: data.type,
          name: fileName,
          photoBucketId: imageId, // id from photo bucket used for ocr scan
        })

        return resolve()
      } catch (error) {
        console.error(error)
        commit('REMOVE_RECEIPT', id)
        reject(error)
      }
    })
  },

  async CREATE_ITEM({ dispatch }, { enterpriseId, fileName, mimeType, data }) {
    const { data: id } = await request.post('receipts', {
      body: {
        content: data,
        enterprise_id: enterpriseId,
        file_name: fileName,
        mime_type: mimeType,
      },
    })

    // Fetch the item in order to put it in cache
    await dispatch('FETCH_ITEM', { query: { id }, useCache: false })

    return { id }
  },
}
