import i18n from '@src/i18n'
import { isNumber } from 'lodash'

export const requiredValidation = (value) => {
  const isNull = value === null
  const isUndefined = value === undefined
  const isEmptyString = typeof value === 'string' && !value
  return isNull || isUndefined || isEmptyString
    ? i18n.t('REQUIRED_FIELD')
    : true
}

export const emailValidation = (value) => {
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailPattern.test(value) || i18n.t('EMAIL_INVALID')
}

export const passwordDigitValidation = (value) => {
  const passwordPattern = /^(?=.*[0-9])/
  return passwordPattern.test(value) || i18n.t('PASSWORD_DIGIT')
}
export const passwordSpecialValidation = (value) => {
  const passwordPattern = /^(?=.*[\W|_])/
  return passwordPattern.test(value) || i18n.t('PASSWORD_SPECIAL')
}
export const passwordLettersValidation = (value) => {
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])/
  return passwordPattern.test(value) || i18n.t('PASSWORD_LETTERS')
}

export const maxLengthValidation = (value, length = 50) => {
  return (
    value.length < length ||
    i18n.t('MAX_CHARACTERS_ALLOWED', {
      length,
    })
  )
}

export const minLengthValidation = (value, length = 2) => {
  return (
    value.length >= length ||
    i18n.t('MIN_CHARACTERS_ALLOWED', {
      length,
    })
  )
}

export const numberRangeValidation = (value, min, max) => {
  return (
    (value.length >= min && value.length <= max) ||
    i18n.t('PASSWORD_RANGE', {
      min: min,
      max: max,
    })
  )
}

export const maxNumber = (value, limiter = 0) => {
  return (
    value <= limiter ||
    i18n.t('VALIDATION_MAXIMUM_NUMBER', {
      limiter,
    })
  )
}

export const minNumber = (value, limiter) => {
  return (
    value >= limiter ||
    i18n.t('VALIDATION_MINIMUM_NUMBER', {
      limiter,
    })
  )
}

export const positiveNumber = (value) => {
  return value > 0 || i18n.t('VALIDATION_NOT_POSITIVE')
}

export const inScacDigitNumberRange = (digit, from, to) => {
  return (
    isNumber(digit) === false ||
    (digit >= from && digit <= to) ||
    i18n.t('SCAC_DIGIT_INVALID', {
      digit,
    })
  )
}

export const vatRateMatchValidation = (value, valueToCompare) => {
  return value === valueToCompare || i18n.t('VAT_RATE_MISMATCH_WARNING')
}

export const passwordMatchValidation = (newPassword, confirmPassword) => {
  if (newPassword && confirmPassword) {
    return newPassword === confirmPassword ? '' : i18n.t('PASSWORD_MATCH')
  }
}

export const swiftValidation = (value) => {
  const swiftPattern = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/
  return swiftPattern.test(value) || i18n.t('SWIFT_INVALID')
}

export const isValidIbanNumber = (value) => {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  }
  const iban = String(value)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  let digits
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false || i18n.t('IBAN_INVALID')
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = `${code[3]}${code[1]}${code[2]}`.replace(
    /[A-Z]/g,
    function (letter) {
      return letter.charCodeAt(0) - 55
    }
  )
  // final check
  return mod97(digits) === 1 || i18n.t('IBAN_INVALID')
}

const mod97 = (value) => {
  const digital = value.toString()
  let checksum = digital.slice(0, 2)
  let fragment = ''
  for (let offset = 2; offset < digital.length; offset += 7) {
    fragment = checksum + digital.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

const checkValidation = (callback) => {
  const result = callback()
  return typeof result !== 'string'
}

const getValidationResult = (value, callback) => {
  const isValid = checkValidation(callback)
  if (value && isValid) {
    return 'valid'
  } else if (!value && !isValid) {
    return 'initialValid'
  } else if (!isValid) {
    return 'invalid'
  }
}

export const passwordValidations = (value, validation, key, callback) => {
  validation[key] = {
    ...validation[key],
    valid: getValidationResult(value, callback),
  }
  return callback()
}

export const digitsAndLettersOnly = (value) =>
  /^([a-zA-Z0-9]+)$/.test(value) || i18n.t('VALIDATION_NUMBERS_LETTERS_ONLY')
