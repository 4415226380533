import { size } from 'lodash'
import request from '@utils/apiRequest'

export const state = {
  byCode: {},
}

export const getters = {
  getVatRatesForCountry: (state) => (code) => {
    if (!code || !state.byCode[code]) {
      return []
    }
    return state.byCode[code].vatRates
      .filter((vatRate) => {
        // Keep the vat rate if it doesn't have a validTo or if validTo is in the
        // future
        // return !vatRate.validTo || vatRate.validTo > Date.now()
        return vatRate
      })
      .map((vatRateObj) => vatRateObj.vatRate)
  },
}

export const mutations = {
  SET_VAT_RATES_FOR_COUNTRY(state, { code, data }) {
    const vatRateData = {
      code: data.country,
      vatRates: data.vat_rates.map((obj) => ({
        vatRate: obj.vat_rate,
        vatPercent: obj.vat_rate / 100,
        validFrom: obj.valid_from ? new Date(obj.valid_from) : obj.valid_from,
        validTo: obj.valid_to ? new Date(obj.valid_to) : obj.valid_to,
      })),
    }
    state.byCode = { ...state.byCode, [code]: vatRateData }
  },
}

export const actions = {
  async fetchVatRatesForCountry({ state, commit }, { code, useCache = true }) {
    // If we already have the vat rates and want to reuse, abort here
    if (useCache && size(state.byCode[code]) > 0) {
      return
    }

    // Otherwise fetch the vat rates from the API
    try {
      const { data } = await request.get(`vat-rates/${code}`, {
        baseUrl: 'vat-rates',
        skipAuthentication: true,
      })
      commit('SET_VAT_RATES_FOR_COUNTRY', { code, data })
    } catch (error) {
      console.error(error)
    }
  },
}
