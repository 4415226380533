import { render, staticRenderFns } from "./_base-modal.vue?vue&type=template&id=bdafc84a&"
import script from "./_base-modal.vue?vue&type=script&lang=js&"
export * from "./_base-modal.vue?vue&type=script&lang=js&"
import style0 from "./_base-modal.vue?vue&type=style&index=0&id=bdafc84a&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VBtn,VDialog,VIcon,VOverlay})
