var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user"},[(_vm.isChangePasswordModalVisible)?_c('ChangePasswordModal',{attrs:{"close-fn":() => _vm.toggleChangePasswordModal(false),"title":_vm.$t('CHANGE_PASSWORD'),"content":_vm.changePasswordForm,"on-submit":() => _vm.toggleChangePasswordModal(false)}}):_vm._e(),_c('div',{key:_vm.userMenuKey,staticClass:"trigger",on:{"click":_vm.toggleUserMenuVisibility}},[(_vm.photo)?_c('div',{staticClass:"imageDiv",style:({
        backgroundImage: `url(${_vm.photo})`,
      })}):_c('svg',{class:_vm.headicon,attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"36","height":"36"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd"}},[_c('circle',{attrs:{"cx":"18","cy":"18","r":"18","fill":"#F2F4F6"}}),_c('g',{attrs:{"fill":_vm.iconColor,"fill-rule":"nonzero","transform":"translate(10 10)"}},[_c('circle',{attrs:{"cx":"8","cy":"4","r":"4"}}),_c('path',{attrs:{"d":"M16 13.2c.0033866-.7819214-.4516825-1.4932848-1.163-1.818C12.6811034 10.4438848 10.351003 9.972889 8 10c-2.351003-.027111-4.6811034.4438848-6.837 1.382C.4516825 11.7067152-.0033866 12.4180786 0 13.2L-.0000188 16h16.0000376L16 13.2z"}})])])]),_c('span',{class:_vm.usernameTextColor},[_vm._v(_vm._s(_vm.authenticatedUser))]),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"6"}},[_c('path',{attrs:{"fill":_vm.iconColor,"fill-rule":"nonzero","d":"M1.70711.2928932c-.39053-.3905243-1.02369-.3905243-1.41422 0-.39052.3905243-.39052 1.0236893 0 1.4142136l4 4c.39053.3905243 1.02369.3905243 1.41422 0l4-4c.39052-.3905243.39052-1.0236893 0-1.4142136-.39053-.3905243-1.02369-.3905243-1.41422 0L5 3.5857864 1.70711.2928932z"}})])]),_c('div',{staticClass:"userMenu",class:{ active: _vm.showUserMenu }},[(_vm.showUserMenu)?_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.showUserMenu = false}}}):_vm._e(),(_vm.photo)?_c('div',{staticClass:"largePhotoContainer"},[_c('div',{staticClass:"largePhoto",style:({
          backgroundImage: `url(${_vm.photo}) `,
        })})]):_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"70","height":"70"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd","transform":"translate(-.000037)"}},[_c('circle',{attrs:{"cx":"35.0000365","cy":"35","r":"35","fill":"#F2F4F6"}}),_c('circle',{attrs:{"cx":"35.0000365","cy":"27.2222222","r":"7.7777778","fill":"#8C949E","fill-rule":"nonzero"}}),_c('path',{attrs:{"fill":"#8C949E","fill-rule":"nonzero","d":"M50.5555921 45.1111111c.006585-1.5204027-.8782716-2.9036093-2.2613889-3.535-4.1920212-1.8241128-8.7227719-2.7399381-13.2941667-2.6872222-4.5713948-.0527159-9.1021455.8631094-13.2941667 2.6872222-1.3831173.6313907-2.2679739 2.0145973-2.2613889 3.535l-.0000365 5.4444445h31.1111842l-.0000365-5.4444445z"}})])]),_c('div',{staticClass:"userData"},[_c('div',{staticClass:"userFullName"},[_vm._v(" "+_vm._s(_vm.authenticatedUser)+" ")]),(_vm.$route.path !== '/enterprises')?_c('div',{staticClass:"enterprise"},[_vm._v(" "+_vm._s(_vm.currentEnterprise.name)+" ")]):_vm._e()]),_c('ul',[(_vm.$route.path !== '/enterprises')?_c('li',{staticClass:"account"},[_c('router-link',{attrs:{"to":`/settings/company-settings`}},[_vm._v(" "+_vm._s(_vm.$t('SETTINGS'))+" ")])],1):_vm._e(),(_vm.isVattaxAdmin && _vm.$route.path !== '/enterprises')?_c('li',[_c('router-link',{staticClass:"icon",style:({
            display: 'flex',
            alignItems: 'center',
            margin: '6px 0 3px 0',
          }),attrs:{"to":`/enterprises`}},[_c('v-icon',{staticClass:"v-icon",attrs:{"medium":""}},[_vm._v("list")]),_vm._v(" "+_vm._s(_vm.$t('SELECT_ENTERPRISE'))+" ")],1)],1):_vm._e(),_c('li',{staticClass:"changePassword"},[_c('a',{on:{"click":() => _vm.toggleChangePasswordModal(true)}},[_vm._v(_vm._s(_vm.$t('CHANGE_PASSWORD')))])]),_c('li',{staticClass:"languageSelector"},[_c('a',{on:{"click":_vm.toggleLanguageSelectorVisibility}},[_vm._v(_vm._s(_vm.$t('CHANGE_LANGUAGE')))]),_c('LanguageSelector',{attrs:{"on-change-language":_vm.setLanguage,"is-visible":_vm.isLanguageSelectorVisible,"is-dropdown":true}}),_c('v-icon',{staticClass:"v-icon languageSelectorIcon",on:{"click":_vm.toggleLanguageSelectorVisibility}},[_vm._v("mdi-menu-down")])],1),_c('li',{staticClass:"signout"},[_c('a',{on:{"click":_vm.showLogoutModal}},[_vm._v(_vm._s(_vm.$t('LOG_OUT')))])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }