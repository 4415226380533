import { render, staticRenderFns } from "./expense-history-item.vue?vue&type=template&id=4ecf757e&functional=true&"
import script from "./expense-history-item.vue?vue&type=script&lang=js&"
export * from "./expense-history-item.vue?vue&type=script&lang=js&"
import style0 from "./expense-history-item.vue?vue&type=style&index=0&id=4ecf757e&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  injectStyles,
  null,
  null
  
)

export default component.exports