import request from '@utils/apiRequest'
import eventBus from '@src/event-bus'
import Router from '@src/router/index'
import { getSavedStateSession, saveStateSession } from '@utils/storage'
import i18n from '@src/i18n'

export const state = {
  currentEnterprise: getSavedStateSession('selectedEnterprise') || {},
  poaDocuments: [],
  poaDocument: {
    url: '',
    name: '',
  },
  uploadPOAInProgress: false,
  getPoaDocumentsInProgress: false,
  getPoaDocumentInProgress: false,
  getPoaDocumentsError: false,
  getPoaDocumentError: false,
  getCurrentEnterpriseInProgress: false,
  deletePoaDocumentsInProgress: false,
  currentPoaDocument: null,
}

export const getters = {
  currentEnterprise(state) {
    return state.currentEnterprise
  },
}

export const mutations = {
  SET_CURRENT_ENTERPRISE(state, data) {
    state.currentEnterprise = data
  },
  SET_POA_DOCUMENTS(state, data) {
    state.poaDocuments = data
  },
  GET_POA_DOCUMENTS_IN_PROGRESS(state, bool) {
    state.getPoaDocumentsInProgress = bool
  },
  DELETE_POA_DOCUMENT_IN_PROGRESS(state, bool) {
    state.deletePoaDocumentsInProgress = bool
  },
  GET_POA_DOCUMENT_IN_PROGRESS(state, bool) {
    state.getPoaDocumentInProgress = bool
  },
  SET_POA_DOCUMENTS_ERROR(state, bool) {
    state.getPoaDocumentsError = bool
  },
  SET_POA_DOCUMENT_ERROR(state, bool) {
    state.getPoaDocumentError = bool
  },
  SET_SELECTED_ENTERPRISE(state, data) {
    state.currentEnterprise = data
  },
  GET_CURRENT_ENTERPRISE_IN_PROGRESS(state, bool) {
    state.getCurrentEnterpriseInProgress = bool
  },
  SET_UPLOAD_POA_IN_PROGRESS(state, bool) {
    state.uploadPOAInProgress = bool
  },
  SET_POA_DOCUMENT(state, document) {
    state.poaDocument = {
      url: document.url,
      name: document.name,
    }
  },
}

export const actions = {
  getCurrentEnterprise({ commit }) {
    commit('GET_CURRENT_ENTERPRISE_IN_PROGRESS', true)
    return request
      .get('enterprises/current')
      .then((response) => {
        commit('SET_CURRENT_ENTERPRISE', response.data)
        saveStateSession('selectedEnterprise', response.data)
        commit('GET_CURRENT_ENTERPRISE_IN_PROGRESS', false)
      })
      .catch((error) => {
        const { data } = error && error.response
        // TODO map errors to something meaningful
        eventBus.$emit('growl', {
          title: 'Error:',
          description: data,
          type: 'error',
        })
        commit('GET_CURRENT_ENTERPRISE_IN_PROGRESS', false)
      })
  },

  getSelectedEnterprise(
    { commit },
    { enterpriseId, settings, disableRedirect }
  ) {
    return request
      .get(`enterprises/${enterpriseId}`)
      .then((response) => {
        commit('SET_SELECTED_ENTERPRISE', response.data)
        commit('expenseOverview/SET_PAGE', 1, { root: true })
        saveStateSession('selectedEnterprise', response.data)
        if (disableRedirect) {
          return null
        } else {
          if (settings) {
            Router.push('/settings/company-settings')
          } else {
            Router.push('/overview')
          }
        }
      })
      .catch((error) => {
        const { data } = error && error.response
        // TODO map errors to something meaningful
        eventBus.$emit('growl', {
          title: 'Error:',
          description: data,
          type: 'error',
        })
      })
  },

  // Get enterprise POA documents
  getPOADocuments({ commit, state }) {
    commit('GET_POA_DOCUMENT_IN_PROGRESS', true)

    return request
      .get(`enterprises/${state.currentEnterprise.id}/poa`)
      .then((response) => {
        commit('SET_POA_DOCUMENTS', response.data.content)
        commit('GET_POA_DOCUMENT_IN_PROGRESS', false)
      })
      .catch((error) => {
        commit('GET_POA_DOCUMENT_IN_PROGRESS', false)
        commit('SET_POA_DOCUMENT_ERROR', true)
        const { data } = error && error.response
        // TODO map errors to something meaningful
        eventBus.$emit('growl', {
          title: 'Error:',
          description: data,
          type: 'error',
        })
      })
  },

  // Get single POA document
  getPOADocument({ commit, state }, { index, isDownload = false, name }) {
    commit('GET_POA_DOCUMENT_IN_PROGRESS', true)
    return request
      .get(
        `enterprises/${state.currentEnterprise.id}/poa/${state.poaDocuments[index].id}`,
        { responseType: 'blob' }
      )
      .then((response) => {
        const objectURL = URL.createObjectURL(response.data)
        if (isDownload) {
          const link = document.createElement('a')
          link.href = objectURL
          link.download = name
          link.click()
        } else {
          commit('SET_POA_DOCUMENT', { url: objectURL, name: name })
        }
      })
      .catch((error) => {
        commit('GET_POA_DOCUMENT_IN_PROGRESS', false)
        commit('SET_POA_DOCUMENT_ERROR', true)
        const { data } = error && error.response
        // TODO map errors to something meaningful
        eventBus.$emit('growl', {
          title: 'Error:',
          description: data,
          type: 'error',
        })
      })
  },

  resetPOADocument({ commit }) {
    URL.revokeObjectURL(state.poaDocument.url)

    commit('SET_POA_DOCUMENT', { url: '', name: '' })
  },

  uploadPOADocument({ commit, state, dispatch }, { file, resolveCallback }) {
    if (file) {
      commit('SET_UPLOAD_POA_IN_PROGRESS', true)
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        return new Promise((resolve) => {
          const pdfName = file.name
          const splitMark = reader.result.search(/;/)
          const base64 = reader.result.slice(splitMark + 8)
          const mimeType = reader.result.slice(5, splitMark)
          const payload = {
            content: base64,
            fileName: pdfName,
            mimeType,
          }
          resolve(payload)
        })
          .then((payload) => {
            return request
              .post(`enterprises/${state.currentEnterprise.id}/poa`, {
                body: payload,
              })
              .then(() => {
                commit('SET_UPLOAD_POA_IN_PROGRESS', false)
                dispatch('getPOADocuments')
                eventBus.$emit('growl', {
                  title: 'Success',
                  description: i18n.t('POA_DOCUMENT_SUCCESSFULLY_UPLOADED'),
                  type: 'success',
                })
                dispatch('getSelectedEnterprise', {
                  enterpriseId: state.currentEnterprise.id,
                  disableRedirect: true,
                })
              })
          })

          .catch((error) => {
            console.log(error)
            commit('SET_UPLOAD_POA_IN_PROGRESS', false)
            eventBus.$emit('growl', {
              title: 'Oops:',
              description: i18n.t('POA_DOCUMENT_UPLOAD_FAILED'),
              type: 'error',
            })
          })
      }
      resolveCallback()
      reader.onerror = function (error) {
        console.log('Error: ', error)
        commit('SET_RECEIPT_IMAGE_IN_PROGRESS', false)
      }
    }
  },

  deletePOADocument({ commit, state, dispatch }, index) {
    commit('DELETE_POA_DOCUMENT_IN_PROGRESS', true)

    return request
      .delete(
        `enterprises/${state.currentEnterprise.id}/poa/${state.poaDocuments[index].id}`
      )
      .then(() => {
        commit('DELETE_POA_DOCUMENT_IN_PROGRESS', false)
        dispatch('general/resetModalData', null, { root: true })
        eventBus.$emit('growl', {
          title: 'Yay',
          description: i18n.t('POA_REMOVE_SUCCESS'),
          type: 'success',
        })
        dispatch('getPOADocuments')
        dispatch('getSelectedEnterprise', {
          enterpriseId: state.currentEnterprise.id,
          disableRedirect: true,
        })
      })
      .catch((error) => {
        console.log(error)
        commit('DELETE_POA_DOCUMENT_IN_PROGRESS', false)
        dispatch('general/resetModalData', null, { root: true })
        eventBus.$emit('growl', {
          title: 'Error:',
          description: i18n.t('POA_REMOVE_ERROR'),
          type: 'error',
        })
      })
  },
}
