import request from '@utils/apiRequest'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'
import Router from '@src/router/index'
import showVatRateError from '@utils/showVatRateError'
import { lunadisPayOrigin } from '@/src/constants/origins'

export const state = {
  expenses: [],
  error: false,
  category: null,
  country: null,
  order: 'desc',
  reclaimsSort: 'date_created',
  sort: 'amount_incl_vat',
  status: 'NEED_MORE_INFO',
  getExpensesByCountryInProgress: false,
  getSummaryInProgress: false,
  categoryOverviewInProgress: false,
  getExpensesByCountryError: false,
  reclaimedQuartersInProgress: true,
  moveExpensesInProgress: false,
  summary: null,
  reclaimedQuarters: null,
  categorySummary: {},
  categoryOverview: {},
  selectedExpenses: [],
  year: null,
  pagination: {
    page: 1,
    pageSize: 10,
    total: null,
  },
  createReclaimInProgress: false,
}

const extractReclaimedQuarters = (reclaims) => {
  const reclaimedQuarters = []
  const quartersFromReclaims = reclaims.map((reclaim) => reclaim.period)
  const allQuarters = ['Q1', 'Q2', 'Q3', 'Q4']
  allQuarters.forEach((quarter) => {
    if (quartersFromReclaims.includes(quarter)) reclaimedQuarters.push(quarter)
  })
  return reclaimedQuarters
}

export const getters = {
  getCountry(state) {
    return state.country
  },
}

export const mutations = {
  SET_EXPENSES_BY_COUNTRY(state, response) {
    state.expenses = response.data.content
    state.categorySummary = response.data.category_summary
  },
  SET_SUMMARY(state, data) {
    state.summary = data
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_CATEGORY(state, category) {
    state.category = category
  },
  SET_EXPENSES_BY_COUNTRY_IN_PROGRESS(state, bool) {
    state.getExpensesByCountryInProgress = bool
  },
  SET_CATEGORY_OVERVIEW_IN_PROGRESS(state, bool) {
    state.categoryOverviewInProgress = bool
  },
  SET_EXPENSES_BY_COUNTRY_ERROR(state, bool) {
    state.getExpensesByCountryError = bool
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination
  },
  SET_PAGE(state, page) {
    state.pagination = { ...state.pagination, page }
  },
  SET_SELECTED_EXPENSES(state, selection) {
    state.selectedExpenses = selection
  },
  DESELECT_EXPENSES(state) {
    state.selectedExpenses = []
  },
  SET_CATEGORY_OVERVIEW(state, response) {
    state.categoryOverview = response.data
  },
  SET_YEAR(state, year) {
    state.year = year
  },
  SET_COUNTRY(state, data) {
    state.country = data
  },
  SET_SORT(state, sort) {
    state.sort = sort
  },
  SET_RECLAIMED_QUARTERS(state, quarters) {
    state.reclaimedQuarters = quarters
  },
  SET_RECLAIMED_QUARTERS_IN_PROGRESS(state, bool) {
    state.reclaimedQuartersInProgress = bool
  },
  SET_CREATE_RECLAIM_IN_PROGRESS(state, bool) {
    state.createReclaimInProgress = bool
  },
  SET_SUMMARY_IN_PROGRESS(state, bool) {
    state.getSummaryInProgress = bool
  },
  SET_MOVE_EXPENSES_IN_PROGRESS(state, bool) {
    state.moveExpensesInProgress = bool
  },
}

export const actions = {
  resetExpensesByCountry({ commit }) {
    commit('SET_RECLAIMED_QUARTERS', null)
    commit('SET_SUMMARY', null)
    commit('SET_CATEGORY', null)
    commit('DESELECT_EXPENSES')
    commit('SET_PAGE', 1)
  },
  getExpenses({ commit, state }, params) {
    commit('SET_EXPENSES_BY_COUNTRY_IN_PROGRESS', true)
    return request
      .get(`expenses`, { params })
      .then((response) => {
        commit('SET_EXPENSES_BY_COUNTRY', response)
        commit('SET_PAGINATION', {
          ...state.pagination,
          total: response.data.total_results,
        })
        if (response.data.content && response.data.content.length === 0)
          // Close the list if there are no expenses.
          commit('SET_CATEGORY', null)
        commit('SET_EXPENSES_BY_COUNTRY_IN_PROGRESS', false)
        commit('SET_COUNTRY', params.country) // What's this for?
      })
      .catch((error) => {
        console.log(error)
        commit('SET_EXPENSES_BY_COUNTRY_ERROR', true)
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: 'Error fetching expenses',
          type: 'error',
        })
        commit('SET_EXPENSES_BY_COUNTRY_IN_PROGRESS', false)
      })
  },
  getCategoriesOverview({ commit, state }, params) {
    commit('SET_CATEGORY_OVERVIEW_IN_PROGRESS', true)
    return request
      .get(`expenses/overview/categories`, { params })
      .then((response) => {
        commit('SET_CATEGORY_OVERVIEW', response)
        commit('SET_CATEGORY_OVERVIEW_IN_PROGRESS', false)
      })
      .catch((error) => {
        const message =
          error.response &&
          error.response.data &&
          error.response.data.developer_message
        showVatRateError(message, 'CATEGORY_OVERVIEW_ERROR')
        commit('SET_CATEGORY_OVERVIEW_IN_PROGRESS', false)
      })
  },
  getSummary({ commit, rootState }, countryData) {
    commit('SET_SUMMARY_IN_PROGRESS', true)
    return request
      .get('expenses/summary', {
        params: {
          country: countryData.country,
          enterprise_id: rootState.enterprise.currentEnterprise.id,
          year: countryData.year,
        },
      })
      .then((response) => {
        commit('SET_SUMMARY', response.data)
        commit('SET_SUMMARY_IN_PROGRESS', false)
      })
      .catch((error) => {
        commit('SET_EXPENSES_BY_COUNTRY_ERROR', true)
        const message =
          error.response &&
          error.response.data &&
          error.response.data.developer_message
        showVatRateError(message, 'SUMMARY_ERROR')

        commit('SET_SUMMARY_IN_PROGRESS', false)
      })
  },
  getReclaimedQuarters({ commit, rootState }) {
    if (!state.country) console.warn('No country set for reclaims')
    commit('SET_RECLAIMED_QUARTERS_IN_PROGRESS', true)
    return request
      .get(`reclaims`, {
        params: {
          enterprise_id: rootState.enterprise.currentEnterprise.id,
          year: state.year,
          country: state.country,
          order: state.order,
          page: 1,
          page_size: 10,
          sort: state.reclaimsSort,
        },
      })
      .then((response) => {
        commit(
          'SET_RECLAIMED_QUARTERS',
          extractReclaimedQuarters(response.data.content)
        )
        commit('SET_RECLAIMED_QUARTERS_IN_PROGRESS', false)
      })
      .catch((error) => {
        commit('SET_RECLAIMED_QUARTERS_IN_PROGRESS', false)
        console.log(error)
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: 'Fetching reclaimed quarters failed',
          type: 'error',
        })
      })
  },
  createReclaim({ commit, state, rootState, dispatch }, period) {
    commit('SET_CREATE_RECLAIM_IN_PROGRESS', true)
    const payload = {
      country: state.country,
      enterprise_id: rootState.enterprise.currentEnterprise.id,
      period,
      year: state.year,
    }
    return request
      .post('reclaims', { body: payload })
      .then((response) => {
        if (
          rootState.enterprise.currentEnterprise.originating_system !==
          lunadisPayOrigin
        ) {
          commit('reclaimsOverview/SET_SORT', 'date_created', { root: true })
          commit('reclaimsOverview/SET_ORDER', 'desc', { root: true })
          commit('reclaimsOverview/SET_PAGE', 1, { root: true })
          commit('reclaimsOverview/SET_RECLAIMS_YEAR', state.year, {
            root: true,
          })

          Router.push('/reclaims')
        }

        dispatch('refreshData')
        commit('SET_CREATE_RECLAIM_IN_PROGRESS', false)
        eventBus.$emit('growl', {
          title: 'Success',
          description: i18n.t('RECLAIM_CREATED'),
          type: 'success',
        })
      })
      .catch((error) => {
        const { data } = error && error.response
        const message = data && data.developer_message
        let description

        if (
          message &&
          message.toLowerCase() === 'power of attorney document does not exist'
        ) {
          description = 'POA_DOCUMENT_MISSING'
        } else if (
          message &&
          message.toLowerCase() ===
            'reclaimed amount is less than the limit defined for the quarter'
        ) {
          description = 'RECLAIM_CREATE_LIMIT_ERROR'
        } else {
          description = 'RECLAIM_CREATE_ERROR'
        }

        eventBus.$emit('growl', {
          title: 'Error:',
          description: i18n.t(description),
          type: 'error',
        })
        commit('SET_CREATE_RECLAIM_IN_PROGRESS', false)
      })
  },
  // Handle user request to change status of expense(s).
  // Function caller provides expenseIds and status they are to be moved to.
  moveExpenses(
    { commit, dispatch, state, rootState, rootGetters },
    { status, expenseIds }
  ) {
    commit('SET_MOVE_EXPENSES_IN_PROGRESS', true)
    const promises = expenseIds.map((expenseId) => {
      let expense = state.expenses.find((expense) => expense.id === expenseId)
      if (!expense) {
        expense = rootGetters['expenses/item'](expenseId)
      }
      // TODO: Once PATCH /expenses endpoint is properly implemented,
      // only status param should be sent, like this:
      // const payload = { status }
      const payload = {
        amount_ex_vat: expense.amount_ex_vat,
        country: expense.country,
        currency: expense.currency,
        invoice_number: expense.invoice_number,
        status,
        vat_amount: expense.vat_amount,
        scac: {
          scac_top_level_category: expense.scac.top_level_category,
        },
      }

      return request
        .patch(`expenses/${expenseId}`, { body: payload })
        .catch((error) => {
          console.log(error)
        })
    })

    return Promise.all(promises)
      .then(() => {
        commit('SET_SELECTED_EXPENSES', [])
        dispatch('refreshData')
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('SET_MOVE_EXPENSES_IN_PROGRESS', false)
      })
  },
  async refreshData({ state, rootState, dispatch }) {
    let promises = []

    const { category, status, country, year, pagination, sort, order } = state
    const { page, pageSize } = pagination
    const enterpriseId = rootState.enterprise.currentEnterprise.id

    if (
      // If all these values are truthy, we call the getExpenses action
      [
        category,
        status,
        country,
        enterpriseId,
        year,
        page,
        pageSize,
        sort,
        order,
      ].every(Boolean)
    ) {
      promises.push(
        dispatch('getExpenses', {
          category,
          status,
          country,
          enterprise_id: enterpriseId,
          year,
          page,
          page_size: pageSize,
          sort,
          order,
        })
      )
    }

    if (
      // If all these values are truthy, we call the getSummary action
      [country, enterpriseId, year].every(Boolean)
    ) {
      promises.push(
        dispatch('getSummary', { country, enterprise_id: enterpriseId, year })
      )
    }

    if (
      // If all these values are truthy, we call the getCategoriesOverview action
      [status, country, enterpriseId, year].every(Boolean)
    ) {
      promises.push(
        dispatch('getCategoriesOverview', {
          status,
          country,
          enterprise_id: enterpriseId,
          year,
        })
      )
    }

    return Promise.all(promises)
  },
  setCountry({ commit }, country) {
    commit('SET_COUNTRY', country)
  },
  setYear({ commit }, year) {
    commit('SET_YEAR', year)
  },
  setReclaimedQuarters({ commit }, quarters) {
    commit('SET_RECLAIMED_QUARTERS', quarters)
  },
  setSummary({ commit }, summary) {
    commit('SET_SUMMARY', summary)
  },
  setCategory({ commit, state }, category) {
    commit('SET_CATEGORY', category)
    commit('SET_PAGINATION', { ...state.pagination, page: 1 })
  },
  setSelectedExpenses({ commit }, selection) {
    commit('SET_SELECTED_EXPENSES', selection)
  },
  deselectExpenses({ commit }) {
    commit('DESELECT_EXPENSES')
  },
  setPagination({ commit }, pagination) {
    commit('SET_PAGINATION', pagination)
  },
  setPage({ commit }, page) {
    commit('SET_PAGE', page)
  },
  setStatus({ commit }, status) {
    commit('SET_STATUS', status)
  },
  setSort({ commit }, sort) {
    commit('SET_SORT', sort)
  },
}
