<script>
import HeaderTop from '@components/header-top/header-top'

export default {
  components: { HeaderTop },
}
</script>

<template>
  <div :class="$style.container">
    <HeaderTop />
    <slot />
    <BaseModal />
    <BaseExpenseModal />
  </div>
</template>

<style lang="scss" module>
.container {
  height: 100%;
}
</style>
