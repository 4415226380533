import { mapState, mapActions } from 'vuex'

export const modalComputed = {
  ...mapState('general', {
    modalData: (state) => state.modalData,
  }),
}
export const modalMethods = mapActions('general', [
  'setModalData',
  'resetModalData',
])
