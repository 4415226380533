/**
 * This watcher is used to trigger actions when expense modal data changes
 */
import { isEqual } from 'lodash'
import { compareSplits } from '@state/utils/expense-splits'

export default (store) => {
  return store.watch(
    // Reactively watch this functions return value
    (state, getters) => {
      // Base
      const apiExpense = getters['expenseModal/originalExpenseRequestBody']
      const localExpense = getters['expenseModal/editedExpenseRequestBody']
      const baseHasChanges = !isEqual(localExpense, apiExpense)

      // Receipt
      const apiReceiptId = getters['expenseModal/originalExpenseReceiptId']
      const localReceiptId = getters['expenseModal/receiptId']
      const receiptHasChanged = apiReceiptId !== localReceiptId

      // Splits
      const apiSplits = getters['expenseModal/getApiSplits']
      const localSplits = getters['expenseModal/getLocalSplits']
      const { add, remove, update } = compareSplits({ apiSplits, localSplits })
      const splitsHaveChanged = [...add, ...remove, ...update].length > 0

      const somethingChanged =
        baseHasChanges || receiptHasChanged || splitsHaveChanged

      // The 'hasChanges' property in the expenseModal state can be changed
      // "behind the scenes", so this watcher needs to consider that property
      // when it's evaluating if the state has changed. The callback to this
      // watcher will not run unless the output of the watcher function changes.
      // Therefore we will add a 'force' property below to make sure the
      // callback runs when we know it has to update 'hasChanges'
      const force =
        getters['expenseModal/expenseHasChanges'] !== somethingChanged

      return JSON.stringify({
        force: force ? Date.now() : false,
        somethingChanged,
      })
    },

    // Call this function when the value changes
    (json) => {
      const { somethingChanged } = JSON.parse(json)
      store.dispatch('expenseModal/setExpenseHasChanges', somethingChanged, {
        root: true,
      })
    },

    // Options
    {
      immediate: true,
    }
  )
}
