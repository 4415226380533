import request from '@utils/apiRequest'
import { fromPairs, isObject } from 'lodash'
export const state = {
  cache: {
    queries: {},
  },
  byQuery: {},
}

export const getters = {
  item: (state) => (queryId) => {
    // Check if we have a successful cached response for this queryId
    const cacheHit = state.cache.queries[queryId]
    if (!cacheHit || !cacheHit.success || !isObject(cacheHit.response)) {
      return null
    }
    // We have data in cache!
    return state.byQuery[queryId]
  },
}

export const mutations = {
  ADD_RECEIPT_LIMITS(state, { queryId, query, response, success }) {
    const queryDetails = {
      createdAt: Date.now(),
      id: queryId,
      query,
      success,
      response,
    }
    state.cache.queries[queryId] = queryDetails

    if (success) {
      let responseModified = fromPairs(
        Object.entries(response).map(([key, value]) => {
          if (key === 'accomodation') {
            key = 'accommodation'
          }
          return [key.toUpperCase(), value]
        })
      )

      state.byQuery[queryId] = responseModified
    }
  },
}

export const actions = {
  async fetchReceiptLimits({ commit, state }, { query, useCache = true }) {
    const queryId = `ITEMS_${JSON.stringify(query)}`
    const { country, currency } = query

    // Check if we have cached this
    const cacheHit = useCache && state.cache.queries[queryId]
    if (!cacheHit || !cacheHit.success) {
      // Call the API
      const response = await request.get(`receiptLimits/${country}`, {
        params: {
          currency,
        },
      })
      // Handle the response
      commit('ADD_RECEIPT_LIMITS', {
        queryId,
        query,
        response: response.data,
        success: response.statusOK,
      })
    }
    return { queryId }
  },
}
