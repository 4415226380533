import { getSavedStateSession } from '@utils/storage'
import axios from 'axios'
import i18n from '@src/i18n'
import { cloneDeep } from 'lodash'

const initialState = {
  modalData: {
    secondBtnVisible: false,
    secondBtnAction: null,
    confirmAction: () => null,
    declineAction: () => null,
    isModalVisible: null,
    confirmText: null,
    content: null,
    isAlert: false,
    title: null,
  },
  language: getSavedStateSession('selectedLanguage') || 'en',
  base_api_url: '',
  logo_url: 'https://logo-service-api.dev.cardlay.com',
  vat_question_service_url: 'https://vat-question-api.stage.vattax.com/api',
  vat_rates_url: 'https://vat-rates.dev2.cardlay.com/api/v1',
  vat_rates: null,
  categories: [],
  loading: false,
  userMenuKey: false,
  reclaimPeriod: 6,
  logo_api_solution_id: '',
}

export const state = cloneDeep(initialState)

export const getters = {
  base_api_url(state) {
    return state.base_api_url
  },
  vat_rates_url(state) {
    return state.vat_rates_url
  },
  logo_url(state) {
    return state.logo_url
  },
  vat_question_service_url(state) {
    return state.vat_question_service_url
  },
  getVatQuestionServiceUrl(state) {
    return state.vat_question_service_url
  },
  getReclaimPeriod(state) {
    return state.reclaimPeriod
  },
  language(state) {
    return state.language
  },
  get_logo_api_solution_id(state) {
    return state.logo_api_solution_id
  },
}

export const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language
  },
  SET_MODAL_DATA(state, data) {
    state.modalData = data
  },
  RESET_MODAL_DATA(state) {
    Object.entries(initialState.modalData).forEach(([key, value]) => {
      state.modalData[key] = value
    })
  },
  SET_BASE_API_URL(state, data) {
    state.base_api_url = data
  },
  SET_LOGO_URL(state, data) {
    state.logo_url = data
  },
  SET_VAT_QUESTION_SERVICE_URL(state, data) {
    state.vat_question_service_url = data
  },
  SET_VAT_RATES_URL(state, data) {
    state.vat_rates_url = data
  },
  SET_VAT_RATES(state, data) {
    state.vat_rates = data
  },
  SET_LOADING(state, bool) {
    state.loading = bool
  },
  SET_RECLAIM_PERIOD(state, data) {
    const dataAsNumber = parseInt(data)
    if (isNaN(dataAsNumber)) {
      console.error('Reclaim period must be a number')
      return
    }

    state.reclaimPeriod = dataAsNumber
  },
  TOGGLE_USER_MENU_KEY(state) {
    state.userMenuKey = !state.userMenuKey
  },
  SET_CATEGORIES(state, categories) {
    if (!categories) return
    const value = categories.map((category) => ({
      ...category,
      expanded: false,
    }))
    state.categories = value
  },
  SET_RECEIPT_LIMITS(state, data) {
    state.receiptLimits = data
  },
  SET_LOGO_API_SOLUTION_ID(state, data) {
    state.logo_api_solution_id = data
  },
}

export const actions = {
  setApiUrl({ commit }, url) {
    commit('SET_BASE_API_URL', url)
  },
  setLogoUrl({ commit }, url) {
    commit('SET_LOGO_URL', url)
  },
  setVatQuestionServiceUrl({ commit }, url) {
    commit('SET_VAT_QUESTION_SERVICE_URL', url)
  },
  setReclaimPeriod({ commit }, data) {
    commit('SET_RECLAIM_PERIOD', data)
  },
  setVatRatesUrl({ commit }, url) {
    commit('SET_VAT_RATES_URL', url)
  },
  setLogoApiSolutionId({ commit }, data) {
    commit('SET_LOGO_API_SOLUTION_ID', data)
  },
  setModalData({ commit }, data) {
    return new Promise((resolve) => {
      commit('SET_MODAL_DATA', data)
      resolve()
    })
  },

  resetModalData({ commit }) {
    commit('RESET_MODAL_DATA')
  },

  getCategories({ commit, rootState }) {
    return axios
      .get(`${rootState.general.vat_question_service_url}/categories/all`, {
        headers: {
          'Accept-Language': i18n.locale,
        },
      })
      .then((response) => {
        commit('SET_CATEGORIES', response.data.categories)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  getVatRates({ commit, rootState }, country) {
    return axios
      .get(`${rootState.general.vat_rates_url}/vat-rates/${country}`)
      .then((response) => {
        commit(
          'SET_VAT_RATES',
          response.data.vat_rates.map((rate) => rate.vat_rate)
        )
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
