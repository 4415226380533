<script>
import { modalComputed } from '@state/helpers/modal'

export default {
  computed: {
    ...modalComputed,
  },
}
</script>

<template>
  <v-overlay :value="modalData.isModalVisible" color="#222F44" :opacity="0.8">
    <v-dialog
      v-model="modalData.isModalVisible"
      max-width="400"
      content-class="promptModal"
      hide-overlay
      @click:outside="modalData.declineAction"
    >
      <div :class="$style.modal">
        <v-icon :class="$style.modalIcon" @click="modalData.declineAction"
          >mdi-close</v-icon
        >
        <h1 :class="$style.modalTitle">{{ modalData.title }}</h1>

        <p :class="$style.modalText">{{ modalData.content }}</p>

        <v-btn
          v-if="modalData.confirmAction"
          :class="['btn', modalData.isAlert ? 'alert-btn' : 'primary-btn']"
          text
          @click="modalData.confirmAction"
          >{{ modalData.confirmText }}</v-btn
        >
        <div :class="$style.separator" />
        <v-btn
          v-if="modalData.secondBtnVisible"
          class="cancel-btn remove center"
          text
          @click="modalData.secondBtnAction"
          >{{ modalData.secondBtnText }}</v-btn
        >
      </div>
    </v-dialog>
  </v-overlay>
</template>

<style lang="scss" module>
@import '@design';

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2.4rem 3rem;
  text-align: center;
  :global(.alert-btn) {
    min-width: 225px !important;
    border-radius: 25px;
  }

  .separator {
    height: 10px;
  }

  .modalTitle {
    margin-bottom: 1.2rem;
    font-size: 1.92rem;
    font-weight: bold;
    color: $dark;
  }

  .modalText {
    margin-bottom: 1.8rem;
    font-size: 0.8rem;
    color: $dark-grey;
  }
}
.modalIcon {
  position: absolute !important; // sorry
  top: 20px;
  right: 20px;
  font-size: 2.16rem;
  cursor: pointer;
}
</style>
