import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('auth', {
    authenticationError: (state) => state.authenticationError,
    currentUser: (state) => state.currentUser,
    shouldShowSecurityCheck: (state) => state.shouldShowSecurityCheck,
    isForgotPasswordActive: (state) => state.isForgotPasswordActive,
  }),
  ...mapGetters('auth', ['loggedIn', 'isUserIdle', 'authHeader']),
}

export const authMethods = mapActions('auth', [
  'toggleForgotPassword',
  'logIn',
  'logOut',
  'resetPreauthenticatedUser',
  'submitSecurityCode',
  'forgotPassword',
  'resetPassword',
  'createPassword',
  'changePassword',
  'refreshToken',
])
