import { mapState } from 'vuex'

export const headerUserComputed = {
  ...mapState('auth', {
    userPhoto: (state) => state.currentUserPhoto,
  }),
  ...mapState('general', {
    userMenuKey: (state) => state.userMenuKey,
  }),
}
