import store from '@state/store'
import { saveExpenseToStorage } from '@state/utils/expense-modal'
import i18n from '@src/i18n'
import eventBus from '@src/event-bus'
export default [
  {
    path: '/',
    name: 'login',
    component: () => lazyLoadView(import('@views/login/login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'expenses overview' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/overview',
    name: 'expenses overview',
    component: () =>
      lazyLoadView(import('@views/expenses-overview/expenses-overview')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },
  {
    path: '/settings/company-settings',
    name: 'company settings',
    component: () =>
      lazyLoadView(import('@views/company-settings/company-settings')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },
  {
    path: '/settings/users',
    name: 'user management',
    component: () =>
      lazyLoadView(import('@views/user-management/user-management')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },
  {
    path: '/reclaims',
    name: 'reclaims overview',
    component: () =>
      lazyLoadView(import('@views/reclaims-overview/reclaims-overview')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },
  {
    path: '/reclaim/:country/:period/:uuId',
    name: 'reclaim by id',
    component: () => lazyLoadView(import('@views/reclaim-by-id/reclaim-by-id')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },
  {
    path: '/invoice/:id',
    name: 'invoice by id',
    component: () => lazyLoadView(import('@views/invoice-by-id/invoice-by-id')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },

  {
    path: '/reset-password/:id',
    name: 'reset password',
    component: () =>
      lazyLoadView(import('@views/reset-password/reset-password')),
  },
  {
    path: '/create-password/:id',
    name: 'create password',
    component: () =>
      lazyLoadView(import('@views/create-password/create-password')),
  },
  {
    path: '/expenses/:country/:id',
    name: 'expense details',
    component: () =>
      lazyLoadView(import('@views/expenses-by-country/expenses-by-country')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },

    beforeEnter(routeTo, routeFrom, next) {
      // handle direct link expense
      saveExpenseToStorage(routeTo.params.id).then((response) => {
        if (response.id) {
          next()
        } else {
          let description
          switch (response) {
            case 401:
              description = 'DIRECT_LINK_EXPENSE_UNAUTHORIZED'
              break
            case 404:
              description = 'DIRECT_LINK_EXPENSE_NOT_FOUND'
              break
            default:
              description = 'GENERAL_ERROR'
          }
          eventBus.$emit('growl', {
            title: ' ',
            description: i18n.t(description),
            type: 'error',
          })
          next({ name: 'expenses overview' })
        }
      })
    },
  },
  {
    path: '/expenses/:country',
    name: 'expenses by country',
    component: () =>
      lazyLoadView(import('@views/expenses-by-country/expenses-by-country')),
    meta: {
      authRequired: true,
      enterpriseRequired: true,
    },
  },

  {
    path: '/enterprises',
    name: 'Companies Overview',
    component: () =>
      lazyLoadView(import('@/src/router/views/enterprises/enterprises')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')

        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
