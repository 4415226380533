import axios from 'axios'
import { getSavedStateSession } from '@utils/storage'
import store from '@state/store'
import Router from '@src/router/index'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'

const axiosInstance = axios.create()
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code within the range of 2xx cause this function to trigger
    response.statusOK = true
    return response
  },
  function (error) {
    // Any status codes outside the range of 2xx cause this function to trigger
    error.response.statusOK = false
    return Promise.reject(error)
  }
)

const request = (method, url, options = {}) => {
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'cap-auth': getSavedStateSession('token'),
    ...options.customHeaders,
  }

  if (options.skipAuthentication) {
    delete headers['cap-auth']
  }

  if (options.addAcceptLanguage) {
    headers['Accept-Language'] = getSavedStateSession('selectedLanguage')
  }

  let computedUrl
  switch (options.baseUrl) {
    case 'vat-rates':
      computedUrl = `${store.getters['general/vat_rates_url']}/${url}`
      break
    case 'scac':
      computedUrl = `${store.getters['general/vat_question_service_url']}/${url}`
      break
    case 'logo':
      computedUrl = `${store.getters['general/logo_url']}/${url}`
      break
    default:
      computedUrl = `${store.getters['general/base_api_url']}/api/ui/v1.0/${url}`
      break
  }

  return axiosInstance({
    method: method,
    url: computedUrl,
    headers: headers,
    data: options.body ? options.body : '',
    params: options.params ? options.params : '',
    responseType: options.responseType,
  }).catch((error) => {
    const isTokenExpired =
      error.response &&
      error.response.data &&
      error.response.data.message &&
      error.response.data.message.includes('JWT expired')

    if (isTokenExpired) {
      Router.push('/logout')
      eventBus.$emit('growl', {
        title: 'Error:',
        description: i18n.t('SESSION_EXPIRED'),
        type: 'error',
      })
      return
    }
    return new Promise((resolve, reject) => {
      reject(error)
    })
  })
}

function get(url, ops) {
  return request('GET', url, ops)
}

function post(url, ops) {
  return request('POST', url, ops)
}

function put(url, ops) {
  return request('PUT', url, ops)
}

function $delete(url, ops) {
  return request('DELETE', url, ops)
}

function patch(url, ops) {
  return request('PATCH', url, ops)
}

export default {
  get,
  put,
  post,
  patch,
  delete: $delete,
}
