<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { requiredValidation, maxNumber } from '@utils/inputValidation'
import { pick, isNumber } from 'lodash'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
    disabled: { type: Boolean, default: false },
  },

  data: () => {
    return {
      split: {
        vatRefund: null,
      },
    }
  },

  computed: {
    ...pick(expenseModalComputed, ['getVatRefund', 'isReadOnly']),

    isBaseSplit() {
      return !isNumber(this.split.index)
    },

    isDisabled() {
      return this.disabled || this.isReadOnly
    },

    validationRules() {
      return [requiredValidation, (v) => maxNumber(v, 100)]
    },
  },

  created() {
    const vatRefund = this.getVatRefund(this.splitIndex)
    this.split.vatRefund = isNumber(vatRefund) ? vatRefund : 100
    // Start watching Vuex store
    this.unwatchStore = this.$store.watch(
      // Reactively watch this functions return value
      (state) => {
        const index = this.splitIndex
        const vatRefund = this.getVatRefund(this.splitIndex)

        const split = { index, vatRefund }
        return JSON.stringify(split)
      },
      // Call this function when the value changes
      (json) => {
        this.split = { ...this.split, ...JSON.parse(json) }
      },
      // Options
      { immediate: true }
    )
  },

  beforeDestroy() {
    this.unwatchStore()
  },

  methods: {
    ...pick(expenseModalMethods, ['updateVatRefund']),

    onChangeVatRefund(input) {
      const value = parseFloat(input.target.value)
      this.updateVatRefund({
        vatRefund: value,
        splitIndex: this.split.index,
      })
    },

    preventKeyCode(event) {
      if (event.keyCode === 69) {
        // We prevent 'e' because it can be used to express large
        // numbers. E.g. 1000 = 1e3.
        event.preventDefault()
      }
    },
  },
}
</script>

<template>
  <div>
    <v-text-field
      v-model="split.vatRefund"
      :label="$t('VAT_REFUND')"
      outlined
      placeholder="'"
      type="number"
      :rules="validationRules"
      :disabled="isDisabled"
      :suffix="'%'"
      :class="$style.amountInclVat"
      @focus="isEditing = true"
      @blur="isEditing = false"
      @keyup="onChangeVatRefund"
      @keydown="preventKeyCode"
      @wheel.prevent
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.amountInclVat {
  :global(.v-text-field__suffix) {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  :global(.v-label:not(.v-label--active)) {
    right: 35px !important;
  }
}

.warningMessage {
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: $alert-error;
  }

  padding: 0 12px;
}
</style>
