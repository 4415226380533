<script>
export default {
  props: {
    items: { type: Array, default: () => null },
  },
}
</script>

<template functional>
  <div :class="$style.infoBox">
    <div
      v-for="item in props.items"
      :key="item.label"
      :class="[
        $style.infoBoxText,
        item.manualProcessing ? $style.manualProcessingColor : $style.infoColor,
      ]"
    >
      <span
        :class="
          item.manualProcessing
            ? $style.infoBoxTitleManual
            : $style.infoBoxTitle
        "
        >{{ item.label }}</span
      >
      {{ item.content }}
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.infoBox {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 7px;
  font-size: 13px;
  color: $dark-brown;
  border-radius: 4px;
  box-shadow: 0 1px 2px -28px rgba(34, 47, 68, 0.09),
    0 3px 4px -28px rgba(34, 47, 68, 0.14),
    0 7px 7px -7px rgba(34, 47, 68, 0.11),
    0 28px 19px -28px rgba(34, 47, 68, 0.25);

  .infoBoxText {
    width: 100%;
    padding: 20px 30px 20px 20px;

    &:first-child:not(:only-child) {
      border-bottom: 1px solid $dark-yellow;
    }
  }

  .infoBoxTitle {
    margin-right: 3px;
    font-weight: bold;
  }

  .infoBoxTitleManual {
    position: relative;
    top: -1px;
    margin-right: 3px;
    font-weight: bold;
  }
}

.infoColor {
  background-color: $user-card-button-bgd;
  &::after {
    position: absolute;
    top: -8px;
    right: calc(100% * 0.25);
    width: 0;
    height: 0;
    content: '';
    border-right: 6px solid transparent;
    border-bottom: 8px solid $user-card-button-bgd;
    border-left: 6px solid transparent;
    transform: translateX(50%);
  }
}

.manualProcessingColor {
  background-color: $yellow;
  &::after {
    position: absolute;
    top: -8px;
    right: calc(100% * 0.25);
    width: 0;
    height: 0;
    content: '';
    border-right: 6px solid transparent;
    border-bottom: 8px solid $yellow;
    border-left: 6px solid transparent;
    transform: translateX(50%);
  }
}
</style>
