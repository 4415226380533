import { mapState, mapActions } from 'vuex'

export const enterpriseComputed = mapState('enterprise', {
  currentEnterpriseId: (state) => state.currentEnterprise.id,
  currentEnterprise: (state) => state.currentEnterprise,
  getCurrentEnterpriseInProgress: (state) =>
    state.getCurrentEnterpriseInProgress,
  poaDocuments: (state) => state.poaDocuments,
  getPoaDocumentsInProgress: (state) => state.getPoaDocumentsInProgress,
  getPoaDocumentsError: (state) => state.getPoaDocumentsError,
  poaDocument: (state) => state.poaDocument,
})

export const enterpriseMethods = mapActions('enterprise', [
  'getPOADocuments',
  'getPOADocument',
  'uploadPOADocument',
  'deletePOADocument',
  'resetPOADocument',
  'getCurrentEnterprise',
])
