<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { pick } from 'lodash'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
    disabled: { type: Boolean, default: false },
  },

  data: () => {
    return {
      scacDescription: null,
      message: null,
      messageClass: null,
    }
  },

  computed: {
    ...pick(expenseModalComputed, ['splits', 'getScac', 'getScacDescription']),
    scacMajor() {
      return this.getScac(this.splitIndex).major
    },
  },

  beforeMount() {
    this.scacDescription = this.getScacDescription(this.splitIndex)
    this.message = this.getMessage()
    this.messageClass = this.setNoValueClass(this.scacDescription)
  },
  methods: {
    ...pick(expenseModalMethods, ['updateScacDescription']),
    onKeyUp(e) {
      this.updateScacDescription({
        description: e.target.value,
        splitIndex: this.splitIndex,
      })
      this.message = this.getMessage(e.target.value)
      this.messageClass = this.setNoValueClass(e.target.value)
    },
    getMessage(value) {
      return !this.isNewExpense && !value
        ? this.$i18n.t('INPUT_MISSING_VALUE')
        : ''
    },
    setNoValueClass(value) {
      return !this.isNewExpense && !value ? 'noValue' : ''
    },
  },
}
</script>

<template>
  <!-- Show SCAC description only for category 'Other' -->
  <div v-if="scacMajor === 10" :class="$style.scacDescription">
    <v-text-field
      :value="scacDescription"
      outlined
      :disabled="disabled"
      :label="$t('SCAC_DESCRIPTION')"
      :messages="message"
      :class="messageClass"
      @keyup="onKeyUp"
    />
  </div>
</template>

<style lang="scss" module>
@import '@design';

.scacDescription {
  margin-top: 15px;
}
</style>
