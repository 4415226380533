<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { pick } from 'lodash'
import ExpenseCountryDropdown from '@components/expense-country-dropdown/expense-country-dropdown'
import EventBus from '@src/event-bus'
import { digitsAndLettersOnly } from '@utils/inputValidation'
import { getSavedStateSession } from '@utils/storage'

export default {
  components: {
    ExpenseCountryDropdown,
  },
  props: {
    setValidSection: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      merchantFormIsValid: true,
      digitsAndLettersOnly,
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['isReadOnly', 'merchant', 'isNewExpense']),
  },
  watch: {
    // When local 'merchantFormIsValid' changes
    merchantFormIsValid: {
      immediate: true,
      handler(bool) {
        this.setValidSection(bool)
      },
    },
  },
  mounted() {
    EventBus.$on('validate-fields', () => {
      this.$refs.merchantForm.validate()
    })
  },
  beforeDestroy() {
    EventBus.$off('validate-fields')
  },
  methods: {
    ...pick(expenseModalMethods, [
      'updateMerchantField',
      'updateMerchantCountry',
      'updateMerchantInfo',
    ]),

    lookupMerchant() {
      this.updateMerchantInfo()
    },

    updateMerchant(key, value) {
      this.updateMerchantField({ key, value: value.trim() })
    },
    setNoValueClass(value) {
      return !this.isNewExpense && !value ? 'noValue' : ''
    },
    setMessages(value) {
      return !(this.isNewExpense || (value && value.trim()))
        ? this.$i18n.t('INPUT_MISSING_VALUE')
        : ''
    },
  },
}
</script>

<template>
  <div>
    <h2 class="expenseTitle">{{ $t('MERCHANT_INFO') }}</h2>
    <v-form ref="merchantForm" v-model="merchantFormIsValid">
      <!-- Merchant country -->
      <v-row>
        <v-col sm="12" cols="12">
          <ExpenseCountryDropdown
            :value="merchant.country"
            :on-select="updateMerchantCountry"
            :label="$t('LABEL_MERCHANT_COUNTRY')"
            :disabled="isReadOnlyUser || isReadOnly"
            :messages="setMessages(merchant.country)"
            :class="setNoValueClass(merchant.country)"
          />
        </v-col>
      </v-row>

      <!-- Merchant VAT number -->
      <v-row>
        <!-- If merchant is in Germany, we add a select-dropdown where
        numberType can be selected-->
        <v-col v-if="merchant.country === 'DE'" sm="12" cols="12">
          <v-select
            :value="merchant.numberType"
            :items="[
              {
                numberType: 'TAX_REGISTRATION_NUMBER',
                label: $t('LABEL_TAX_REGISTRATION_NUMBER'),
              },
              {
                numberType: 'VAT_NUMBER',
                label: $t('LABEL_VAT_NUMBER'),
              },
            ]"
            item-text="label"
            item-value="numberType"
            outlined
            prepend-inner-icon="place"
            class="bulky forced"
            :label="$t('LABEL_VAT_NUMBER_TYPE')"
            :disabled="isReadOnlyUser || isReadOnly"
            @change="(numberType) => updateMerchant('numberType', numberType)"
          />
        </v-col>
        <v-col sm="12" cols="12">
          <v-text-field
            v-model="merchant.number"
            outlined
            :rules="[digitsAndLettersOnly]"
            :label="
              merchant.numberType === 'TAX_REGISTRATION_NUMBER'
                ? $t('LABEL_TAX_REGISTRATION_NUMBER')
                : $t('LABEL_VAT_NUMBER')
            "
            :class="[setNoValueClass(merchant.number), $style.merchantNumber]"
            :messages="setMessages(merchant.number)"
            :disabled="isReadOnlyUser || isReadOnly"
            @keyup.enter="$event.target.blur"
            @blur="
              (e) => updateMerchant('number', e.target.value.toUpperCase())
            "
          >
            <template v-if="!isReadOnlyUser && !isReadOnly" slot="append">
              <v-btn
                :class="{
                  isLoading: merchant.isLoading,
                  'naked-btn': true,
                  primary: true,
                  bold: true,
                  smaller: true,
                }"
                text
                :disabled="!merchant.number || !merchant.country"
                @click="lookupMerchant"
              >
                {{ $t(merchant.isLoading ? 'SEARCHING' : 'LOOKUP_MERCHANT') }}
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <!-- Merchant name -->
      <v-row>
        <v-col sm="12" cols="12">
          <v-text-field
            v-model="merchant.name"
            outlined
            autocomplete="new-password"
            :label="$t('MERCHANT_NAME')"
            :disabled="isReadOnlyUser || isReadOnly"
            :class="setNoValueClass(merchant.name)"
            :messages="setMessages(merchant.name)"
            @blur="(e) => updateMerchant('name', e.target.value)"
          />
        </v-col>
      </v-row>
      <!-- Merchant address -->
      <v-row>
        <v-col sm="8" cols="12">
          <v-text-field
            v-model="merchant.city"
            outlined
            autocomplete="new-password"
            :label="$t('LABEL_CITY')"
            :disabled="isReadOnlyUser || isReadOnly"
            :class="setNoValueClass(merchant.city)"
            :messages="setMessages(merchant.city)"
            @blur="(e) => updateMerchant('city', e.target.value)"
          />
        </v-col>
        <v-col sm="4" cols="12">
          <v-text-field
            v-model="merchant.zipCode"
            outlined
            autocomplete="new-password"
            :label="$t('ZIP_CODE')"
            :disabled="isReadOnlyUser || isReadOnly"
            :class="setNoValueClass(merchant.zipCode)"
            :messages="setMessages(merchant.zipCode)"
            @blur="(e) => updateMerchant('zipCode', e.target.value)"
          />
        </v-col>
        <v-col sm="8" cols="12">
          <v-text-field
            v-model="merchant.street"
            outlined
            autocomplete="new-password"
            :label="$t('ADDRESS')"
            :disabled="isReadOnlyUser || isReadOnly"
            :class="setNoValueClass(merchant.street)"
            :messages="setMessages(merchant.street)"
            @blur="(e) => updateMerchant('street', e.target.value)"
          />
        </v-col>
        <v-col sm="4" cols="12">
          <v-text-field
            v-model="merchant.streetNo"
            outlined
            autocomplete="new-password"
            :label="$t('STREET_NUMBER')"
            :disabled="isReadOnlyUser || isReadOnly"
            :class="setNoValueClass(merchant.streetNo)"
            :messages="setMessages(merchant.streetNo)"
            @blur="(e) => updateMerchant('streetNo', e.target.value)"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.merchantNumber {
  :global(.v-input__append-inner) {
    align-self: center;
  }
}
</style>
