<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import {
  expenseModalMethods,
  expenseModalComputed,
} from '@state/helpers/expense-modal'
import { pick } from 'lodash'
import ExpenseCountryDropdown from '@components/expense-country-dropdown/expense-country-dropdown'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
  components: {
    FilePond,
    ExpenseCountryDropdown,
  },

  data() {
    return {
      receiptFiles: [],
      shouldShowCountrySelect: false,
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['merchant']),
  },

  methods: {
    ...pick(expenseModalMethods, [
      'uploadReceiptImage',
      'updateMerchantCountry',
      'updateCountry',
    ]),
    async onAddFile() {
      const { file } = this.$refs.pond.getFiles()[0]
      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
      await delay(750) // delay to give the user time to see the receipt preview

      this.uploadReceiptImage({ file })
    },
    onCountrySelect(value) {
      this.updateMerchantCountry(value)
      this.updateCountry(value)
      // keep the dropdown visible after a country has been set
      this.shouldShowCountrySelect = true
    },
  },
}
</script>

<template>
  <div :class="$style.receiptUploadWrapper">
    <div :class="$style.uploadIcon">
      <img src="~@/src/assets/svg/upload-scan.svg" />
    </div>
    <h2>{{ $t('UPLOAD_SCAN') }}</h2>
    <p :class="$style.uploadInfo">
      {{
        $t(
          merchant.country ? 'UPLOAD_SCAN_INFO' : 'UPLOAD_SCAN_INFO_NO_COUNTRY'
        )
      }}
    </p>
    <ExpenseCountryDropdown
      v-if="!merchant.country || shouldShowCountrySelect"
      :value="merchant.country"
      :on-select="onCountrySelect"
      :class="$style.dropdown"
      :placeholder="$t('SELECT_COUNTRY')"
    />
    <div v-if="merchant.country" :class="$style.uploadWrapper">
      <FilePond
        ref="pond"
        :label-idle="$t('DROP_FILE_OR_BROWSE')"
        accepted-file-types="image/jpeg, image/png, application/pdf"
        :allow-multiple="false"
        :files="receiptFiles"
        instant-upload="false"
        @addfile="onAddFile"
      />
      <p :class="$style.allowed">
        {{ $t('RECEIPT_UPLOAD_ALLOWED_MIME_TYPES') }}
      </p>
    </div>
  </div>
</template>

<style src="./filepond.css"></style>
<style src="./filepond-plugin-image-preview.css"></style>
<style lang="scss" module>
@import '@design';

:global(.filepond--wrapper) {
  width: 100%;
}
:global(fieldset.filepond--data) {
  border: none;
}

.receiptUploadWrapper {
  position: absolute;
  top: 58px;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 58px);
  max-height: 100%;
  padding: 0 70px 18%;
  background: $light-grey;

  .uploadWrapper {
    width: 100%;
  }
  // animation for: the filepond component

  :global(.filepond--root) {
    opacity: 0;
    animation: 0.85s cubic-bezier(0.22, 1.02, 0.22, 1.02) 0.55s forwards
      'fadePop';
  }
  :global(.filepond--panel-root) {
    background-color: #e1e7ee !important;
  }
  :global(.filepond--root:hover .filepond--panel-root) {
    cursor: pointer;
    background-color: #c5d1de !important;
  }

  .uploadIcon {
    width: 80px;
    height: 65px;
    // animation for: upload icon and text
    opacity: 0;
    animation: 0.85s cubic-bezier(0.22, 1.02, 0.22, 1.02) 0.55s forwards
      'fadePop';
  }
  h2 {
    margin: 20px 0 8px;
    font-size: 32px;
    font-weight: 900;
    color: $dark;
    opacity: 0;
    animation: 0.85s cubic-bezier(0.22, 1.02, 0.22, 1.02) 0.55s forwards
      'fadePop';
  }
  p {
    margin: 5px 0 20px 0;
    font-size: 12px;
    color: $grey;
    opacity: 0;
    animation: 0.85s cubic-bezier(0.22, 1.02, 0.22, 1.02) 0.55s forwards
      'fadePop';
  }

  .dropdown {
    flex: 0 0 auto;
    width: 100%;
    margin: 30px 0;
    opacity: 0;
    animation: 0.85s cubic-bezier(0.22, 1.02, 0.22, 1.02) 0.55s forwards
      'fadePop';
  }

  .uploadInfo {
    width: 80%;
  }
}
</style>
