import { isNumber, intersection, difference, isEqual } from 'lodash'

export const formatLocalSplitToApiSplit = (moduleSplit) => {
  return {
    amount_ex_vat: moduleSplit.amountExclVat,
    amount_incl_vat: moduleSplit.amountInclVat,
    id: moduleSplit.id,
    scac_information: moduleSplit.scacInformation,
    scac_major: moduleSplit.scac.major, // TODO: Scac keys from GET /expenses are called 'major' and over here 'scac_major'. Get BE guys to make them consistent.
    scac_minor: moduleSplit.scac.minor,
    next_question_id: moduleSplit.nextQuestionId,
    scac_patch: moduleSplit.scac.patch,
    scac_source: moduleSplit.scacSource,
    scac_top_level_category: moduleSplit.category.key, // TODO: Probably unnecessary but BE expects it
    top_level_category: moduleSplit.category.key,
    vat_amount: moduleSplit.vatAmount,
    vat_refund: moduleSplit.vatRefund,
    scac_type: moduleSplit.scacType,
    text_reply: moduleSplit.scacTextReply,
    scac_description: moduleSplit.scacDescription,
    maximum_reclaim_amount: moduleSplit.maximumReclaimAmount,
  }
}

// Format split as received from the API in accordance to what components
// expect. If no argument is provided, return empty split.
export const formatApiSplitToLocalSplit = (apiSplit = {}) => {
  return {
    amountExclVat: isNumber(apiSplit.amount_ex_vat)
      ? apiSplit.amount_ex_vat
      : null,
    amountInclVat: isNumber(apiSplit.amount_incl_vat)
      ? apiSplit.amount_incl_vat
      : null,
    id: apiSplit.id || null,
    scacInformation: apiSplit.information || null,
    scacDescription: apiSplit.scac_description || null,
    scac: {
      major: apiSplit.major || null,
      minor: apiSplit.minor || null,
      patch: apiSplit.patch || null,
    },

    scacTextReply: apiSplit.text_reply,
    scacSource: apiSplit.scac_source || null,
    scacType: apiSplit.scac_type,
    scacTopLevelCategory: apiSplit.scac_top_level_category || null, // TODO: Remove this?
    maximumReclaimAmount: apiSplit.maximum_reclaim_amount || null,
    category: {
      key: apiSplit.scac_top_level_category || null,
    },
    nextQuestionId: apiSplit.next_question_id || null,
    vatAmount: isNumber(apiSplit.vat_amount) ? apiSplit.vat_amount : null,
    vatRefund: isNumber(apiSplit.vat_refund) ? apiSplit.vat_refund : 100,
    categoryTemporary: null,
    vatPercent: null,
  }
}

/**
 * This helper function compares the api expense and the local expense and
 * returns an object with the following properties:
 * * add - An array of split objects to add
 * * update - An array of split objects to update
 * * remove - An array of split ids to delete
 */
export const compareSplits = ({
  apiSplits: apiSplitsFormatted, // Just renaming here, they are already formatted
  localSplits,
}) => {
  const localSplitsFormatted = localSplits.map(formatLocalSplitToApiSplit)

  const apiSplitIds = apiSplitsFormatted.map((split) => split.id)

  const localSplitsIds = localSplitsFormatted
    .map((split) => split.id)
    .filter(Boolean)

  // Add splits that have no id
  const add = localSplitsFormatted.filter((split) => !split.id)

  // Update splits that have changed
  const update = intersection(apiSplitIds, localSplitsIds) // Ids of splits that are in both api and local
    .map((splitId) => {
      const localSplit = localSplitsFormatted.find(
        (split) => split.id === splitId
      )
      const apiSplit = apiSplitsFormatted.find((split) => split.id === splitId)

      return !isEqual(localSplit, apiSplit) ? localSplit : null // This will be filtered out
    })
    .filter(Boolean) // Only keep truthy values
  // Remove splits that is only in api
  const remove = difference(apiSplitIds, localSplitsIds)
  return { add, update, remove }
}

// This is a helper function that takes a split as we got it from GET, and
// returns a split object ready for PATCH.
export const formatApiSplitForPatch = (apiSplit) => {
  return {
    id: apiSplit.id || null,

    amount_ex_vat: apiSplit.amount_ex_vat || null,
    amount_incl_vat: apiSplit.amount_incl_vat || null,
    vat_amount: apiSplit.vat_amount || null,
    vat_refund: apiSplit.vat_refund || null,

    scac_top_level_category: apiSplit.scac_top_level_category || null,
    top_level_category: apiSplit.scac_top_level_category || null,

    scac_information: apiSplit.information || null,
    scac_description: apiSplit.scac_description || null,
    next_question_id: apiSplit.next_question_id || null,
    scac_source: apiSplit.scac_source || null,

    scac_major: apiSplit.major || null,
    scac_minor: apiSplit.minor || null,
    scac_patch: apiSplit.patch || null,
    text_reply: apiSplit.text_reply || null,
    scac_type: apiSplit.scac_type || null,
    maximum_reclaim_amount: apiSplit.maximum_reclaim_amount || null,
  }
}
