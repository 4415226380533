<script>
import { expenseModalComputed } from '@state/helpers/expense-modal'
import i18n from '@src/i18n'
import { pick } from 'lodash'

export default {
  props: {
    value: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: i18n.t('LABEL_COUNTRY'),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: () => null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    messages: {
      type: [String, Array],
      default: () => [],
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => null,
    },
  },
  computed: {
    ...pick(expenseModalComputed, ['getCountries']),
    countries() {
      const countries = this.getCountries

      const countriesWithNames = countries.map((item) => {
        return {
          ...item,
          name: i18n.t(`ALPHA3_COUNTRY.${item.code}`),
        }
      })

      return countriesWithNames.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })
    },
  },
  methods: {
    loadFlagImage(code) {
      try {
        return require(`../../assets/flags/${code}.svg`)
      } catch (error) {
        return require(`../../assets/flags/un.svg`)
      }
    },
  },
}
</script>

<template>
  <v-autocomplete
    class="bulky forced"
    outlined
    item-text="name"
    item-value="code"
    :items="countries"
    :label="label"
    :value="value"
    :menu-props="{ offsetY: true }"
    :multiple="false"
    :auto-select-first="true"
    :disabled="disabled"
    :rules="rules"
    :messages="messages"
    :error-messages="errorMessages"
    :placeholder="placeholder"
    @change="(countryCode) => onSelect(countryCode)"
  >
    <template v-slot:prepend-inner>
      <img
        v-if="!value"
        :src="loadFlagImage('un')"
        width="20"
        :class="$style.country"
        alt="country"
      />
      <img
        v-else
        :src="loadFlagImage(value)"
        width="20"
        :class="$style.country"
        alt="country"
      />
    </template>
  </v-autocomplete>
</template>

<style lang="scss" module>
@import '@design';
.country {
  width: 26px;
  margin: 5px 13px 0 6px;
}
</style>
