import request from '@utils/apiRequest'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'
import { saveStateSession, getSavedStateSession } from '@utils/storage'

export const state = {
  users: [],
  userDetails: {},
  updatePhoto: false,
  loading: false,
  photos: {},
  componentKey: false,
}

export const mutations = {
  SET_USERS(state, response) {
    state.users = response.data.content
  },
  SET_USER_DETAILS(state, details) {
    state.userDetails = details
  },
  SET_UPDATE_PHOTO(state, userId) {
    state.updatePhoto = userId
  },
  SET_LOADING(state, bool) {
    state.loading = bool
  },
  SET_USER_IMAGE(state, { image, id }) {
    state.photos[id] = image
  },
  TOGGLE_KEY(state) {
    state.componentKey = !state.componentKey
  },
}

export const getters = {
  users(state) {
    return state.users
  },
  photos(state) {
    return state.photos
  },
}

export const actions = {
  // Fetches user list for a given enterprise
  getUsers({ commit, dispatch, state }, enterpriseId) {
    commit('SET_LOADING', true)
    return request
      .get(`principals/list`, {
        params: {
          enterprise: enterpriseId,
        },
      })
      .then((response) => {
        response.data.content.forEach((user) => {
          // Fetch an image only if it's not already there or it needs to be updated
          if (
            (user.imageContent && !state.photos[user.id]) ||
            state.updatePhoto === user.id
          ) {
            dispatch('getUserImage', user)
            if (state.updatePhoto === user.id) {
              commit('SET_UPDATE_PHOTO', null)
            }
          }
        })
        commit('SET_LOADING', false)
        commit('SET_USERS', response)
      })
      .catch((error) => {
        console.log(error)
        commit('SET_LOADING', false)
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: i18n.t('FETCHING_USERS_FAILED'),
          type: 'error',
        })
      })
  },
  getUserImage({ commit }, user) {
    return request
      .get(`principals/${user.id}/image`, { responseType: 'blob' })
      .then((response) => {
        const reader = new window.FileReader()
        reader.readAsDataURL(new Blob([response.data]))
        reader.onload = () => {
          commit('SET_USER_IMAGE', {
            image: reader.result,
            id: user.id,
          })
          commit('TOGGLE_KEY')
          const sessionUser = getSavedStateSession('user')
          const sessionUserId = sessionUser ? sessionUser.id : null
          // Case when picture fetched is that of the logged in user
          if (user.id === sessionUserId) {
            saveStateSession('auth.userPhoto', reader.result)
            commit('general/TOGGLE_USER_MENU_KEY', null, { root: true }) // Rerender user header
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
  // Add user to enterprise
  addUser({ dispatch, commit }, payload) {
    commit('SET_LOADING', true)
    return request
      .post('principals', {
        body: payload,
        addAcceptLanguage: true,
      })
      .then((response) => {
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        dispatch('getUsers', payload.enterprise_id)
        eventBus.$emit('growl', {
          title: 'Success',
          description: i18n.t('USER_ADDED'),
          type: 'success',
        })
      })
      .catch((error) => {
        console.log(error)
        commit('SET_LOADING', false)
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        eventBus.$emit('growl', {
          title: 'Oops:',
          description:
            error.message === 'Request failed with status code 403'
              ? i18n.t('USER_ALREADY_EXISTS')
              : i18n.t('ERROR_CREATING_USER'),
          type: 'error',
        })
      })
  },
  // Edit user
  editUser({ dispatch, commit }, payload) {
    commit('SET_LOADING', true)
    return request
      .patch('principals', { body: payload })
      .then((response) => {
        commit('SET_LOADING', false)
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        dispatch('getUsers', payload.enterpriseId)
        eventBus.$emit('growl', {
          title: 'Success',
          description: i18n.t('USER_UPDATED'),
          type: 'success',
        })
        const sessionUser = getSavedStateSession('user')
        if (payload.id === sessionUser.id) {
          saveStateSession('user', {
            name: `${response.data.first_name} ${response.data.last_name}`,
            id: response.data.id,
          })

          commit('general/TOGGLE_USER_MENU_KEY', null, { root: true }) // Rerender user header
        }
      })
      .catch((error) => {
        console.log(error)
        commit('SET_LOADING', false)
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: i18n.t('ERROR_UPDATING_USER'),
          type: 'error',
        })
      })
  },
  // Forgot password
  passwordResetEmail({ commit }, { email, context }) {
    commit('SET_LOADING', true)
    return request
      .post('forgotPassword', {
        body: { email },
        addAcceptLanguage: true,
      })
      .then(() => {
        commit('SET_LOADING', false)
        eventBus.$emit('growl', {
          title: 'Success',
          description:
            context === 'passwordReset'
              ? i18n.t('PASSWORD_RESET_EMAIL_SENT') // For active users
              : i18n.t('WELCOME_EMAIL_SUCCESSFULLY_RESENT'), // For users pending activation
          type: 'success',
        })
      })
      .catch((error) => {
        console.log(error)
        commit('SET_LOADING', false)
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        eventBus.$emit('growl', {
          title: 'Oops:',
          description:
            context === 'passwordReset'
              ? i18n.t('ERROR_SENDING_PASSWORD_RESET_EMAIL') // For active users
              : i18n.t('ERROR_SENDING_WELCOME_EMAIL'), // For users pending activation
          type: 'error',
        })
      })
  },
  deleteUser({ commit, dispatch }, payload) {
    return request
      .delete(`principals/${payload.uuId}`)
      .then(() => {
        eventBus.$emit('growl', {
          title: 'Success:',
          description: i18n.t('ADMINISTRATOR_SUCCESSFULLY_DELETED'),
          type: 'success',
        })
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        dispatch('getUsers', payload.enterpriseId)
      })
      .catch((error) => {
        console.log(error)
        commit('app/SET_CURRENT_MODAL', null, { root: true })
        eventBus.$emit('growl', {
          title: 'Oops:',
          description: i18n.t('ERROR_DELETING_USER'),
          type: 'error',
        })
      })
  },
}
