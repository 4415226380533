<script>
import ScacInfo from '@components/expense-scac-info-item/expense-scac-info-item'
import { pick } from 'lodash'
import { expenseModalComputed } from '@state/helpers/expense-modal'
export default {
  components: {
    ScacInfo,
  },
  props: {
    splitIndex: { type: Number, default: () => null },
  },
  computed: {
    ...pick(expenseModalComputed, [
      'getScacTextReply',
      'getScacInfo',
      'getHasScacTypeManual',
    ]),
    text() {
      return this.getScacTextReply(this.splitIndex)
    },
    info() {
      return this.getScacInfo(this.splitIndex)
    },
    infoItems() {
      return [
        {
          label: this.$i18n.t(
            this.getHasScacTypeManual ? 'MANUAL_PROCESSING' : 'INFO'
          ),
          content: this.info,
          manualProcessing: this.getHasScacTypeManual,
        },
        { label: this.$i18n.t('USER_INPUT'), content: this.text },
      ].filter((item) => item.content)
    },
  },
}
</script>

<template>
  <ScacInfo v-if="text || info" :items="infoItems" />
</template>
