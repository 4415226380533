import { mapState, mapActions, mapMutations } from 'vuex'

export const expensesByCountryComputed = {
  ...mapState('expensesByCountry', {
    year: (state) => state.year,
    sort: (state) => state.sort,
    order: (state) => state.order,
    expenses: (state) => state.expenses,
    categorySummary: (state) => state.categorySummary,
    summary: (state) => state.summary,
    pagination: (state) => state.pagination,
    category: (state) => state.category,
    status: (state) => state.status,
    selectedExpenses: (state) => state.selectedExpenses,
    categoryOverview: (state) => state.categoryOverview,
    reclaimedQuarters: (state) => state.reclaimedQuarters,
    createReclaimInProgress: (state) => state.createReclaimInProgress,
    reclaimedQuartersInProgress: (state) => state.reclaimedQuartersInProgress,
    categoryOverviewInProgress: (state) => state.categoryOverviewInProgress,
    getSummaryInProgress: (state) => state.getSummaryInProgress,
    getExpensesByCountryInProgress: (state) =>
      state.getExpensesByCountryInProgress,
    moveExpensesInProgress: (state) => state.moveExpensesInProgress,
  }),
  ...mapState('general', {
    categories: (state) => state.categories,
  }),
  ...mapState('reclaim', {
    reclaimLimits: (state) => state.reclaimLimits,
  }),
  ...mapState('enterprise', {
    enterpriseId: (state) => state.currentEnterprise.id,
    enterpriseVatRate: (state) => state.currentEnterprise.vat_rate,
  }),
}

export const expensesByCountryMethods = {
  ...mapActions('expensesByCountry', [
    'resetExpensesByCountry',
    'getSummary',
    'getExpenses',
    'getCategoriesOverview',
    'getReclaimedQuarters',
    'createReclaim',
    'moveExpenses',
    'setYear',
    'setCountry',
    'setReclaimedQuarters',
    'setSummary',
    'setCategory',
    'setSelectedExpenses',
    'deselectExpenses',
    'setPagination',
    'setPage',
    'setStatus',
    'setSort',
  ]),
  ...mapActions('general', ['getCategories', 'resetModalData', 'setModalData']),
  ...mapActions('vatRates', ['fetchVatRatesForCountry']),
  ...mapActions('reclaim', ['fetchReclaimLimits']),
}

export const expensesByCountryMutations = {
  ...mapMutations('expensesByCountry', [
    'SET_CATEGORY',
    'SET_COUNTRY',
    'SET_YEAR',
    'SET_SUMMARY',
    'SET_PAGINATION',
    'SET_STATUS',
    'SET_SELECTED_EXPENSES',
    'SET_RECLAIMED_QUARTERS',
    'SET_SUMMARY',
    'SET_RECLAIM_BUTTON_DISABLED',
    'SET_CATEGORY_OVERVIEW',
    'SET_SORT',
  ]),
  ...mapMutations('general', ['SET_MODAL_DATA']),
}
