<script>
import { requiredValidation } from '@utils/inputValidation'
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import Icons from '@utils/categoryIcons'
import { pick } from 'lodash'
import EventBus from '@src/event-bus'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
    disabled: { type: Boolean, default: false },
  },

  data: () => ({
    icons: Icons,
    split: { index: null, category: {} },
    categoryRules: [requiredValidation],
    unwatchStore: () => {},
  }),

  computed: {
    ...pick(expenseModalComputed, [
      'isReadOnly',
      'isNewExpense',
      'country',
      'categories',
      'enterpriseId',
      'getCategory',
    ]),
  },

  created() {
    // Start watching Vuex store
    this.unwatchStore = this.$store.watch(
      // Reactively watch this functions return value
      (state, getter) => {
        const index = this.splitIndex
        const category = this.getCategory(index) || {}

        // Return a primitive value
        return JSON.stringify({ index, category })
      },

      // Call this function when the value changes
      (json) => {
        this.split = JSON.parse(json)
      },

      // Options
      { immediate: true }
    )
  },

  beforeDestroy() {
    this.unwatchStore()
  },

  methods: {
    ...pick(expenseModalMethods, [
      'updateCategory',
      'removeTemporaryCategory',
      'setScacWizardSplitIndex',
    ]),
    updateExpenseCategory(key) {
      const category = this.$store.getters['categories/getCategory']({ key })

      // Update the category and marking the change as temporary. We do that
      // because the user might abort the scac wizard, and in that case we want
      // to revert to the previous category.
      this.updateCategory({
        category,
        updateIsTemporary: true,
        splitIndex: this.split.index,
      })

      // TODO: Should we also log split index?
      this.$amplitude.getInstance().logEvent('UPDATE_CATEGORY', {
        enterpriseId: this.enterpriseId,
        category: category.name,
      })

      // Write split index in vuex store so the wizard is aware of it
      this.setScacWizardSplitIndex(this.splitIndex)

      // Emit an event that we want to open the wizard. Also set up a listener
      // and a callback
      EventBus.$emit('open-scac-wizard', {
        // When this event is emitted (happens when the wizard closes) ...
        event: 'expense-modal-state-changed-to-editor',
        // ...call this function!
        callback: () => {
          // We want to remove the temporary category
          this.removeTemporaryCategory({ splitIndex: this.split.index })
        },
      })
    },
  },
}
</script>

<template>
  <div>
    <v-select
      :label="$t('CATEGORY')"
      class="bulky"
      outlined
      :value="split.category.key"
      :items="categories"
      item-value="key"
      item-text="name"
      :menu-props="{ offsetY: true }"
      :rules="categoryRules"
      :disabled="disabled || isReadOnly || (isNewExpense && !country)"
      @change="updateExpenseCategory"
    >
      <template v-slot:prepend-inner>
        <v-icon v-if="!split.category.key" :class="$style.categoryIcon"
          >mdi-category__no_category</v-icon
        >
        <v-icon v-else>{{ icons[split.category.key] }}</v-icon>
      </template>
    </v-select>

    <div v-if="isNewExpense && !country" :class="$style.warningMessage">
      {{ $t('NO_COUNTRY_SELECTED') }}
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.warningMessage {
  margin-left: 10px;
  font-size: 14px;
  color: $orange;
}
.categoryIcon {
  margin: 5px 13px 0 6px;
}
</style>
