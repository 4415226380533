var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{class:{
    [_vm.$style.hasFocus]: _vm.isFocused,
    [_vm.$style.hasError]: _vm.inputState.hasError,
    [_vm.$style.isValid]: _vm.inputState.isValid,
    noValue: _vm.inputState.hasNoValue,
    bulky: true,
    forced: true,
    SCAC: true,
  },attrs:{"label":_vm.$t('SCAC_CODE'),"outlined":"","messages":_vm.messages,"append-icon":!_vm.isReadOnly && _vm.split.category.key ? 'mdi-chat' : '',"required":"","disabled":_vm.disabled || _vm.isReadOnly || !_vm.country,"error-messages":_vm.errorMessage,"placeholder":"0.0.0"},on:{"click:append":_vm.openWizard,"focus":_vm.onFocus,"blur":_vm.onBlur},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',{class:_vm.$style.icon},[_vm._v("e")])]},proxy:true}]),model:{value:(_vm.split.scacCode),callback:function ($$v) {_vm.$set(_vm.split, "scacCode", $$v)},expression:"split.scacCode"}})
}
var staticRenderFns = []

export { render, staticRenderFns }