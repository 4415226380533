/**
 * Function that starts watching the vuex store in order to react to specific changes
 * @param {{}} store The vuex store
 */

export const startWatchingStoreChanges = (store) => {
  // https://webpack.js.org/guides/dependency-management/#require-context
  const requireWatchers = require.context(
    // Look for files in the current directory
    '.',
    // Also look in subdirectories
    true,
    // Include any .js files that are not this file or a unit test.
    /^((?!index|\.unit\.).)*\.js$/
  )

  // For each matching file name...
  requireWatchers.keys().forEach((fileName) => {
    // Get the watch function
    const watcherConfig = requireWatchers(fileName)

    const watch = watcherConfig.default || watcherConfig

    watch(store)
  })
}
