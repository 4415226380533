/**
 * This watcher will trigger fetching new vat-rates
 */

export default (store) => {
  return store.watch(
    // Reactively watch this functions return value
    (state, getters) => {
      return [
        getters['expensesByCountry/getCountry'],
        getters['expenseModal/getCountry'],
      ]
        .filter(Boolean) // Remove falsey values
        .join(',') // Join in order to make a simple value
    },

    // Call this function when the value changes
    (countries) => {
      if (countries) {
        // Foreach country in the array, fetch the vat-rates
        countries.split(',').forEach((country) => {
          store.dispatch(
            'vatRates/fetchVatRatesForCountry',
            { code: country },
            { root: true }
          )

          // TODO: Remove this later when no component uses vat rates from general
          store.dispatch('general/getVatRates', country, { root: true })
        })
      }
    },

    // Options
    { immediate: true }
  )
}
