<script>
import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import {
  requiredValidation,
  positiveNumber,
  minNumber,
} from '@utils/inputValidation'
import { pick, isNumber, isNaN } from 'lodash'
import i18n from '@src/i18n'
import eventBus from '@src/event-bus'
import { calculateVatValues } from '@utils/vattax-math'

export default {
  props: {
    splitIndex: { type: Number, default: () => null },
    disabled: { type: Boolean, default: false },
  },

  data: () => {
    return {
      split: {
        index: null,
        amountInclVat: null,

        // If this component lives inside a non-base-split, it can not exceed
        // the total amount. maxAmount will hold the maxium value this field may
        // have without triggering a validation error
        maxAmount: null,
        maxAmountExceeded: false,
      },
      isEditing: false,
      unwatchStore: () => {},
    }
  },

  computed: {
    ...pick(expenseModalComputed, [
      'isReadOnly',
      'splits',
      'getAmountInclVat',
      'currency',
      'getVatRate',
    ]),

    isBaseSplit() {
      return !isNumber(this.split.index)
    },

    isNormalSplit() {
      return !this.isBaseSplit
    },

    expenseHasSplits() {
      return this.splits.length > 0
    },

    isDisabled() {
      return (
        this.disabled ||
        this.isReadOnly ||
        (this.isBaseSplit && this.expenseHasSplits)
      )
    },

    maxAmountValidation() {
      return this.split.maxAmountExceeded
        ? i18n.t('VALUE_IN_SPLIT_TOO_LARGE', {
            interpolation: false,
            label: i18n.t('AMOUNT_INCL_VAT'),
            value: this.split.maxAmount,
          })
        : true
    },

    isShowingSumOfSplitsWarning() {
      return (
        this.isBaseSplit &&
        this.expenseHasSplits &&
        this.split.amountInclVat < 0
      )
    },
    validationRules() {
      if (this.isNormalSplit) {
        return [requiredValidation, positiveNumber, this.maxAmountValidation]
      } else {
        return [requiredValidation, (v) => minNumber(v, 0)]
      }
    },
  },

  created() {
    // Start watching Vuex store
    this.unwatchStore = this.$store.watch(
      // Reactively watch this functions return value
      (state) => {
        const index = this.splitIndex

        const totalAmountInclVat = this.getAmountInclVat()
        const sumOfAmountInclVatForOtherSplits = this.splits.reduce(
          (sum, split, idx) => {
            if (idx !== index) {
              return sum + (this.getAmountInclVat(idx) || 0)
            } else {
              return sum
            }
          },
          0
        )
        const minimumSplitAmount = 0.01
        const maxAmount = isNumber(index)
          ? totalAmountInclVat -
            sumOfAmountInclVatForOtherSplits -
            minimumSplitAmount
          : null
        const maxAmountExceeded = isNumber(index)
          ? this.split.amountInclVat > maxAmount
          : false

        const split = { index, maxAmount }

        // Input field can be in edit mode or not. If user is editing this
        // split, we don't touch the amount
        if (!this.isEditing) {
          // The split can be either a base or normal split
          if (this.isNormalSplit) {
            const amountInclVat = this.getAmountInclVat(index)
            split.amountInclVat = amountInclVat && amountInclVat.toFixed(2)
          }

          // When the split is a base split, we take the amount from the base
          // and subtract the sum of all splits
          if (this.isBaseSplit) {
            const baseAmount = this.getAmountInclVat()
            const baseVatRate = this.getVatRate()

            const sumOfAmountFromAllSplits = this.splits.reduce(
              (sum, { amountInclVat }) => sum + amountInclVat,
              0
            )

            split.amountInclVat =
              sumOfAmountFromAllSplits !== 0
                ? (baseAmount - sumOfAmountFromAllSplits).toFixed(2)
                : baseAmount && baseAmount.toFixed(2)

            // if amount incl vat changes due to new split, we need to
            // update vat amount accordingly
            const { vatAmount } = calculateVatValues({
              amountInclVat: +split.amountInclVat,
              vatPercent: baseVatRate / 100,
            })

            eventBus.$emit('vatAmountChanged', vatAmount)
          }
        }

        // If we are editing, we want to update the maxAmountExceeded value. But
        // we also want to update it if we are not editing AND max amount isn't
        // exceeded anymore
        if (this.isEditing || (!this.isEditing && !maxAmountExceeded)) {
          split.maxAmountExceeded = maxAmountExceeded
        }

        // Return a primitive value
        return JSON.stringify(split)
      },

      // Call this function when the value changes
      (json) => {
        this.split = { ...this.split, ...JSON.parse(json) }
      },

      // Options
      { immediate: true }
    )
  },

  beforeDestroy() {
    this.unwatchStore()
  },

  methods: {
    ...pick(expenseModalMethods, ['updateAmountInclVat']),

    onChangeAmountInclVat(input) {
      const value = parseFloat(input.target.value)
      const amountInclVat = isNumber(value) && !isNaN(value) ? value : null

      this.updateAmountInclVat({
        value: amountInclVat,
        splitIndex: this.split.index,
      })
    },

    preventKeyCode(event) {
      if (event.keyCode === 69) {
        // We prevent 'e' because it can be used to express large
        // numbers. E.g. 1000 = 1e3.
        event.preventDefault()
      }
    },
  },
}
</script>

<template>
  <div>
    <v-text-field
      v-model="split.amountInclVat"
      :label="$t('AMOUNT_INCL_VAT')"
      outlined
      type="number"
      placeholder="0"
      :rules="validationRules"
      :disabled="isDisabled"
      :suffix="currency"
      :class="$style.amountInclVat"
      @focus="isEditing = true"
      @blur="isEditing = false"
      @keyup="onChangeAmountInclVat"
      @keydown="preventKeyCode"
      @wheel.prevent
    />
    <div v-if="isShowingSumOfSplitsWarning" :class="$style.warningMessage">
      <p>{{ $t('SUM_OF_SPLITS_TOO_LARGE') }}</p>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.amountInclVat {
  :global(.v-text-field__suffix) {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  :global(.v-label:not(.v-label--active)) {
    right: 35px !important;
  }
}

.warningMessage {
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: $alert-error;
  }

  padding: 0 12px;
}
</style>
