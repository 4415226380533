/**
 * This watcher looks for changes in general.language
 */

import i18n from '@src/i18n'
import eventBus from '@src/event-bus'
import { saveStateSession } from '@utils/storage'

export default (store) => {
  return store.watch(
    // Reactively watch this functions return value
    (state, getters) => {
      return getters['general/language']
    },

    // Call this function when the value changes
    (language) => {
      i18n.locale = language
      store.dispatch('general/getCategories', {}, { root: true }) // TODO: Remove this when we delete getCategories from /general
      store.dispatch('categories/fetchCategories', {}, { root: true })
      saveStateSession('selectedLanguage', language)
      eventBus.$emit('languageChanged')
    },

    // Options
    {
      immediate: true,
    }
  )
}
