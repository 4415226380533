import request from '@utils/apiRequest'

export const list = async (query) => {
  try {
    const response = await request.get(`enterprises`, {
      params: {
        order: query.order,
        page: query.page,
        page_size: query.pageSize,
        search_query: query.searchQuery,
        sort: query.sort,
        enabled: true,
      },
    })
    // Tweak response.data a little to fit our needs
    response.data = {
      data: response.data.content,
      meta: {
        order: response.data.order,
        page: response.data.page,
        pageSize: response.data.page_size,
        sort: response.data.sort,
        totalResults: response.data.total_results,
      },
    }
    return response
  } catch ({ response }) {
    return response
  }
}

export const find = async ({ id }) => {
  try {
    const response = await request.get(`enterprises/${id}`)
    response.data = { data: response.data }
    return response
  } catch ({ response }) {
    return response
  }
}

export const update = async ({ id }, item) => {
  try {
    const response = await request.patch(`enterprises/${id}`, { body: item })
    response.data = { data: response.data }
    return response
  } catch ({ response }) {
    return response
  }
}

export const create = async (item) => {
  throw new Error('Create enterprise is not implemented')
}
