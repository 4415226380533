import request from '@utils/apiRequest'
import eventBus from '@src/event-bus'
import i18n from '@src/i18n'

export const state = {
  cache: {
    queries: {},
  },
  byNumber: {},
  byReceiptId: {},
}

export const getters = {
  getMerchantInfoByNumber: (state) => (number) => {
    return state.byNumber[number]
  },
  getMerchantInfoByReceiptId: (state) => (id) => {
    return state.byReceiptId[id]
  },
}

export const mutations = {
  ADD_MERCHANT_INFO(state, { queryId, query, response, success }) {
    const queryDetails = {
      createdAt: Date.now(),
      id: queryId,
      query,
      success,
      response,
    }

    state.cache.queries[queryId] = queryDetails

    if (success) {
      const { vatNumber, receiptId, lookupOnReceiptUpload } = query

      const merchantInfo = {
        name: response.merchantName,
        city: response.address.city,
        street: response.address.street,
        streetNo: response.address.streetNumber,
        zipCode: response.address.postalCode,
        number: response.merchantNumber,
      }

      if (lookupOnReceiptUpload) {
        state.byReceiptId[receiptId] = merchantInfo
      } else {
        state.byNumber[vatNumber] = merchantInfo
      }
    }
  },
}

export const actions = {
  async fetchMerchantInfo({ commit, state }, { query, useCache = true }) {
    const queryId = `ITEM_${JSON.stringify({
      number: query.vatNumber,
      country: query.country,
      receiptId: query.receiptId,
    })}`

    const { expenseId, vatNumber, country, receiptId, lookupOnReceiptUpload } =
      query

    // Check if we have cached this
    const cacheHit = useCache && state.cache.queries[queryId]
    if (!cacheHit || !cacheHit.success) {
      // Call the API
      const response = await request.post(`expenses/merchantlookup`, {
        body: {
          vat_number: vatNumber,
          expense_id: lookupOnReceiptUpload ? null : expenseId, // remove id for automatic lookup so it doesn't look for expense data in the background
          country_code: country,
          receipt_id: receiptId,
          max_results: 1,
        },
      })

      if (response.data[0]) {
        // Handle the response
        commit('ADD_MERCHANT_INFO', {
          queryId,
          query,
          response: response.data[0],
          success: response.statusOK,
        })
      } else {
        eventBus.$emit('growl', {
          title: 'Lookup failed',
          description: i18n.t(
            lookupOnReceiptUpload
              ? 'AUTOMATIC_LOOKUP_FAILED'
              : 'MANUAL_LOOKUP_FAILED'
          ),
          type: 'notice',
        })
      }
    }
    return { queryId }
  },
}
