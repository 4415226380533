<script>
import formatDate from '@utils/format-date'
import { expenseModalComputed } from '@state/helpers/expense-modal'
import { pick } from 'lodash'

export default {
  data() {
    return {
      merchantLogoUrl: require(`@assets/svg/fallback.svg`),
    }
  },
  computed: {
    ...pick(expenseModalComputed, ['originalPointOfSale', 'merchant']),
    formattedDate() {
      return formatDate(this.originalPointOfSale.date)
    },
  },

  watch: {
    'merchant.logoUrl': {
      handler(newValue) {
        if (newValue) {
          this.merchantLogoUrl = this.merchant.logoUrl
        }
      },
    },
  },
}
</script>

<template>
  <div>
    <h2 class="expenseTitle">{{ $t('POINT_OF_SALE_TITLE') }}</h2>
    <div :class="$style.CatWrap">
      <div :class="$style.merchant">
        <div :class="$style.logo">
          <img :src="merchantLogoUrl" />
        </div>
      </div>
      <div :class="$style.info">
        <div :class="$style.big">
          <span>{{ originalPointOfSale.merchantName }}</span>
          <span :class="$style.right"
            >{{ this.$tn(originalPointOfSale.amountInclVat) }}
            {{ originalPointOfSale.currency }}</span
          >
        </div>

        <div :class="$style.small">
          {{ formattedDate }}
          <span :class="$style.right">{{
            $t(`ALPHA3_COUNTRY.${originalPointOfSale.country}`)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.CatWrap {
  display: flex;
  align-items: center;
  padding: 20px 30px 20px 20px;
  margin: 9px 0 0;
  color: $grey;
  background: #fff;
  border-radius: 4px;
  .merchant {
    .logo {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      overflow: hidden;
      text-align: center;
      background: $light-grey;
      background-position: center center;
      background-size: cover;
      border-radius: 999px;
      box-shadow: inset 0 0 10px 0 lighten($grey, 30%);
      img {
        width: 100%;
        height: 100%;
      }
      i {
        line-height: 50px;
      }
    }
  }
  .info {
    width: calc(100% - 50px);
    margin-top: 7px;
    .right {
      float: right;
    }

    .big {
      font-weight: 700;
      line-height: 14px;
      color: $dark;
      .merchantname {
        display: inline-block;
        width: 66%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right {
        text-align: right;
      }
    }
    .small {
      font-size: 12px;
      color: $dark;
      .right {
        text-align: right;
      }
    }
  }
}
</style>
