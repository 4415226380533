<script>
import ExpensePointOfSale from '@components/expense-sale-point/expense-sale-point'
import ExpenseTransaction from '@components/expense-transaction/expense-transaction'
import ExpenseMerchantInfo from '@components/expense-merchant-info/expense-merchant-info'
import ExpenseCountry from '@components/expense-country/expense-country'
import ExpenseSplits from '@components/expense-splits/expense-splits'
import EventBus from '@src/event-bus'
import { modalMethods as baseModalMethods } from '@state/helpers/modal'
import i18n from '@src/i18n'

import {
  expenseModalComputed,
  expenseModalMethods,
} from '@state/helpers/expense-modal'
import { pick } from 'lodash'
import { getSavedStateSession } from '@utils/storage'

export default {
  components: {
    ExpensePointOfSale,
    ExpenseTransaction,
    ExpenseMerchantInfo,
    ExpenseSplits,
    ExpenseCountry,
  },
  props: {
    scrollPosition: { type: Number, default: 0 },
    shouldValidateOnMount: { type: Boolean, default: false },
    baseSplitValidation: { type: Object, default: null },
  },
  data() {
    return {
      validSections: {
        splitBase: true, // We always have a base split
        transaction: true,
        merchant: true,
      },
      editorScrollPosition: 0,
      handleScrollToSplitEvent: () => {},
      handleOpenScacWizardEvent: () => {},
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...pick(expenseModalComputed, [
      'expenseKey',
      'isReadOnly',
      'isNewExpense',
      'isManuallyCreated',
      'expenseHasChanges',
      'receipt',
      'splits',
      'getHasScacTypeManual',
    ]),

    hasInvalidSections() {
      return Object.values(this.validSections).includes(false)
    },

    saveButtonIsDisabled() {
      if (this.isNewExpense) {
        return false
      }
      const hasNoReceipt = !this.receipt.id
      const hasNoChanges = !this.expenseHasChanges
      return (
        this.hasInvalidSections ||
        hasNoReceipt ||
        (hasNoChanges && !this.getHasScacTypeManual)
      )
    },
  },
  watch: {
    splits: {
      immediate: true,
      handler(splits) {
        const prefix = 'split_'

        // Create array of all split section ids
        const allSplitSectionIds = splits.map((_, idx) => `${prefix}${idx}`)

        // Create an array of old splits section ids (removed splits)
        const splitSectionIdsToRemove = Object.keys(this.validSections)
          .filter((id) => id.startsWith(prefix))
          .filter((id) => !allSplitSectionIds.includes(id))

        // Create an array of new split section ids
        const splitSectionIdsToAdd = allSplitSectionIds.filter(
          (id) => !Object.keys(this.validSections).includes(id)
        )

        // Remove & add ids from validSections
        splitSectionIdsToRemove.forEach((id) => {
          this.$set(this.validSections, id, undefined)
        })
        splitSectionIdsToAdd.forEach((id) => {
          this.$set(this.validSections, id, true)
        })
      },
    },
  },
  mounted() {
    // we get the information form the parent that we need to revalidate on mount
    if (this.shouldValidateOnMount) {
      EventBus.$emit('validate-fields')
    }
  },
  async created() {
    // Initially scroll to position specified by parent (expense-details)
    await this.$nextTick()
    this.scrollEditor({ y: this.scrollPosition, duration: 0 })

    this.handleScrollToSplitEvent = async ({ element }) => {
      await this.$nextTick()

      // Get the wrapper element
      const editor = this.$refs.expenseEditor
      // Pick scrollTop and clientHeight from the editor element
      // * scrollTop = the distance we have already scrolled
      // * clientHeight = the height of the visible part of the editor
      const { scrollTop, clientHeight } = editor
      // Find the bottom y value of the element
      const elementBottom = element.y + element.height
      // Calculate the distance between the editor bottom and the element
      // bottom. We need to scroll this distance!
      const scrollDeltaY = Math.abs(scrollTop + clientHeight - elementBottom)
      // If the bottom of the element is not visible, we want to scroll
      if (scrollTop + clientHeight < elementBottom) {
        // Add the scrollDelta to the current scroll. We also add 100 to give it
        // some air in the bottom
        const y = scrollTop + scrollDeltaY + 100
        this.scrollEditor({ y, duration: 300 })
      }
    }

    this.handleOpenScacWizardEvent = async ({ event, callback } = {}) => {
      await this.$nextTick()
      // Get the expenseEditor element
      const editor = this.$refs.expenseEditor
      this.$nextTick(() => {
        EventBus.$emit('change-expense-modal-state', {
          toState: 'scac-wizard',
          event,
          callback,
          scrollTop: editor.scrollTop,
        })
      })
    }
    // Listen for event and scroll to element if it's not in view
    EventBus.$on('scroll-expense-modal-editor', this.handleScrollToSplitEvent)
    // Listen for event and open scac wizard
    EventBus.$on('open-scac-wizard', this.handleOpenScacWizardEvent)
  },
  beforeDestroy() {
    EventBus.$off('scroll-expense-modal-editor', this.handleScrollToSplitEvent)
    EventBus.$off('open-scac-wizard', this.handleOpenScacWizardEvent)
    EventBus.$off('vatAmountChanged')
  },
  methods: {
    ...pick(baseModalMethods, ['setModalData', 'resetModalData']),
    ...pick(
      expenseModalMethods,
      'createExpense',
      'saveExpense',
      'changeScacTypeFromManualToCode'
    ),
    setValidSection(section) {
      const sections = this.validSections
      return function (bool) {
        sections[section] = bool
      }
    },
    saveChanges() {
      this.$amplitude.getInstance().logEvent('SAVE_EXPENSE')

      if (this.isNewExpense) {
        // if there are missing fields, we send that info to the parent
        // component. The parent component will hold the information if validation
        // is needed on mount, since this component is unmounted when scac wizard
        // is opened and the information about the required fields gets lost.
        this.$emit('setExpenseHasErrors', this.hasInvalidSections)

        // validate fields on button click
        EventBus.$emit('validate-fields')

        if (this.hasInvalidSections) {
          EventBus.$emit('growl', {
            title: 'Error:',
            description: this.$i18n.t('FORM_MISSING_REQUIRED_FIELDS'),
            type: 'error',
          })
        } else {
          this.createExpense()
          this.$emit('resetErrorStates')
        }
      } else {
        this.saveExpense()
        this.$emit('resetErrorStates')
      }
    },
    onManualReviewConfirm() {
      this.changeScacTypeFromManualToCode()
      this.saveChanges()
      this.resetModalData() // Close confirmation modal
    },
    onManualReviewSkip() {
      this.saveChanges()
      this.resetModalData() // Close confirmation modal
    },
    onSaveButtonClick() {
      if (this.getHasScacTypeManual) {
        // Show confirmation modal
        const modalData = {
          confirmAction: this.onManualReviewConfirm,
          secondBtnAction: this.onManualReviewSkip,
          declineAction: this.resetModalData,
          secondBtnVisible: true,
          isModalVisible: true,
          confirmText: i18n.t('MANUAL_PROCESSING_CONFIRM'),
          secondBtnText: i18n.t('MANUAL_PROCESSING_DECLINE'),
          title: i18n.t('MANUAL_PROCESSING', {
            status: i18n.t(this.nextStatus),
          }),
          content: this.$i18n.t('MANUAL_PROCESSING_DIALOGUE_TEXT'),
        }
        this.setModalData(modalData) // Open confirmation modal
      } else {
        this.saveChanges() // Save expense
      }
    },
    scrollEditor({ y, duration }) {
      const container = this.$refs.expenseEditor
      this.$vuetify.goTo(y, {
        duration: duration || 0,
        offset: 0,
        container,
      })
    },
  },
}
</script>

<template>
  <div ref="expenseEditor" :key="expenseKey" :class="$style.expenseEditor">
    <!-- Add -->
    <template v-if="isNewExpense">
      <ExpenseCountry />
      <ExpenseSplits
        :set-valid-section="setValidSection"
        :should-validate-on-mount="shouldValidateOnMount"
        :base-split-validation="baseSplitValidation"
      />
      <ExpenseTransaction :set-valid-section="setValidSection('transaction')" />
      <ExpenseMerchantInfo :set-valid-section="setValidSection('merchant')" />
    </template>

    <!-- Edit -->
    <template v-else>
      <ExpensePointOfSale v-if="!isManuallyCreated" />
      <ExpenseSplits
        :set-valid-section="setValidSection"
        :should-validate-on-mount="shouldValidateOnMount"
        :base-split-validation="baseSplitValidation"
      />
      <ExpenseCountry />
      <ExpenseTransaction :set-valid-section="setValidSection('transaction')" />
      <ExpenseMerchantInfo :set-valid-section="setValidSection('merchant')" />
    </template>

    <!-- Save button -->
    <div v-if="!isReadOnly && !isReadOnlyUser" :class="$style.fixedToBottom">
      <v-btn
        :class="['btn', 'primary-btn', 'preauth-btn', 'center', $style.btn]"
        text
        :disabled="saveButtonIsDisabled"
        @click="onSaveButtonClick"
        >{{ $t('SAVE_CLOSE_BUTTON') }}</v-btn
      >
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';
.expenseEditor {
  width: 100%;
  padding: 20px 30px 100px 10px;
  padding-bottom: 100px;
  margin-top: 0;
  overflow-y: scroll;
  text-align: left;
  :global(.v-input:not(.bulky) .theme--light.v-label) {
    background-color: $light-grey;
  }

  @include scrollbars;
}

.fixedToBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  padding: 20px 20px 40px;
  text-align: center;
  background-image: linear-gradient(
    180deg,
    rgba(242, 244, 246, 0) 0%,
    $light-grey 60%
  );
}
</style>
