import store from '@state/store'
import Router from '@src/router/index'
import i18n from '@src/i18n'

const COUNTDOWN_BEFORE_LOGOUT = 60000
const IDLE_TIME = 840000
const TOKEN_TIME = 240000

const countdownInterval = (duration, callback, title) => {
  let timeLeft = duration / 1000 // convert to seconds
  const logoutInterval = setInterval(() => {
    let minutes = parseInt(timeLeft / 60, 10)
    let seconds = parseInt(timeLeft % 60, 10)

    minutes = minutes < 10 ? `0${minutes}` : minutes
    seconds = seconds < 10 ? `0${seconds}` : seconds

    const remainingTime = `${minutes}:${seconds}`
    timeLeft -= 1
    document.title = `Time left: ${remainingTime}`

    if (timeLeft >= 0 && !store.getters['auth/isUserIdle']) {
      clearInterval(logoutInterval)
      document.title = title
    }
    if (timeLeft < 0) {
      clearInterval(logoutInterval)
      document.title = title
      callback()
    }
  }, 1000)
}

let timeout, tokenTimeout
const activitySetup = () => {
  // In order to start the listener on both after auth and on refresh, it needs to be called twice,
  // which then messes up the counter
  // The solution was to reset timers before each call

  clearTimers()

  document.addEventListener('mousemove', resetTimer, false)
  document.addEventListener('mousedown', resetTimer, false)
  document.addEventListener('keypress', resetTimer, false)
  document.addEventListener('DOMMouseScroll', resetTimer, false)
  document.addEventListener('mousewheel', resetTimer, false)
  document.addEventListener('touchmove', resetTimer, false)
  document.addEventListener('MSPointerMove', resetTimer, false)

  startTimer()
  startTokenInterval()
}

const startTimer = () => {
  // set to idle after set time
  timeout = window.setTimeout(setIdle, IDLE_TIME)
}

export const startTokenInterval = () => {
  tokenTimeout = window.setInterval(refreshToken, TOKEN_TIME)
}

const resetTimer = () => {
  if (!store.getters['auth/isUserIdle']) {
    window.clearTimeout(timeout)
    setActive()
  }
}

const refreshToken = () => {
  if (store.getters['auth/loggedIn']) {
    store.dispatch('auth/refreshToken')
  }
}

const setIdle = () => {
  if (store.getters['auth/loggedIn']) {
    const pageTitle = document.title
    countdownInterval(COUNTDOWN_BEFORE_LOGOUT, autoLogout, pageTitle)

    store.dispatch('auth/setUserIdle', true)
    clearInterval(tokenTimeout)

    const modalData = {
      declineAction: remainActive,
      confirmAction: remainActive,
      isModalVisible: store.getters['auth/isUserIdle'],
      confirmText: i18n.t('STAY_LOGGED_IN_BUTTON'),
      content: i18n.t('STAY_LOGGED_IN_CONTENT'),
      title: i18n.t('STAY_LOGGED_IN_TITLE'),
    }
    store.dispatch('general/setModalData', modalData)
  }
}

const setActive = () => {
  startTimer()
}

const remainActive = () => {
  store.dispatch('general/resetModalData')
  store.dispatch('auth/setUserIdle', false)
  store.dispatch('auth/refreshToken')
  startTokenInterval()
  startTimer()
}

const autoLogout = () => {
  store.dispatch('auth/setUserIdle', false)
  store.dispatch('general/resetModalData')
  Router.push('/logout')
}

export const clearTimers = () => {
  clearInterval(tokenTimeout)
  clearTimeout(timeout)
}

export default activitySetup
