<script>
import { authMethods } from '@state/helpers/auth'
import passwordData from '@utils/passwordData'
import toggleElementClass from '@utils/toggleElementClass'

import {
  passwordDigitValidation,
  passwordLettersValidation,
  requiredValidation,
  passwordValidations,
  passwordMatchValidation,
  numberRangeValidation,
} from '@utils/inputValidation'

export default {
  props: {
    closeFn: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      oldPassword: {
        ...passwordData,
        rules: [
          (value) => requiredValidation(value),
          (value) => numberRangeValidation(value, 7, 50),
        ],
      },
      newPassword: {
        ...passwordData,
        rules: [
          (value) =>
            passwordValidations(
              value,
              this.newPassword.validations,
              'isPasswordDigitValid',
              () => passwordDigitValidation(value)
            ),
          (value) =>
            passwordValidations(
              value,
              this.newPassword.validations,
              'isPasswordLettersValid',
              () => passwordLettersValidation(value)
            ),
          (value) =>
            passwordValidations(
              value,
              this.newPassword.validations,
              'isPasswordRangeValid',
              () => numberRangeValidation(value, 7, 50)
            ),
        ],
      },
      confirmPassword: {
        ...passwordData,
        rules: [(value) => requiredValidation(value)],
      },
      valid: false,
    }
  },
  mounted() {
    toggleElementClass('addClass', 'fixed', document.body)
  },
  destroyed() {
    toggleElementClass('removeClass', 'fixed', document.body)
  },
  methods: {
    ...authMethods,

    submit() {
      if (this.$refs.form.validate()) {
        return this.changePassword({
          body: {
            old_password: this.oldPassword.value,
            new_password: this.newPassword.value,
            confirm_password: this.confirmPassword.value,
          },
          callback: this.closeFn,
        })
      }
    },
    passwordMatch() {
      return passwordMatchValidation(
        this.newPassword.value,
        this.confirmPassword.value
      )
    },
  },
}
</script>

<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-row class="changePasswordRow">
        <v-text-field
          v-model="oldPassword.value"
          :append-icon="oldPassword.show ? 'visibility' : 'visibility_off'"
          :rules="oldPassword.rules"
          :type="oldPassword.show ? 'text' : 'password'"
          :label="$t('CURRENT_PASSWORD')"
          outlined
          name="oldPassword"
          password
          required
          @click:append="oldPassword.show = !oldPassword.show"
        ></v-text-field>
      </v-row>
      <v-row class="changePasswordRow">
        <v-text-field
          v-model="newPassword.value"
          :append-icon="newPassword.show ? 'visibility' : 'visibility_off'"
          :rules="newPassword.rules"
          :type="newPassword.show ? 'text' : 'password'"
          :label="$t('NEW_PASSWORD')"
          hide-details
          outlined
          name="newPassword"
          password
          required
          @click:append="newPassword.show = !newPassword.show"
        ></v-text-field>
        <ul class="validationMessages">
          <li v-for="item in newPassword.validations" :key="item.index">
            <span
              :class="{
                error: item.valid === 'invalid',
                valid: item.valid === 'valid',
                validationMessage: true,
              }"
            >
              {{ $t(item.message, item.params) }}
            </span>
          </li>
        </ul>
      </v-row>
      <v-row class="changePasswordRow">
        <v-text-field
          v-model="confirmPassword.value"
          :append-icon="confirmPassword.show ? 'visibility' : 'visibility_off'"
          :rules="confirmPassword.rules"
          :type="confirmPassword.show ? 'text' : 'password'"
          :label="$t('REPEAT_PASSWORD')"
          outlined
          :error-messages="passwordMatch()"
          password
          required
          name="repeatPassword"
          @click:append="confirmPassword.show = !confirmPassword.show"
        ></v-text-field>
      </v-row>
      <v-btn
        :disabled="!valid"
        class="btn primary-btn btn center"
        color="success"
        type="submit"
      >
        {{ $t('CHANGE_PASSWORD') }}
      </v-btn>
    </v-form>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.changePasswordRow {
  margin-bottom: 40px;

  &:nth-of-type(2) {
    margin-bottom: 0;
  }
}

.validationMessages {
  padding-left: 10px;
  margin-bottom: 20px;
  text-align: left;

  li {
    display: block;
  }
}
.validationMessage {
  font-size: 12px;
  color: $grey;

  &.error {
    color: $alert-error;
  }

  &.valid {
    text-decoration: line-through;
  }
}
</style>
