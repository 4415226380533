import i18n from '@src/i18n'

export default {
  install(Vue) {
    Vue.prototype.$tn = function (num, digits = 2) {
      return i18n.n(num, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      })
    }
  },
}
