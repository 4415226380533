<script>
import { authMethods, authComputed } from '@state/helpers/auth'
import { modalMethods } from '@state/helpers/modal'
import { headerUserComputed } from '@state/helpers/headerUser'
import ChangePassword from '@components/change-password/change-password'
import { getSavedStateSession } from '@utils/storage'
import { pick } from 'lodash'
import { enterpriseComputed } from '@state/helpers/enterprise'
const LanguageSelector = () =>
  import('@components/language-selector/language-selector')
const ModalForm = () => import('@components/_base-modal-form/_base-modal-form')
export default {
  components: {
    ChangePasswordModal: ModalForm,
    LanguageSelector,
  },
  props: {
    iconColor: {
      type: String,
      default: 'currentColor',
    },
    usernameTextColor: {
      type: String,
      default: 'currentColor',
    },
    headicon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showUserMenu: false,
      isChangePasswordModalVisible: false,
      isChangePasswordFormValid: false,
      isLogoutModalVisible: false,
      photo: getSavedStateSession('auth.userPhoto'),
      changePasswordForm: ChangePassword,
      isLanguageSelectorVisible: false,
      authenticatedUser: getSavedStateSession('user').name,
      isVattaxAdmin:
        getSavedStateSession('isVattaxAdmin') ||
        getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    ...authComputed,
    ...headerUserComputed,
    ...pick(enterpriseComputed, ['currentEnterprise']),
  },
  watch: {
    userMenuKey(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.photo = getSavedStateSession('auth.userPhoto')
        this.authenticatedUser = getSavedStateSession('user').name
      }
    },
  },
  methods: {
    ...authMethods,
    ...modalMethods,
    showLogoutModal: function () {
      this.showUserMenu = false
      const modalData = {
        confirmAction: this.logout,
        declineAction: this.cancelLogout,
        isModalVisible: true,
        confirmText: this.$i18n.t('LOG_OUT'),
        content: this.$i18n.t('LOG_OUT_CONFIRMATION'),
        isAlert: true,
        title: this.$i18n.t('LOG_OUT'),
      }
      this.setModalData(modalData)
    },
    toggleChangePasswordModal(bool) {
      this.isChangePasswordModalVisible = bool
    },
    logout: function () {
      this.$router.push('/logout')
      this.resetModalData()
    },
    cancelLogout: function () {
      this.resetModalData()
    },
    toggleUserMenuVisibility() {
      if (this.isLanguageSelectorVisible && !this.showUserMenu) {
        this.isLanguageSelectorVisible = false
      }
      this.showUserMenu = !this.showUserMenu
    },
    toggleLanguageSelectorVisibility() {
      this.isLanguageSelectorVisible = !this.isLanguageSelectorVisible
    },
    setLanguage(language) {
      this.toggleLanguageSelectorVisibility()
      this.toggleUserMenuVisibility()
    },
  },
}
</script>

<template>
  <div class="user">
    <ChangePasswordModal
      v-if="isChangePasswordModalVisible"
      :close-fn="() => toggleChangePasswordModal(false)"
      :title="$t('CHANGE_PASSWORD')"
      :content="changePasswordForm"
      :on-submit="() => toggleChangePasswordModal(false)"
    />
    <div :key="userMenuKey" class="trigger" @click="toggleUserMenuVisibility">
      <div
        v-if="photo"
        class="imageDiv"
        :style="{
          backgroundImage: `url(${photo})`,
        }"
      ></div>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        :class="headicon"
      >
        <g fill="none" fill-rule="evenodd">
          <circle cx="18" cy="18" r="18" fill="#F2F4F6" />
          <g :fill="iconColor" fill-rule="nonzero" transform="translate(10 10)">
            <circle cx="8" cy="4" r="4" />
            <path
              d="M16 13.2c.0033866-.7819214-.4516825-1.4932848-1.163-1.818C12.6811034 10.4438848 10.351003 9.972889 8 10c-2.351003-.027111-4.6811034.4438848-6.837 1.382C.4516825 11.7067152-.0033866 12.4180786 0 13.2L-.0000188 16h16.0000376L16 13.2z"
            />
          </g>
        </g>
      </svg>
      <span :class="usernameTextColor">{{ authenticatedUser }}</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6">
        <path
          :fill="iconColor"
          fill-rule="nonzero"
          d="M1.70711.2928932c-.39053-.3905243-1.02369-.3905243-1.41422 0-.39052.3905243-.39052 1.0236893 0 1.4142136l4 4c.39053.3905243 1.02369.3905243 1.41422 0l4-4c.39052-.3905243.39052-1.0236893 0-1.4142136-.39053-.3905243-1.02369-.3905243-1.41422 0L5 3.5857864 1.70711.2928932z"
        />
      </svg>
    </div>
    <div :class="{ active: showUserMenu }" class="userMenu">
      <div v-if="showUserMenu" class="overlay" @click="showUserMenu = false" />
      <div v-if="photo" class="largePhotoContainer">
        <div
          class="largePhoto"
          :style="{
            backgroundImage: `url(${photo}) `,
          }"
        ></div>
      </div>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="70" height="70">
        <g fill="none" fill-rule="evenodd" transform="translate(-.000037)">
          <circle cx="35.0000365" cy="35" r="35" fill="#F2F4F6" />
          <circle
            cx="35.0000365"
            cy="27.2222222"
            r="7.7777778"
            fill="#8C949E"
            fill-rule="nonzero"
          />
          <path
            fill="#8C949E"
            fill-rule="nonzero"
            d="M50.5555921 45.1111111c.006585-1.5204027-.8782716-2.9036093-2.2613889-3.535-4.1920212-1.8241128-8.7227719-2.7399381-13.2941667-2.6872222-4.5713948-.0527159-9.1021455.8631094-13.2941667 2.6872222-1.3831173.6313907-2.2679739 2.0145973-2.2613889 3.535l-.0000365 5.4444445h31.1111842l-.0000365-5.4444445z"
          />
        </g>
      </svg>
      <div class="userData">
        <div class="userFullName">
          {{ authenticatedUser }}
        </div>
        <div v-if="$route.path !== '/enterprises'" class="enterprise">
          {{ currentEnterprise.name }}
        </div>
      </div>

      <ul>
        <li v-if="$route.path !== '/enterprises'" class="account">
          <router-link :to="`/settings/company-settings`">
            {{ $t('SETTINGS') }}
          </router-link>
        </li>
        <li v-if="isVattaxAdmin && $route.path !== '/enterprises'">
          <router-link
            class="icon"
            :style="{
              display: 'flex',
              alignItems: 'center',
              margin: '6px 0 3px 0',
            }"
            :to="`/enterprises`"
          >
            <v-icon class="v-icon" medium>list</v-icon>
            {{ $t('SELECT_ENTERPRISE') }}
          </router-link>
        </li>
        <li class="changePassword">
          <a @click="() => toggleChangePasswordModal(true)">{{
            $t('CHANGE_PASSWORD')
          }}</a>
        </li>
        <li class="languageSelector">
          <a @click="toggleLanguageSelectorVisibility">{{
            $t('CHANGE_LANGUAGE')
          }}</a>
          <LanguageSelector
            :on-change-language="setLanguage"
            :is-visible="isLanguageSelectorVisible"
            :is-dropdown="true"
          />
          <v-icon
            class="v-icon languageSelectorIcon"
            @click="toggleLanguageSelectorVisibility"
            >mdi-menu-down</v-icon
          >
        </li>

        <li class="signout">
          <a @click="showLogoutModal">{{ $t('LOG_OUT') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@design';

.icon {
  padding: 0;
  margin: 0;
  color: $dark;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.imageDiv {
  width: 36px;
  height: 36px;
  background-repeat: none;
  background-position: center;
  background-size: cover;
  border-radius: 50px;
}

.largePhotoContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.largePhoto {
  width: 100px;
  height: 100px;
  padding: 0;
  margin: 0;
  background-repeat: none;
  background-position: center;
  background-size: cover;
  border-radius: 100px;
}

.v-icon {
  margin: 0 20px 0 0;
  color: $dark;
}

.user {
  padding: relative;
  // FIX: Text can be selected/highlighted when clicked
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  .trigger {
    display: flex;
    align-items: center;
    span {
      margin: 0 12px;
    }
    &:hover,
    &:focus {
      cursor: pointer;
    }
    .hide {
      display: none;
    }
  }

  .languageSelector {
    position: relative;
    .languageSelectorIcon {
      position: absolute;
      top: 50%;
      right: 0;
      font-size: 22px;
      transform: translateY(-50%);
    }
  }

  .userMenu {
    position: absolute;
    top: 0;
    right: 40px;
    z-index: -1;
    width: 265px;
    padding: 40px 0 0 0;
    text-align: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
    opacity: 0;
    transition: all 0.2s ease-out;

    &.active {
      top: 60px;
      z-index: 4;
      opacity: 1;
    }

    .userData {
      padding-bottom: 10px;
    }

    .userFullName {
      font-size: 16px;
      color: $dark;
    }
    .enterprise {
      font-size: 12px;
      color: $grey;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        width: 100%;
        padding: 5px 20px;
        text-align: left;
        text-indent: 30px;
        border-top: 1px solid $light-grey;
        a {
          display: block;
          font-size: 12px;
          font-weight: bold;
          color: $dark;
          text-decoration: none;
        }
        &:hover,
        &:focus {
          background-color: $light-grey;
        }
        &.account,
        &.details,
        &.help,
        &.changePassword,
        &.languageSelector,
        &.signout {
          background-repeat: no-repeat;
          background-position: 20px center;
          background-size: 16px;
        }
        // icons
        &.account {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M13.3 5.2l1.1-2.1L13 1.7l-2.1 1.1c-.3-.2-.7-.3-1.1-.4L9 0H7l-.8 2.3c-.3.1-.7.2-1 .4L3.1 1.6 1.6 3.1l1.1 2.1c-.2.3-.3.7-.4 1L0 7v2l2.3.8c.1.4.3.7.4 1.1L1.6 13 3 14.4l2.1-1.1c.3.2.7.3 1.1.4L7 16h2l.8-2.3c.4-.1.7-.3 1.1-.4l2.1 1.1 1.4-1.4-1.1-2.1c.2-.3.3-.7.4-1.1L16 9V7l-2.3-.8c-.1-.3-.2-.7-.4-1zM8 11c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z'/%3e%3c/svg%3e");
        }
        &.details {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3e%3cg fill='%23222F44' fill-rule='nonzero'%3e%3ccircle cx='8' cy='7' r='3'/%3e%3cpath d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 12.4c-.6-.7-1.4-1.4-3-1.4H7c-1.6 0-2.4.7-3 1.4C2.8 11.3 2 9.8 2 8c0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.8-.8 3.3-2 4.4z'/%3e%3c/g%3e%3c/svg%3e");
        }
        &.help {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3e%3cpath fill='%23222F44' fill-rule='nonzero' d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm0-8c.9 0 1.8.2 2.6.6L9.044 4.156c-.761-.207-1.327-.207-2.089 0L5.4 2.6C6.2 2.2 7.1 2 8 2zM2 8c0-.9.2-1.8.6-2.6l1.556 1.556c-.207.761-.207 1.327 0 2.089L2.6 10.6C2.2 9.8 2 8.9 2 8zm6 6c-.9 0-1.8-.2-2.6-.6l1.556-1.556c.761.207 1.327.207 2.089 0L10.6 13.4c-.8.4-1.7.6-2.6.6zm5.4-3.4l-1.556-1.556c.207-.761.207-1.327 0-2.089L13.4 5.4c.4.8.6 1.7.6 2.6 0 .9-.2 1.8-.6 2.6z'/%3e%3c/svg%3e");
        }
        &.signout {
          cursor: pointer;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16'%3e%3cg fill='%23222F44' fill-rule='nonzero'%3e%3cpath d='M3.4 2H8v2h2V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v9c0 .3.1.5.3.7l5 5c.2.2.4.3.7.3.1 0 .3 0 .4-.1.4-.1.6-.5.6-.9V6c0-.3-.1-.5-.3-.7L3.4 2zM5 12.6l-3-3V3.4l3 3v6.2z'/%3e%3cpath d='M15.7 7.3L12 3.6 10.6 5l2 2H8v2h4.6l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4z'/%3e%3c/g%3e%3c/svg%3e");
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &.changePassword {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%231D1D1D' fill-rule='nonzero' d='M12.7 0L6.5 6.3C6 6.1 5.5 6 5 6c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c0-.5-.1-1.1-.3-1.6L11 8V6h2V4h2l1-1V0h-3.3zM4.5 12c-.8 0-1.5-.7-1.5-1.5S3.7 9 4.5 9 6 9.7 6 10.5 5.3 12 4.5 12z'/%3E%3C/svg%3E");
        }
        &.languageSelector {
          background-image: url('~@/src/assets/svg/translate.svg');
        }
      }
    }
    .routeLink {
      padding: 0;
    }
  }
}
</style>
