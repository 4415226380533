/**
 * This watcher is used to trigger actions when user signs in / out
 */

export default (store) => {
  return store.watch(
    // Reactively watch this functions return value
    (state, getters) => {
      return getters['auth/loggedIn']
    },

    // Call this function when the value changes
    (isLoggedIn) => {
      if (isLoggedIn) {
        store.dispatch(
          'countries/fetchCountries',
          { query: { pageSize: 500 } },
          { root: true }
        )
        store.dispatch('categories/fetchCategories', {}, { root: true })
      }
    },

    // Options
    {
      immediate: true,
    }
  )
}
